import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { IBSignUpPage } from '../../IntroducingBroker/SignUp/IBSignUpPage'
import { Loading } from '../../global/Loading/Loading'
import { SelectField } from '../../global/field/SelectField'
import { Modal } from '../../global/modal/Modal'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextTiny } from '../../ui/Typography/Typography'
import { useIntroducingBroker } from '../../utils/IntroducingBrokerContext'
import { useWindowResize } from '../../utils/domUtils'
import { useIBPrograms } from '../../utils/useIBPrograms'
import { useIBReferralCodes } from '../../utils/useIBReferralCodes'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { HowIBWorksModal } from './HowIBWorksModal'
import { HowMultiTierWorksModal } from './HowMultiTierWorksModal'

import styles from './IntroducingBroker.module.scss'

export const IntroducingBrokerPage = (): JSX.Element => {
  useScrollToTop()
  const [howIBWorks, setHowIBWorks] = useState(false)
  const [howMIBWorks, setHowMIBWorks] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const introducingBroker = useIntroducingBroker()
  const { defaultProgram, multiTierProgram } = useIBPrograms()
  const { referralCodes, ibCode, mtCode, isLoading } = useIBReferralCodes()
  const [selectedCode, setSelectedCode] = useState(ibCode || mtCode)
  const [showThankYou, setShowThankYou] = useState(false)
  const isMobile = useWindowResize()

  useEffect(() => setSelectedCode(ibCode || mtCode), [ibCode, mtCode])
  const canRegister = useMemo(
    () => ((multiTierProgram && !mtCode) || (defaultProgram && !ibCode)) ?? true,
    [ibCode, mtCode, multiTierProgram, defaultProgram]
  )

  return (
    <>
      {howIBWorks && (
        <Modal
          closeModal={() => setHowIBWorks(false)}
          render={({ closeModal }) => <HowIBWorksModal onCancel={closeModal} ibCode={ibCode} />}
        />
      )}
      {howMIBWorks && (
        <Modal
          closeModal={() => setHowMIBWorks(false)}
          render={({ closeModal }) => (
            <HowMultiTierWorksModal onCancel={closeModal} ibCode={mtCode} />
          )}
        />
      )}
      <PageHeader
        className={classNames({ [styles.isPageHeaderVisible]: showThankYou && !isMobile })}
        title={t('IB.Introducing Broker Program')}
        id={ScrollToIds.IBProfileHeader}
      />
      <Loading showLoadingIcon isLoading={isLoading}>
        {!!referralCodes.length && !showThankYou && (
          <div className={styles.container}>
            <section className={styles.inviteSection}>
              <span>
                {t(
                  'IB.Invite traders to join Tickmill by using your personal link and get a permanent commission on every trade they make.'
                )}
              </span>
              <SelectField
                options={referralCodes.map(({ code }) => ({
                  value: code,
                  label: code,
                }))}
                allowCopy
                className={styles.selector}
                onChange={(v) => setSelectedCode(v.target.value)}
                value={selectedCode}
                disabled={referralCodes.length < 2}
                label={t('IB.Your Referral Code')}
              />

              {selectedCode === ibCode && (
                <div className={styles.link} onClick={() => setHowIBWorks(true)}>
                  {t('IB.How IB works?')}
                </div>
              )}
              {selectedCode === mtCode && (
                <div className={styles.link} onClick={() => setHowMIBWorks(true)}>
                  {t('IB.MultiTier.How Multi-tier works')}?
                </div>
              )}
            </section>
            <section className={styles.linkSection}>
              <div onClick={() => navigate('/dashboard/introducing-broker/legal-documents')}>
                <p className={styles.documents}>
                  {t('Profile.Legal Documents')}{' '}
                  <span className='icon is-right has-cursor-pointer'>
                    <ChevronRightIcon />
                  </span>
                </p>
                <TextTiny isParagraph>{t('Profile.All the legal documents')}</TextTiny>
              </div>
              {introducingBroker?.loyaltyProgramEnabled && (
                <div onClick={() => navigate('/dashboard/introducing-broker/loyalty-program')}>
                  <p className={styles.documents}>
                    {t('Profile.Loyalty Program')}{' '}
                    <span className='icon is-right has-cursor-pointer'>
                      <ChevronRightIcon />
                    </span>
                  </p>
                  <TextTiny isParagraph>
                    {t('Level')}&nbsp;{introducingBroker?.loyaltyDetails.currentTier?.name}&nbsp;(
                    {introducingBroker?.loyaltyDetails.loyaltyLots}&nbsp;{t('Profile.points')})
                  </TextTiny>
                </div>
              )}
              <div onClick={() => navigate('/dashboard/introducing-broker/referral-materials')}>
                <p className={styles.documents}>
                  {t('Profile.Promo Materials')}{' '}
                  <span className='icon is-right has-cursor-pointer'>
                    <ChevronRightIcon />
                  </span>
                </p>
                <TextTiny isParagraph>{t('Profile.Banners, logos')}</TextTiny>
              </div>
            </section>
          </div>
        )}
        {(canRegister || showThankYou) && <IBSignUpPage setShowThankYou={setShowThankYou} />}
      </Loading>
    </>
  )
}
