import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CloseIcon } from '../../icons/CloseIcon'

import styles from './NegativeBalanceModal.module.scss'

interface Props {
  onCancel?: () => void
}

export const NegativeBalanceModal: React.FC<Props> = ({ onCancel }) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className={styles.body}>
        <p className={styles.textSmall}>{t('Wallet.Withdrawal is currently not available')}</p>
        <button
          type='button'
          className={classNames('button', styles.closeButton)}
          onClick={onCancel}
        >
          <CloseIcon size={14} />
        </button>
      </div>
    </Fragment>
  )
}
