export enum PaymentProviderId {
  SC_Orbital_Crypto = '07d15c3e-35b3-11ed-8b0c-0a51dcb142a4',
  SC_Zotapay_Crypto = 'd25ea11c-9971-11ec-8b0c-0a51dcb142a4',
  SC_Zotapay_Crypto_Metablocks = 'f1f04084-2234-4672-88a2-89b11f3ed4a0',
}

export const isPaymentProviderSCOrbitalCrypto = (
  paymentProviderId: string | undefined
): boolean => {
  return PaymentProviderId.SC_Orbital_Crypto === paymentProviderId
}

export const isPaymentProviderSCZotapayCrypto = (
  paymentProviderId: string | undefined
): boolean => {
  return PaymentProviderId.SC_Zotapay_Crypto === paymentProviderId
}

export const isPaymentProviderSCZotapayCryptoMetablocks = (
  paymentProviderId: string | undefined
): boolean => {
  return PaymentProviderId.SC_Zotapay_Crypto_Metablocks === paymentProviderId
}
