import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Loading } from '../../../../global/Loading/Loading'
import { Button } from '../../../../global/button/Button'
import { FieldGroup } from '../../../../global/form/FieldGroup'
import { createFormField } from '../../../../global/formField/FormField'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import { WalletPaymentProviderCurrency } from '../../../../model/WalletPaymentProviderParameters'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import {
  AgentInformation,
  AgentInformationType,
  useAgentInformationFetch,
} from '../../../WalletAgentInformation'
import { WalletWithdrawCurrencyModal } from '../WalletWithdrawCurrencyModal'
import { CurrencyAmountField } from './CurrencyAmountField'
import { FormTermsConditionsPaymentAgent } from './FormTermsConditionsFactory'
import { WalletFormFieldsFactory } from './WalletFormFieldsFactory'
import { WalletWithdrawFormValues } from './WalletWithdrawForm'

import styles from './WalletWithdrawForm.module.scss'

interface WalletWithdrawPaymentAgentFieldsProps {
  onCancel(): void
  amountHint?: string
  onClickTermsConditions(): void
}

const FormField = createFormField<WalletWithdrawFormValues>()

export const WalletWithdrawPaymentAgentFields: React.FC<WalletWithdrawPaymentAgentFieldsProps> = (
  props
) => {
  const { onCancel, onClickTermsConditions, amountHint } = props
  const { t } = useTranslation()
  const { values, isValid, setValues, handleSubmit } = useFormikContext<WalletWithdrawFormValues>()

  const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false)

  const apiClient = useApiClient(ClientApiClient)

  const { paymentAgent, isLoading, forceRefresh } = useAgentInformationFetch(
    values.wallet.id!,
    values.paymentProvider.id!
  )

  const handleExitPageConfirmationModalOpen = () => {
    onCancel()
  }

  const handlePaymentAcceptAgentTerms = async () => {
    if (values.paymentProvider.id) {
      await apiClient.paymentAgentAcceptTerms(values.paymentProvider.id)
      forceRefresh()
    }
  }

  const handleCurrency = (walletPaymentProviderCurrency: WalletPaymentProviderCurrency) => {
    setValues({
      ...values,
      paymentProvider: {
        ...values.paymentProvider,
        currency: walletPaymentProviderCurrency,
      },
    })
    setCurrencyModalOpen(false)
  }

  const handleCurrencyModalOpen = () => {
    setCurrencyModalOpen(true)
  }

  const handleCurrencyModalClose = () => {
    setCurrencyModalOpen(false)
  }

  const handleSubmitForm = () => {
    handleSubmit()
  }

  return (
    <Loading isLoading={isLoading}>
      {paymentAgent && (
        <React.Fragment>
          {isCurrencyModalOpen && (
            <WalletWithdrawCurrencyModal
              onSelectOption={handleCurrency}
              onClose={handleCurrencyModalClose}
            />
          )}

          {paymentAgent.powerOfAttorney.accepted && (
            <React.Fragment>
              <FieldGroup>
                <CurrencyAmountField hint={amountHint} />
                <div className={styles.currencyFieldWrapper}>
                  <FormField
                    name='paymentProvider.currency.id'
                    label={t('Currency')}
                    required
                    disabled={values.paymentProvider.parameters.currencies.length <= 1}
                    rightIcon={<DropArrowDownIcon />}
                    onClick={handleCurrencyModalOpen}
                  />
                </div>
              </FieldGroup>

              <WalletFormFieldsFactory />
            </React.Fragment>
          )}

          <AgentInformation type={AgentInformationType.Withdraw} paymentAgent={paymentAgent} />

          <FormTermsConditionsPaymentAgent
            paymentAgent={paymentAgent}
            onClickTermsConditions={onClickTermsConditions}
          />

          <TransactionFormActionButtonTemplate>
            <Button
              appearance='secondary'
              size='L'
              type='button'
              onClick={handleExitPageConfirmationModalOpen}
            >
              {t('Cancel')}
            </Button>
            {paymentAgent.powerOfAttorney.accepted && (
              <Button
                appearance='primary'
                size='L'
                type='button'
                disabled={!isValid}
                onClick={handleSubmitForm}
              >
                {t('Confirm')}
              </Button>
            )}
            {!paymentAgent.powerOfAttorney.accepted && (
              <Button
                appearance='primary'
                size='L'
                type='button'
                disabled={!values.terms.paymentOperations || !values.terms.powerOfAttorney}
                onClick={handlePaymentAcceptAgentTerms}
              >
                {t('Accept Agent')}
              </Button>
            )}
          </TransactionFormActionButtonTemplate>
        </React.Fragment>
      )}
    </Loading>
  )
}
