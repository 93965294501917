import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { FileData } from '../../global/fileUpload/FileUpload'
import { SuccessModal } from '../../global/modal/SuccessModal'
import { BankAccountFormValues } from '../../model/BankAccount'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import { DocumentCategoryOptionType } from '../../model/DocumentCategoryOptionType'
import { DocumentStatus } from '../../model/DocumentStatus'
import { WalletCurrency, WalletTypeEnum } from '../../model/WalletDto'
import { TextStrong } from '../../ui/Typography/Typography'
import { useAccountActionAccessCheck } from '../../utils/AccountAccess/accountAccessCheck'
import { PageQuery, useApiClient } from '../../utils/ApiClient'
import { usePathHistoryContext } from '../../utils/PathHistoryContext'
import { ClientApiClient } from '../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage } from '../../utils/useFetch'
import { CreateBankAccountForm } from './CreateBankAccountForm'

export interface CreateBankAccountFormValues extends Omit<BankAccountFormValues, 'documentId'> {
  availableCurrencies: WalletCurrency[]
  document?: FileData
  previouslyUploadedDocumentId?: string
}

interface UserDocProps {
  doc: FileData
  id: string
}

const useDocuments = () => {
  const apiClient = useApiClient(ClientApiClient)

  const locale = useSessionLanguage()

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    (query?: PageQuery) =>
      apiClient.getDocuments({
        ...query,
        search: {
          Type: [DocumentCategoryOptionType.BankStatement].toString(),
          Status: [DocumentStatus.Approved].toString(),
        },
        languageId: locale,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )
  const query = useFetchAppendablePage(callback)
  return { ...query, forceRefresh }
}

export const CreateBankAccountPage: React.FC = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const apiClient = useApiClient(ClientApiClient)

  const [availableCurrencies, setAvailableCurrencies] = useState<WalletCurrency[]>([])
  const [userDoc, setUserDoc] = useState<UserDocProps>()
  const [modal, setModal] = useState(false)
  const [createdBankAccountId, setCreatedBankAccountId] = useState<string>()
  const locale = useSessionLanguage()
  const { data: documents, isLoading } = useDocuments()
  const { navigateToPreviousPath } = usePathHistoryContext()

  const { checkAccountTradersRoomAccess } = useAccountActionAccessCheck()

  const handleSubmitBankAccount = async (values: CreateBankAccountFormValues) => {
    try {
      let documentId = values.previouslyUploadedDocumentId || userDoc?.id

      if (values.document && userDoc?.doc.fileName !== values.document.fileName) {
        const response = await apiClient.uploadDocuments({
          documents: [
            {
              categoryId: DocumentCategoryType.Payment,
              typeId: DocumentCategoryOptionType.BankStatement,
              file: values.document.base64Content,
              filename: values.document.fileName,
            },
          ],
        })
        documentId = response[0]?.id
        setUserDoc({ doc: values.document, id: documentId })
      }
      const { document, availableCurrencies, ...bankAccountData } = values

      if (!documentId) {
        return
      }

      const createdBankAccount = await apiClient.addBankAccount({
        ...bankAccountData,
        documentId: documentId,
      })

      setCreatedBankAccountId(createdBankAccount.id)
      setModal(true)
    } catch (e: unknown) {
      console.error(e)
    }
  }

  const fetchAvailableCurrencies = useCallback(
    async () => setAvailableCurrencies(await apiClient.getAllCurrencies()),
    [apiClient]
  )

  useEffect(() => {
    fetchAvailableCurrencies()
  }, [fetchAvailableCurrencies])

  const goToWithdrawal = () => {
    const route =
      location.state.walletType === WalletTypeEnum.IB ? 'introducing-broker' : 'traders-room'
    const localeChanged = locale !== location.state.locale

    navigate(`/dashboard/${route}/wallets/${location.state.walletId}/withdrawal`, {
      state: {
        values: location.state?.values,
        bankAccountId: createdBankAccountId,
        localeChanged,
        resetFields: false,
      },
    })
  }

  const successFooter = () => {
    if (location?.state?.walletId) {
      return (
        <button className='button' onClick={goToWithdrawal}>
          <TextStrong>{t('Wallet.Back to Withdrawal Form')}</TextStrong>
        </button>
      )
    } else {
      return (
        <button className='button' onClick={() => navigate('/profile/bank-accounts')}>
          <TextStrong>{t('OK')}</TextStrong>
        </button>
      )
    }
  }

  const handleCancel = () => {
    if (location?.state?.walletId) {
      goToWithdrawal()
    } else {
      navigateToPreviousPath()
    }
  }

  return (
    <Loading isLoading={isLoading}>
      {modal && (
        <SuccessModal
          title={t('Success')}
          body={<span>{t('Bank Account Added')}</span>}
          onConfirm={() => checkAccountTradersRoomAccess(() => navigate('/profile/bank-accounts'))}
          renderFooter={successFooter}
        />
      )}
      <CreateBankAccountForm
        documents={documents}
        availableCurrencies={availableCurrencies}
        onSubmit={handleSubmitBankAccount}
        onCancelNewBank={handleCancel}
      />
    </Loading>
  )
}
