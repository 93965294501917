import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'

import { PercentageAmountChip } from '../../../../global/PercentageChip/PercentageAmountChip'
import { Button } from '../../../../global/button/Button'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { DocumentFileUpload } from '../../../../global/fileUpload/DocumentFileUpload'
import { FileData } from '../../../../global/fileUpload/FileUpload'
import { FieldGroup } from '../../../../global/form/FieldGroup'
import {
  FormWarningMessage,
  FormWarningMessageGroup,
} from '../../../../global/form/FormWarningMessage'
import { createFormField } from '../../../../global/formField/FormField'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import { BankAccount } from '../../../../model/BankAccount'
import { DocumentCategoryType } from '../../../../model/DocumentCategories'
import { WalletPaymentProviderCurrency } from '../../../../model/WalletPaymentProviderParameters'
import { validateRequired } from '../../../../utils/formValidation'
import { BankAccountsModal } from '../BankAccountsModal'
import { WalletWithdrawCurrencyModal } from '../WalletWithdrawCurrencyModal'
import { CurrencyAmountField } from './CurrencyAmountField'
import { FormTermsConditionsPSP } from './FormTermsConditionsFactory'
import { WalletFormFieldsFactory } from './WalletFormFieldsFactory'
import { WalletWithdrawFormValues } from './WalletWithdrawForm'

import styles from './WalletWithdrawForm.module.scss'

interface WalletWithdrawBankFieldsProps {
  showAmountPresets?: boolean
  onCancel(): void
  amountHint?: string

  onClickTermsConditions(): void
}

const FormField = createFormField<WalletWithdrawFormValues>()

export const WalletWithdrawBankFields: React.FC<WalletWithdrawBankFieldsProps> = (props) => {
  const { onCancel, onClickTermsConditions, amountHint } = props

  const { t } = useTranslation()

  const { values, setValues, isValid, handleSubmit, setFieldValue, setFieldTouched } =
    useFormikContext<WalletWithdrawFormValues>()

  const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false)

  const handleExitPageConfirmationModalOpen = () => {
    onCancel()
  }

  const handleCurrency = (walletPaymentProviderCurrency: WalletPaymentProviderCurrency) => {
    setValues({
      ...values,
      paymentProvider: {
        ...values.paymentProvider,
        currency: walletPaymentProviderCurrency,
      },
    })
    setCurrencyModalOpen(false)
  }

  const handleCurrencyModalOpen = () => {
    setCurrencyModalOpen(true)
  }

  const handleCurrencyModalClose = () => {
    setCurrencyModalOpen(false)
  }

  const handleSubmitForm = () => {
    handleSubmit()
  }

  const disabled = values.paymentProvider.parameters.currencies.length <= 1

  return (
    <React.Fragment>
      {values.paymentProvider.id && (
        <React.Fragment>
          {isCurrencyModalOpen && (
            <WalletWithdrawCurrencyModal
              onSelectOption={handleCurrency}
              onClose={handleCurrencyModalClose}
            />
          )}
          <FieldGroup>
            <CurrencyAmountField hint={amountHint} />
            <div className={styles.currencyFieldWrapper}>
              <FormField
                name='paymentProvider.currency.id'
                label={t('Wallet.Currency')}
                required
                disabled={disabled}
                rightIcon={!disabled ? <DropArrowDownIcon /> : null}
                onClick={handleCurrencyModalOpen}
              />
            </div>
          </FieldGroup>
          {props.showAmountPresets && (
            <div className='mb-3'>
              <PercentageAmountChip
                currentAmount={values.amount}
                walletBalance={values.wallet.balance}
                onAmountChange={(newAmount) => {
                  setFieldTouched('amount', true)
                  setFieldValue('amount', newAmount.toFixed(2))
                }}
              />
            </div>
          )}
          <BankFields onClickTermsConditions={onClickTermsConditions} />
          <TransactionFormActionButtonTemplate>
            <Button
              appearance='secondary'
              size='L'
              type='button'
              onClick={handleExitPageConfirmationModalOpen}
            >
              {t('Cancel')}
            </Button>
            <Button
              appearance='primary'
              size='L'
              type='button'
              onClick={handleSubmitForm}
              disabled={!isValid}
            >
              {t('Confirm')}
            </Button>
          </TransactionFormActionButtonTemplate>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export const initialBankAccountState = {
  clientBankAccountDetailsId: undefined,
  bankName: '',
  bankCode: '',
  bankCodeSort: '',
  bankAddress: '',
  accountName: '',
  externalAccount: '',
}

const BankFields: React.FC<{ onClickTermsConditions(): void }> = ({ onClickTermsConditions }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { values, setFieldValue } = useFormikContext<WalletWithdrawFormValues>()
  const locale = useSessionLanguage()

  const [isNewBankAccount, setNewBankAccount] = useState(false)
  const [isBankAccountModalOpen, setBankAccountModalOpen] = useState(false)

  const handleBankAccountNew = () => {
    navigate('/profile/bank-accounts/new', {
      state: {
        walletId: values.wallet.id,
        values,
        walletType: values.walletType,
        locale,
      },
    })
    setNewBankAccount(true)
    setFieldValue('isBankAccountsList', false)
    setFieldValue('fields', {
      ...values.fields,
      ...initialBankAccountState,
    })
  }

  const handleBankAccountModalOpen = () => {
    setBankAccountModalOpen(true)
  }

  const handleBankAccountModalClose = () => {
    setFieldValue('bankAccount', undefined)
    setFieldValue('fields', {
      ...values.fields,
      ...initialBankAccountState,
    })
    setBankAccountModalOpen(false)
  }

  const handleSelectOption = (option: BankAccount) => {
    setFieldValue('bankAccount', option)
    setFieldValue('fields', {
      ...values.fields,
      clientBankAccountDetailsId: option.id,
      bankName: option.bankName,
      bankCode: option.bankCode,
      bankCodeSort: option.bankCodeSort,
      bankAddress: option.bankAddress,
      accountName: option.accountName,
      externalAccount: option.bankAccount,
    })
    setBankAccountModalOpen(false)
  }

  if (isNewBankAccount) {
    return (
      <React.Fragment>
        <FormField
          name='fields.accountName'
          label={t('Beneficiary Name')}
          placeholder={t('Beneficiary Name')}
          validate={validateRequired(t('Validation.Required'))}
          required
          maxlength={128}
        />
        <WalletFormFieldsFactory />
        <FormField
          name='comment'
          label={t('Wallet.Additional Comment')}
          placeholder={t('Wallet.Additional Comment')}
          maxlength={255}
        />
        <div className='pt-5 pb-4'>
          <BankFileUpload />
        </div>
        <Note />
        <div className='my-5'>
          <FormTermsConditionsPSP onClickTermsConditions={onClickTermsConditions} />
        </div>
      </React.Fragment>
    )
  }

  if (!isNewBankAccount && values.isBankAccountsList) {
    return (
      <React.Fragment>
        {isBankAccountModalOpen && (
          <BankAccountsModal
            onSelectOption={handleSelectOption}
            onNewAccount={handleBankAccountNew}
            onClose={handleBankAccountModalClose}
          />
        )}
        <FormField
          name='fields.accountName'
          label={t('Wallet.To My Bank Account')}
          placeholder={t('Wallet.To My Bank Account')}
          value={values?.bankAccount?.accountName}
          rightIcon={<DropArrowDownIcon />}
          required
          readOnly
          onClick={handleBankAccountModalOpen}
          maxlength={128}
        />
        {values?.bankAccount?.bankAccount && <WalletFormFieldsFactory disabled />}
        <FormField
          name='comment'
          label={t('Wallet.Additional Comment')}
          placeholder={t('Wallet.Additional Comment')}
          maxlength={255}
        />
        <Note />
        <div className='my-5'>
          <FormTermsConditionsPSP onClickTermsConditions={onClickTermsConditions} />
        </div>
      </React.Fragment>
    )
  }

  if (!isNewBankAccount && !values.isBankAccountsList) {
    return (
      <React.Fragment>
        <FormField
          name='fields.accountName'
          label={t('Beneficiary Name')}
          placeholder={t('Beneficiary Name')}
          validate={validateRequired(t('Validation.Required'))}
          required
          maxlength={128}
        />
        <WalletFormFieldsFactory />
        <FormField
          name='comment'
          label={t('Wallet.Additional Comment')}
          placeholder={t('Wallet.Additional Comment')}
        />
        <div className='pt-5 pb-4'>
          <BankFileUpload />
        </div>
        <Note />
        <div className='my-5'>
          <FormTermsConditionsPSP onClickTermsConditions={onClickTermsConditions} />
        </div>
      </React.Fragment>
    )
  }

  return null
}

const BankFileUpload = () => {
  const { t } = useTranslation()

  const { values, setFieldValue } = useFormikContext<WalletWithdrawFormValues>()

  const handleUploadDocument = (data: FileData) => {
    setFieldValue('bankDepositProof', data)
  }

  const handleUploadDocumentRemove = () => {
    setFieldValue('bankDepositProof', {})
  }

  return (
    <DocumentFileUpload
      title={t('Wallet.Bank Deposit Proof / Bank Statement')}
      documentCategory={DocumentCategoryType.Internal}
      file={values.bankDepositProof as FileData}
      onUpload={handleUploadDocument}
      onRemove={handleUploadDocumentRemove}
    />
  )
}

const Note: React.FC = () => {
  const { values } = useFormikContext<WalletWithdrawFormValues>()

  const { t } = useTranslation()

  return (
    <React.Fragment>
      {values.paymentProvider.method_name === 'SC Alogateway CUP' ? (
        <FormWarningMessageGroup>
          <FormWarningMessage
            message={
              <React.Fragment>
                <span className='has-text-danger pr-1'>{t('Note')}</span> {t('china info')}
              </React.Fragment>
            }
          />
        </FormWarningMessageGroup>
      ) : null}
      {values.paymentProvider.method_name === 'SC Transact365 CNY' ? (
        <FormWarningMessageGroup>
          <FormWarningMessage
            message={
              <React.Fragment>
                <span className='has-text-danger pr-1'>{t('Note')}</span>{' '}
                {t('This payment provider channel is')}
              </React.Fragment>
            }
          />
        </FormWarningMessageGroup>
      ) : null}
    </React.Fragment>
  )
}
