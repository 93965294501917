import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useFormikContext } from 'formik'

import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { createFormRadioField } from '../../global/formField/RadioGroupField'
import { RadioButton } from '../../global/radioButton/RadioButton'
import { getMarketDataClassificationsName } from '../../model/MarketDataClassificationsType'
import { NameDto } from '../../model/NameDto'
import { DocumentTemplate } from '../../pages/Signup/Components/DocumentTemplate'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { ResponseError, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { LegalDocumentsValues } from './LegalDocumentsForm'

import styles from './ReadDocumentMarketDataPage.module.scss'

const FormRadioGroupField = createFormRadioField<LegalDocumentsValues>()

const useDocumentMarketDataClassifications = () => {
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => {
    return apiClient.getMarketDataClassifications(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])
  const { data: marketDataClassifications = [], isLoading } = useFetchOne(callback)

  return { marketDataClassifications, isLoading }
}

export const useDocumentMarketDataClassificationsMutate = () => {
  const apiClient = useApiClient(ClientApiClient)

  const [isLoading, setLoading] = useState(false)
  const { marketDataClassificationId } = useDocumentMarketDataIdState()

  const mutate = async (values: LegalDocumentsValues, callback?: () => void) => {
    try {
      setLoading(true)
      const documentMarketDataClassificationId =
        values.readDocumentMarketData?.marketDataClassificationId || marketDataClassificationId
      if (documentMarketDataClassificationId) {
        await apiClient.updateMarketDataClassifications(documentMarketDataClassificationId)
        callback?.()
      }
    } catch (e: unknown) {
      if (isMarketDataClassificationAlreadyAcceptedError(e)) {
        callback?.()
      }
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const isMarketDataClassificationAlreadyAcceptedError = (e: unknown) => {
    const MarketDataAlreadySet = 'market_data_classification'
    const error = e as ResponseError
    const code = error.response?.response?.data?.code

    return code === MarketDataAlreadySet
  }

  return {
    mutate,
    isLoading,
  }
}

export const useDocumentMarketDataIdState = () => {
  const { account } = useAccountReadContext()
  const [urlParams] = useSearchParams()

  const token = urlParams.get('token') || ''
  const documentMarketDataId = account?.id || token || ''

  const MarketDataClassificationKey = `DocumentMarketDataClassificationIdKey${documentMarketDataId}`
  const [marketDataClassificationId, setMarketDataClassificationId] = useLocallyPersistedState(
    MarketDataClassificationKey,
    0
  )

  return { marketDataClassificationId, setMarketDataClassificationId }
}

interface ReadDocumentMarketDataProps {
  onAccept(values: LegalDocumentsValues): Promise<void>
}

export const ReadDocumentMarketDataPage: React.FC<ReadDocumentMarketDataProps> = (props) => {
  useScrollToTop()
  const { onAccept } = props

  const { t } = useTranslation()

  const { values, setFieldValue } = useFormikContext<LegalDocumentsValues>()
  const { marketDataClassifications = [], isLoading } = useDocumentMarketDataClassifications()

  const handleMarketDataAgreementAccept = async () => {
    await onAccept(values)
  }

  const handleMarketDataAgreementCancel = () => {
    setFieldValue('readDocumentMarketData', {
      marketDataClassificationId: 0,
      isDocumentMarketDataRead: false,
      isDocumentMarketDataAccepted: false,
    })
  }

  return (
    <DocumentTemplate
      title={t('Sign up.Exchange Market Data Client Classification')}
      goBack={handleMarketDataAgreementCancel}
    >
      <Loading isLoading={isLoading} showLoadingIcon>
        <div className='is-flex is-flex-direction-row mt-4 pl-4 pr-4'>
          <div className='is-align-items-start is-flex-grow-1'>
            <DocumentMarketDataContent />
            <div className='mb-5'>
              <div className='mb-4'>
                <FormRadioGroupField
                  name={'readDocumentMarketData.marketDataClassificationId'}
                  value={values.readDocumentMarketData?.marketDataClassificationId}
                  required
                >
                  {marketDataClassifications.map((x) => (
                    <RadioButton
                      name={'readDocumentMarketData.marketDataClassificationId'}
                      label={t(getMarketDataClassificationsName(x.id))}
                      value={x.id}
                      checked={isMarketDataClassificationItemSelected(x, values)}
                      key={x.id}
                    />
                  ))}
                </FormRadioGroupField>
              </div>
              <div className={styles.agreeButtons}>
                <Button
                  appearance='secondary'
                  size='L'
                  fullWidth
                  type='button'
                  onClick={handleMarketDataAgreementCancel}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  appearance='primary'
                  size='L'
                  fullWidth
                  type='button'
                  disabled={!isMarketDataClassificationSelected(values)}
                  onClick={handleMarketDataAgreementAccept}
                >
                  {t('Accept')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Loading>
    </DocumentTemplate>
  )
}

const DocumentMarketDataContent = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.content}>
      <div>
        <p className='mb-4'>{t('Sign up.Terms and conditions for receipt of')}</p>
        <p>{t('Sign up.These Terms & Conditions between Tickmill UK Ltd Vendor permits')}</p>
        <p className='mb-4'>
          {t('Sign up.The subscription fees are charged to your account upon approval')}
        </p>
        <p className='mb-4'>
          {t(
            'Sign up.Corporate clients are considered by the exchanges to be PROFESSIONAL INVESTORS'
          )}
        </p>
        <p className='mb-4'>
          {t(
            'Sign up.Subscriber furthermore agrees that Vendor may store and provide the exchange'
          )}
        </p>
        <p>
          {t(
            'Sign up.Based on the Subscribers qualifications, Subscribers may be charged reduced Fees for Information'
          )}
        </p>
      </div>
      <div className='mb-5'>
        <h5 className='has-text-weight-bold'>
          {t('Sign up.A Private Investor non-professional shall mean and include either')}
        </h5>
        <ul>
          <li>{t('Sign up.(a) An individual, natural person Subscriber(s) who, or')}</li>
          <li>
            {t(
              'Sign up.(b) certain small business entities (limited liability companies, partnerships)'
            )}
          </li>
        </ul>
      </div>
      <div className='mb-5'>
        <h5 className='has-text-weight-bold'>
          {t('Sign up.The Private Investor (non-professional Subscriber) must')}
        </h5>
        <ul>
          <li>{t('Sign up.(a) have an active futures trading account')}</li>
          <li>{t('Sign up.(b) be an individual, natural person or small business entity')}</li>
        </ul>
      </div>
      <div className='mb-5'>
        <h5 className='has-text-weight-bold'>
          {t('Sign up.The Private Investor (non-professional) Subscriber must not')}
        </h5>
        <ul>
          <li>{t('Sign up.(c) be a member (or hold or lease any type membership) of')}</li>
          <li>{t('Sign up.(d) does not operate a credit institution or')}</li>
          <li>{t('Sign up.(e) is not subject to any domestic or foreign banking')}</li>
          <li>
            {t(
              'Sign up.(f) be registered or qualified as a professional trader or investment adviser with any'
            )}
          </li>
          <li>
            {t('Sign up.(g) be acting on behalf of an institution that engages in brokerage')}
          </li>
        </ul>
      </div>
      <div className='mb-5'>
        <h5 className='has-text-weight-bold'>
          {t('Sign up.The Non-Professional Subscriber’s Use of Information must')}
        </h5>
        <ul className='mb-4'>
          <li>{t('Sign up.(h) be solely for the Subscriber’s personal or private use')};</li>
          <li>{t('Sign up.(i) be limited to managing the Subscriber’s own assets')}</li>
        </ul>
        <p>
          {t(
            'Sign up.The Subscriber shall notify the Vendor promptly in writing of any changes which have the effect'
          )}
        </p>
      </div>
      <div className='mb-5'>
        <span>
          <span className='has-text-weight-bold'>{t('Sign up.A Professional Investor')}:</span>
          <span>
            {' '}
            {t(
              'Sign up.Is a Subscriber who does not qualify as a Private Investor (as outlined above)'
            )}
          </span>
        </span>
      </div>
      <p className='mb-5'>
        {t('Sign up.Subscriber is please select one of the two following radio buttons')}
      </p>
    </div>
  )
}

const isMarketDataClassificationSelected = (values: LegalDocumentsValues) => {
  return values.readDocumentMarketData?.marketDataClassificationId > 0
}

const isMarketDataClassificationItemSelected = (x: NameDto, values: LegalDocumentsValues) => {
  const marketDataClassificationId = values.readDocumentMarketData?.marketDataClassificationId || ''
  return (
    Number.parseInt(x.id.toString(), 10) ===
    Number.parseInt(marketDataClassificationId?.toString(), 10)
  )
}
