import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'

export interface TwoFactorWarningState {
  visible: boolean
  walletId: string | null
}

interface UseTwoFactorAuthWithdrawalReturnProps {
  onTwoFAWithdrawal: (walletId?: string) => Promise<void>
  twoFactorWarningModalState: TwoFactorWarningState
  onDismissModal(): void
}

const DEFAULT_MODAL_STATE = { visible: false, walletId: null }

export const useTwoFactorAuthWithdrawal = (): UseTwoFactorAuthWithdrawalReturnProps => {
  // const [activeTwoFAProviders, setActiveTwoFAProviders] = useState<TwoFAProviderItem[]>([])
  const [twoFactorWarningModalState, setTwoFactorWarningModalState] =
    useState<TwoFactorWarningState>(DEFAULT_MODAL_STATE)
  const navigate = useNavigate()
  const apiClient = useApiClient(ClientApiClient)

  // const fetchActive2FAProviders = async (walletId?: string) => {
  //   try {
  //     const response = await apiClient.get2FAProviders()
  //     setActiveTwoFAProviders(response)
  //     return response
  //   } catch (error: unknown) {
  //     if (instanceOfAxiosError((error as ResponseError)?.response)) {
  //       const errorResponse = (error as ResponseError).response.response?.data
  //       if (
  //         errorOptions.two_factor_authentication_disabled_for_county_of_residence_of_user ===
  //         errorResponse?.code
  //       ) {
  //         navigate(
  //           walletId
  //             ? `/dashboard/traders-room/wallets/${walletId}/withdrawal`
  //             : '/dashboard/traders-room/wallets/withdrawal/'
  //         )
  //       } else {
  //         throw error
  //       }
  //     }
  //   }
  // }

  const handleWithdrawal = async (walletId?: string) => {
    // const active2FAProviders =
    //   activeTwoFAProviders.length > 0
    //     ? activeTwoFAProviders
    //     : await fetchActive2FAProviders(walletId)

    const hasWithdrawTwoFactorEnabled = true
    // active2FAProviders?.[0]?.authActions?.some(({ id }) => id === AuthActionIds.Withdraw) ?? false

    if (hasWithdrawTwoFactorEnabled) {
      navigate(
        walletId
          ? `/dashboard/traders-room/wallets/${walletId}/withdrawal`
          : '/dashboard/traders-room/wallets/withdrawal/'
      )
    } else {
      setTwoFactorWarningModalState({ visible: true, walletId: walletId ?? null })
    }
  }

  const handleDismissModal = () => setTwoFactorWarningModalState(DEFAULT_MODAL_STATE)

  return {
    onTwoFAWithdrawal: handleWithdrawal,
    twoFactorWarningModalState,
    onDismissModal: handleDismissModal,
  }
}
