import { AccountDetailedDto } from '../model/AccountDetailedDto'
import { NameDto } from '../model/NameDto'
import { PublicDomainDto } from '../model/PublicDomainDto'
import { getDomainName } from './cookie.utils'

export enum TickmillCompaniesEnum {
  GENERIC = 0,
  TICKMILL_EU = 1,
  TICKMILL_UK = 2,
  TICKMILL_SC = 3,
  TICKMILL_PA = 4,
  TICKMILL_AS = 5,
}

export enum PageSections {
  CONTACT_DETAILS = 'contact-details',
}

export type TickmillCompany = NameDto<TickmillCompaniesEnum>

export const AvailableEntities: NameDto<TickmillCompaniesEnum>[] = [
  {
    id: TickmillCompaniesEnum.TICKMILL_EU,
    name: 'Tickmill EU Company',
  },
  {
    id: TickmillCompaniesEnum.TICKMILL_UK,
    name: 'Tickmill UK Company',
  },
  {
    id: TickmillCompaniesEnum.TICKMILL_SC,
    name: 'Tickmill SC Company',
  },
  {
    id: TickmillCompaniesEnum.TICKMILL_PA,
    name: 'Tickmill PA Company',
  },
  {
    id: TickmillCompaniesEnum.TICKMILL_AS,
    name: 'Tickmill AS Company',
  },
]

export const getTickmillSupportEmail = (
  entity: TickmillCompaniesEnum,
  productType = true
): string => {
  if (entity === TickmillCompaniesEnum.TICKMILL_EU) {
    return 'support@tickmill.eu'
  } else if (entity === TickmillCompaniesEnum.TICKMILL_UK) {
    return productType ? 'support@tickmill.co.uk' : 'clientservices@tickmill.co.uk'
  } else {
    return 'support@tickmill.com'
  }
}

export const getTickmillSupportPhone = (entity: TickmillCompaniesEnum): string => {
  return entity === TickmillCompaniesEnum.TICKMILL_EU
    ? '+357 25041710'
    : entity === TickmillCompaniesEnum.TICKMILL_UK
    ? '+44 203 871 3299'
    : '+852 5808 7849'
}

export const getContactUsLink = (
  domains?: PublicDomainDto,
  locale?: string,
  section?: string
): string => {
  const newLocale = locale === 'zh' ? 'cn' : locale
  const homePageUrl = domains?.homePageUrl ?? ''
  const localeSlug = newLocale ? newLocale + '/' : 'en/'
  const sectionSlug = section ? '#' + section : ''
  return `${homePageUrl}/${localeSlug}about/contact-us${sectionSlug}`.replace(
    /(https?:\/\/)|(\/)+/g,
    (match, protocol) => protocol || '/'
  )
}

export const getHomepageLink = (domain: string, locale?: string): string => {
  if (locale) {
    return `${domain}/${locale === 'en' || locale === 'tr' ? '' : locale}`
  }
  return domain
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const entityPerTickmillCompany: Record<TickmillCompaniesEnum, string> = {
  [TickmillCompaniesEnum.TICKMILL_EU]: 'eu',
  [TickmillCompaniesEnum.TICKMILL_SC]: 'sc',
  [TickmillCompaniesEnum.TICKMILL_UK]: 'uk',
  [TickmillCompaniesEnum.TICKMILL_AS]: 'as',
  [TickmillCompaniesEnum.TICKMILL_PA]: 'partners',
  [TickmillCompaniesEnum.GENERIC]: 'partners',
}

export function getRedirectDomain(accountEntity: TickmillCompaniesEnum): string {
  if (Object.keys(window.env.APP_ENTITY_DEFAULT_DOMAINS).length) {
    const entityMap = Object.values(window.env.APP_ENTITY_DEFAULT_DOMAINS)
    const entityMapKey = entityMap.indexOf(entityPerTickmillCompany[accountEntity])
    if (entityMapKey >= 0) {
      return Object.keys(window.env.APP_ENTITY_DEFAULT_DOMAINS)[entityMapKey]
    }
    return window.location.host
  }
  return window.location.host
}

export function replaceWithCorrectEntity(accountEntity: TickmillCompaniesEnum): string {
  if (accountEntity === TickmillCompaniesEnum.TICKMILL_EU) {
    return window.location.href
      .replace('sc', 'eu')
      .replace('uk', 'eu')
      .replace('as', 'eu')
      .replace('partners', 'eu')
      .replace('wrong-entity', 'login')
  }
  if (accountEntity === TickmillCompaniesEnum.TICKMILL_UK) {
    return window.location.href
      .replace('sc', 'uk')
      .replace('eu', 'uk')
      .replace('as', 'uk')
      .replace('partners', 'uk')
      .replace('wrong-entity', 'login')
  }
  if (accountEntity === TickmillCompaniesEnum.TICKMILL_SC) {
    return window.location.href
      .replace('partners', 'sc')
      .replace('uk', 'sc')
      .replace('as', 'sc')
      .replace('eu', 'sc')
      .replace('wrong-entity', 'login')
  }
  if (accountEntity === TickmillCompaniesEnum.TICKMILL_PA) {
    return window.location.href
      .replace('sc', 'partners')
      .replace('uk', 'partners')
      .replace('as', 'partners')
      .replace('eu', 'partners')
      .replace('wrong-entity', 'login')
  }
  if (accountEntity === TickmillCompaniesEnum.TICKMILL_AS) {
    return window.location.href
      .replace('sc', 'as')
      .replace('uk', 'as')
      .replace('eu', 'as')
      .replace('partners', 'as')
      .replace('wrong-entity', 'login')
  }
  return window.location.href.replace('wrong-entity', 'login')
}

export const getTickmillCompanyByHostname = (
  skipCheckingPathName?: boolean
): TickmillCompaniesEnum => {
  const hostName = window.location.hostname.split('.')
  const pathName = window.location.pathname
  const isLoginPage = skipCheckingPathName
    ? false
    : pathName.includes('sign-up') ||
      pathName.includes('forgot-password') ||
      pathName.includes('login') ||
      pathName === '/'

  let probablyEntity
  if (process.env.NODE_ENV === 'development') {
    const reactAppEntity = process.env?.REACT_APP_ENTITY?.trim()
    if (reactAppEntity === 'UK') {
      probablyEntity = TickmillCompaniesEnum.TICKMILL_UK
    } else if (reactAppEntity === 'SC') {
      probablyEntity = TickmillCompaniesEnum.TICKMILL_SC
    } else if (reactAppEntity === 'AS') {
      probablyEntity = TickmillCompaniesEnum.TICKMILL_AS
    } else if (reactAppEntity === 'EU') {
      probablyEntity = TickmillCompaniesEnum.TICKMILL_EU
    } else if (reactAppEntity === 'PARTNERS') {
      probablyEntity = TickmillCompaniesEnum.TICKMILL_PA
    } else if (reactAppEntity === 'GENERIC') {
      probablyEntity = TickmillCompaniesEnum.GENERIC
    } else {
      // fallback
      probablyEntity = TickmillCompaniesEnum.TICKMILL_SC
    }
    return probablyEntity
  }

  if (window.env.APP_ENTITY.length) {
    if (window.env.APP_CONSOLIDATED_DOMAIN) {
      return TickmillCompaniesEnum.GENERIC
    } else if (window.env.APP_ENTITY[0].includes('uk')) {
      probablyEntity = TickmillCompaniesEnum.TICKMILL_UK
      return probablyEntity
    } else if (window.env.APP_ENTITY[0].includes('eu')) {
      probablyEntity = TickmillCompaniesEnum.TICKMILL_EU
      return probablyEntity
    } else if (window.env.APP_ENTITY[0].includes('as')) {
      if (isLoginPage) {
        probablyEntity = TickmillCompaniesEnum.GENERIC
      } else {
        probablyEntity = TickmillCompaniesEnum.TICKMILL_AS
      }
      return probablyEntity
    } else if (window.env.APP_ENTITY[0].includes('sc')) {
      if (isLoginPage) {
        probablyEntity = TickmillCompaniesEnum.GENERIC
      } else {
        probablyEntity = TickmillCompaniesEnum.TICKMILL_SC
      }
      return probablyEntity
    } else {
      probablyEntity = TickmillCompaniesEnum.TICKMILL_PA
      return probablyEntity
    }
  }

  if (getDomainName(true) === 'secure.tickmill.eu') {
    probablyEntity = TickmillCompaniesEnum.TICKMILL_EU
    return probablyEntity
  }
  if (getDomainName(true) === 'secure.tickmill.co.uk') {
    probablyEntity = TickmillCompaniesEnum.TICKMILL_UK
    return probablyEntity
  }

  if (hostName[0].includes('eu')) {
    probablyEntity = TickmillCompaniesEnum.TICKMILL_EU
  } else if (hostName[0].includes('sc')) {
    if (isLoginPage) {
      probablyEntity = TickmillCompaniesEnum.GENERIC
    } else {
      probablyEntity = TickmillCompaniesEnum.TICKMILL_SC
    }
  } else if (hostName[0].includes('uk')) {
    probablyEntity = TickmillCompaniesEnum.TICKMILL_UK
  } else if (hostName[0].includes('partners')) {
    probablyEntity = TickmillCompaniesEnum.TICKMILL_PA
  } else if (hostName[0].includes('as')) {
    if (isLoginPage) {
      probablyEntity = TickmillCompaniesEnum.GENERIC
    } else {
      probablyEntity = TickmillCompaniesEnum.TICKMILL_AS
    }
  } else {
    probablyEntity = TickmillCompaniesEnum.TICKMILL_UK
  }

  return probablyEntity
}

export const getTickmillPublicDomain = () => {
  const hostName = getDomainName(true).split('.')
  return hostName[0] === 'localhost' ? 'clientarea' : getDomainName(true)
}

export const getCompanyName = (id: TickmillCompaniesEnum | undefined) => {
  switch (id) {
    case TickmillCompaniesEnum.TICKMILL_EU:
      return 'TM EU'
    case TickmillCompaniesEnum.TICKMILL_UK:
      return 'TM UK'
    case TickmillCompaniesEnum.TICKMILL_SC:
      return 'TM SC'
    case TickmillCompaniesEnum.TICKMILL_PA:
      return 'TM Partners'
    case TickmillCompaniesEnum.TICKMILL_AS:
      return 'TM AS'
    default:
      return TickmillCompaniesEnum.TICKMILL_SC
  }
}

export const getCompanyFullName = (id: TickmillCompaniesEnum | undefined) => {
  switch (id) {
    case TickmillCompaniesEnum.TICKMILL_EU:
      return 'Tickmill EU Company'
    case TickmillCompaniesEnum.TICKMILL_UK:
      return 'Tickmill UK Company'
    case TickmillCompaniesEnum.TICKMILL_SC:
      return 'Tickmill SC Company'
    case TickmillCompaniesEnum.TICKMILL_PA:
      return 'Tickmill Partners Company'
    case TickmillCompaniesEnum.TICKMILL_AS:
      return 'Tickmill AS Company'
    default:
      return TickmillCompaniesEnum.TICKMILL_SC
  }
}

export enum TickmillCompaniesSuffixEnum {
  TICKMILL_EU = 'EU',
  TICKMILL_UK = 'UK',
  TICKMILL_SC = 'SC',
  TICKMILL_PA = 'PA',
  TICKMILL_AS = 'AS',
}

export const isTickmillGeneric = (account: AccountDetailedDto | undefined) => {
  if (!account) {
    return false
  }
  return account.tickmillCompany.id === TickmillCompaniesEnum.GENERIC
}

export const isTickmillGenericType = (type: TickmillCompaniesEnum | undefined) => {
  const companyType = type?.toString()
  return companyType ? Number.parseInt(companyType) === TickmillCompaniesEnum.GENERIC : false
}

export const isEitherASTypeOrSCType = (type: TickmillCompaniesEnum | undefined) =>
  isTickmillASType(type) || isTickmillSCType(type)

export const isTickmillEU = (account: AccountDetailedDto | undefined) => {
  if (!account) {
    return false
  }
  return account.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_EU
}

export const isTickmillEUType = (type: TickmillCompaniesEnum | undefined) => {
  if (!type) {
    return false
  }
  return type === TickmillCompaniesEnum.TICKMILL_EU
}

export const isTickmillSC = (account: AccountDetailedDto | undefined) => {
  if (!account) {
    return false
  }
  return account.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_SC
}

export const isTickmillSCType = (type: TickmillCompaniesEnum | undefined) => {
  if (!type) {
    return false
  }
  return type === TickmillCompaniesEnum.TICKMILL_SC
}

export const isTickmillUK = (account: AccountDetailedDto | undefined) => {
  if (!account) {
    return false
  }
  return account.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_UK
}

export const isTickmillUKType = (type: TickmillCompaniesEnum | undefined) => {
  if (!type) {
    return false
  }
  return type === TickmillCompaniesEnum.TICKMILL_UK
}

export const isTickmillAS = (account: AccountDetailedDto | undefined) => {
  if (!account) {
    return false
  }
  return account.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_AS
}

export const isTickmillASType = (type: TickmillCompaniesEnum | undefined) => {
  if (!type) {
    return false
  }
  return type === TickmillCompaniesEnum.TICKMILL_AS
}

export const isTickmillPartner = (account: AccountDetailedDto | undefined) => {
  if (!account) {
    return false
  }
  return account.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_PA
}

export const isTickmillPartnerType = (type: TickmillCompaniesEnum | undefined) => {
  if (!type) {
    return false
  }
  return type === TickmillCompaniesEnum.TICKMILL_PA
}

export const requiresAppTestImprovement = (company: TickmillCompaniesEnum): boolean => {
  return (
    company === TickmillCompaniesEnum.TICKMILL_EU || company === TickmillCompaniesEnum.TICKMILL_UK
  )
}
