import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../../global/button/Button'
import { Modal } from '../../../global/modal/Modal'
import { TwoFactorWarningState } from '../../../hooks/useTwoFactorAuthWithdrawal'
import { WalletDto } from '../../../model/WalletDto'
import { WalletBalancesOptions } from './WalletBalancesOptions'

import styles from './WalletOptionsModal.module.scss'

interface Props {
  wallet: WalletDto
  onWithdrawal: (walletId: string) => void
  onWalletInformationModal(): void
  onSetWalletOptionsData: (wallet?: WalletDto) => void
}

export const WalletOptionsModal: React.FC<Props> = (props) => {
  const { wallet, onWithdrawal, onWalletInformationModal, onSetWalletOptionsData } = props

  const { t } = useTranslation()

  return (
    <Modal
      pullDown
      cardClassName={styles.modal}
      closeModal={() => onSetWalletOptionsData(undefined)}
      render={({ closeModal }) => (
        <section className={classNames(styles.body)}>
          <WalletBalancesOptions
            wallet={wallet}
            onWithdrawal={onWithdrawal}
            onSetWalletInformationModalOpen={onWalletInformationModal}
            onSetWalletOptionsData={onSetWalletOptionsData}
          />

          <footer className={styles.footer}>
            <Button onClick={closeModal} type='button' appearance='secondary' fullWidth size='M'>
              {t('Cancel')}
            </Button>
          </footer>
        </section>
      )}
    />
  )
}
