import * as pdfMake from 'pdfmake/build/pdfmake';
import { TMDocLogo } from '../icons/Logos/TMDocLogo'
import { hasArabicWithSpacesAndDashes, isZero } from './validations'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const pdfFonts = require('pdfmake/build/vfs_fonts');

(window as any).pdfMake = pdfMake;
(window as any).pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;

(window as any).pdfMake.fonts = {
	ArialUnicodeMs: {
		normal: `${window.location.origin}/fonts/arial-unicode-ms.ttf`,
		bold: `${window.location.origin}/fonts/arial-unicode-ms.ttf`,
		italics: `${window.location.origin}/fonts/arial-unicode-ms.ttf`,
	},
};
interface PdfTableProps {
  title?: string
  data: string[][]
  fileName?: string
}

export function generatePDFTable(props: PdfTableProps): void {
  const { title, data, fileName } = props

  const reversedIfArabic = (input = '') => {
    if (hasArabicWithSpacesAndDashes(input)) {
      return input.split(' ').reverse().join('  ')
    }
    return input
  }
  const reversedTitle = reversedIfArabic(title)

  const [head, ...body] = data

  const headCell = [
    head.map((text) => ({
      text: reversedIfArabic(text),
      style: 'customTableHeader',
      alignment: 'left',
    })),
  ]

  const bodyCell = [
    body.map((x, index) =>
      x.map((text) => ({
        text: reversedIfArabic(text),
        style: isEven(index) ? 'customTableColumnEven' : 'customTableColumnOdd',
        alignment: 'left',
      }))
    ),
  ]

  const tableCell = {
    body: headCell.concat(...bodyCell),
    alignment: 'left',
    widths:
      data[0].length < 10
        ? [115, ...Array(data[0].length - 1).fill('*')]
        : [50, ...Array(data[0].length - 2).fill('auto'), '*'],
    width: '100%',
  }

  const docDefinition = {
    info: {
      title: reversedTitle,
      subject: reversedTitle,
      keywords: reversedTitle,
    },
    pageSize: 'A3',
    pageOrientation: 'landscape',
    pageMargins: [12, 16, 12, 16] as [number, number, number, number],
    content: [
      {
        marginBottom: 5,
        style: 'header',
        width: '100%',
        columns: [
          {
            text: reversedTitle,
            fontSize: 20,
          },
          { width: '*', text: '' },
          {
            alignment: 'right',
            svg: TMDocLogo,
          },
        ],
      },
      {
        layout: 'noBorders',
        style: 'customTable',
        table: tableCell,
        width: '*',
      },
    ].filter((x) => !!x),
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      customTable: {
        fontSize: 10,
      },
      miniText: {
        fontSize: 8,
      },
      customTableHeader: {
        margin: [4, 4, 4, 4] as [number, number, number, number],
        fillColor: '#ef0000',
        color: '#ffffff',
        bold: true,
        fontSize: 10,
      },
      customTableColumnEven: {
        margin: [4, 4, 4, 4] as [number, number, number, number],
        fillColor: '#ECECEC',
        color: '#000000',
        fontSize: 8,
      },
      customTableColumnOdd: {
        margin: [4, 4, 4, 4] as [number, number, number, number],
        color: '#000000',
        fontSize: 8,
      },
    },
    defaultStyle: {
      font: 'ArialUnicodeMs',
    },
  }
  pdfMake.createPdf(docDefinition as any).download(fileName || `transaction_report.pdf`)
}

const isEven = (n: number): boolean => {
  return isZero(n % 2)
}
