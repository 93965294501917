import { ReactNode, createContext, useEffect, useState } from 'react'

import { Loading } from '../global/Loading/Loading'
import { useSessionEntity } from '../global/context/EntityContext'
import { useGuardedContext } from '../global/useGuardedContext'
import { SupportContactsDto } from '../model/SupportContactsDto'
import { useApiClient } from './ApiClient'
import { ClientApiClient } from './clientApi'
import {
  TickmillCompaniesEnum,
  getTickmillCompanyByHostname,
  getTickmillSupportEmail,
  getTickmillSupportPhone,
} from './companyName.utils'

type SupportContactsContextType = SupportContactsDto | undefined

const SupportContactsContext = createContext<SupportContactsContextType>(undefined)
SupportContactsContext.displayName = 'SupportContactsContext'

interface Props {
  children: ReactNode
}

export function SupportContactsProvider({ children }: Props): React.ReactElement {
  const [supportContacts, setSupportContacts] = useState<SupportContactsDto | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const apiClient = useApiClient(ClientApiClient)
  const entity = useSessionEntity()

  useEffect(() => {
    const fetchSupportContacts = async () => {
      try {
        if (entity !== TickmillCompaniesEnum.GENERIC) {
          const contacts = await apiClient.getSupportContacts(entity)
          setSupportContacts(contacts)
        }
      } catch (error: unknown) {
        console.error('Failed to fetch support contacts:', error)
      } finally {
        setLoading(false)
      }
    }
    fetchSupportContacts()
  }, [apiClient, entity])

  if (loading) {
    return <Loading isLoading={loading} showLoadingIcon />
  }

  return (
    <SupportContactsContext.Provider value={supportContacts}>
      {children}
    </SupportContactsContext.Provider>
  )
}

export const useSupportContactsContext = (): SupportContactsContextType => {
  return useGuardedContext(SupportContactsContext)
}

export const useSupportContacts = (): SupportContactsDto => {
  const supportContacts = useSupportContactsContext()
  if (!supportContacts || !supportContacts.supportEmail) {
    const entity = getTickmillCompanyByHostname(true)
    const fallbackSupportContacts: SupportContactsDto = {
      supportPhoneNumber: getTickmillSupportPhone(entity),
      supportEmail: getTickmillSupportEmail(entity),
    }
    return fallbackSupportContacts
  }

  return supportContacts
}
