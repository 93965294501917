import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useHasUserAnyWallets } from '../../hooks/useHasUserAnyWallets'
import { isAccountActivatedStatus } from '../../model/AccountDetailedStatus'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { CommonPaths } from '../../utils/path'
import { Loading } from '../Loading/Loading'
import { useSessionLanguage } from '../context/SessionSettingsContext'
import { ToastContext, errorToast } from '../toast/Toast'
import { WalletSetupIndicator } from './WalletSetupIndicator'

import styles from './WalletSetupLoader.module.scss'

export const WalletSetupLoader: React.FC = () => {
  const setToast = useContext(ToastContext)
  const { hasUserAnyWallets, refetch } = useHasUserAnyWallets()
  const navigate = useNavigate()
  const locale = useSessionLanguage()
  const { account } = useAccountReadContext()
  const { refreshAccount } = useAccountWriteContext()
  const [isWalletInitialized, setWalletInitialized] = useState(false)
  const [walletsFetching, setWalletsFetching] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => setWalletInitialized(true), 2000)
    return () => clearTimeout(timer)
  }, [])

  const handleClickNext = async () => {
    try {
      setWalletsFetching(true)
      const wallets = await refetch()

      await refreshAccount(locale)

      if (!hasUserAnyWallets) {
        setToast(errorToast(t('errors.Something went wrong')))
      } else {
        const targetPath = isAccountActivatedStatus(account)
          ? CommonPaths.FirstTimeDepositDashboard
          : CommonPaths.FirstTimeDeposit
        navigate(targetPath, { state: { wallets } })
      }
    } finally {
      setWalletsFetching(false)
    }
  }

  return (
    <Loading
      wrapperClass={styles.loadingWrapper}
      disableIconAnimation={isWalletInitialized}
      showLoadingIcon
      renderText={() => (
        <WalletSetupIndicator
          onSetupComplete={handleClickNext}
          isButtonDisabled={!isWalletInitialized || walletsFetching}
        />
      )}
      isLoading
    />
  )
}
