import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useProductReadContext } from '../../global/context/ProductContext'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import IconButton from '../../global/iconButton/IconButton'
import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { InfoIcon } from '../../icons/InfoIcon'
import { NameDto } from '../../model/NameDto'
import { PlatformTypeEnum } from '../../model/PlatformTypeEnum'
import {
  TransactionDto,
  isTransactionTradingAccountBalanceConversion,
} from '../../model/TransactionDto'
import { TransactionStatus } from '../../model/TransactionStatus'
import { CurrencyType } from '../../model/WalletDto'
import { Text, TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { PageQuery } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import {
  TransactionType,
  getClientTransactionFieldsByTransactionType,
  getTransactionType,
} from '../../utils/transaction.utils'
import { isOne, isZero } from '../../utils/validations'
import { TransactionBadgeStatus } from './TransactionBadgeStatus'
import { getCreditTransaction, getDebitTransaction } from './TransactionHistoryTable'

import styles from './TransactionHistoryCard.module.scss'

interface Props {
  data: TransactionDto
  cardId?: string

  isLoading: boolean
  pageQuery?: PageQuery
  setPageQuery: ((pageQuery?: PageQuery | undefined) => void) | undefined
  setSelectedTransaction(id: string): void
  setHistoryModal(v: boolean): void
  setOptionModal({
    data,
    visible,
    step,
  }: {
    data?: TransactionDto
    visible: boolean
    step: number
  }): void
}

export const TransactionHistoryCard: React.FC<Props> = ({
  data,
  setHistoryModal,
  setSelectedTransaction,
  setOptionModal,
  cardId,
}) => {
  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const [expanded, setExpanded] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const { isDefaultCFDProductType } = useProductReadContext()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const isReversed = data.type.id === TransactionType.Reversed
  const { formatMoney, formatNumber } = useFormatNumber()
  const { scrollIntoView } = useScrollToElementIds()

  const reversedTransactionType = data.reversedTransactions?.[0].type.id

  const transactionType =
    isReversed && reversedTransactionType
      ? getTransactionType(reversedTransactionType)
      : getTransactionType(data.type.id)

  const transactionFields = getClientTransactionFieldsByTransactionType(
    transactionType,
    data,
    isDefaultCFDProductType(),
    isArabic,
    auth?.id
  )

  const fields = isReversed
    ? {
        ...transactionFields,
        from: transactionFields.to,
        to: transactionFields.from,
      }
    : transactionFields

  const handleSetTransactionHistoryModal = (id: string) => {
    setSelectedTransaction(id)
    setHistoryModal(true)
  }
  const canBeCancelled =
    (data.type.id === TransactionType.WithdrawToPaymentAgent ||
      data.type.id === TransactionType.WalletWithdrawal) &&
    data.state.id === TransactionStatus.AwaitingApproval

  const getPlatformTypeName = (platformType: NameDto<PlatformTypeEnum> | undefined) => {
    if (!platformType) {
      return ''
    }
    return platformType.id === PlatformTypeEnum.TickmillTrader
      ? t('Sign up.Tickmill Trader')
      : platformType.name
  }

  const isCrypto = fields.from.includes('Crypto Currency')

  const shouldHideRate = isOne(fields.rate) || (isCrypto && isZero(fields.rate))
  const cryptoCurrency = data.walletTransactions[0]?.otherCurrency.name
  const showCurrency = isCrypto && cryptoCurrency && data.state.id === TransactionStatus.Completed

  const creditTransaction = getCreditTransaction(transactionType, data)
  const debitTransaction = getDebitTransaction(transactionType, data)
  const platformTypeName = getPlatformTypeName(
    data.tradingAccountTransactions?.[0]?.tradingAccount?.platformType
  )

  return (
    <div
      onClick={() => {
        if (!expanded && cardId) {
          scrollIntoView([cardId])
        }
        setExpanded((prevState) => !prevState)
      }}
      className={styles.card}
      id={cardId}
    >
      {infoModal && (
        <Modal
          closeModal={() => setInfoModal(false)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('IB.Transaction exchange rate')}
              renderBody={() => (
                <section className='modal-card-body'>
                  <Text
                    className={styles.text}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'IB.Amounts based on currencies which are denominated in foreign currencies'
                      ),
                    }}
                  />
                </section>
              )}
              onConfirm={() => {
                setInfoModal(false)
              }}
            />
          )}
        />
      )}
      <div className={styles.title}>
        <TextSmallStrong>{data.type.name}</TextSmallStrong>
        <Text>{formatMoney(fields.amount, fields.currency as CurrencyType)}</Text>
      </div>
      <div className={styles.subtitle}>
        <TextSmall>{formatDate(data.createdDate, { formatType: dateFormat })}</TextSmall>
        <TextSmall
          onClick={() => handleSetTransactionHistoryModal(data.id)}
          className={styles.action}
        >
          <TransactionBadgeStatus {...data.state} />
        </TextSmall>
      </div>
      {expanded && (
        <Fragment>
          <div className={styles.subtitle}>
            <TextSmall className={styles.secondary}>{t('From')}</TextSmall>
            <TextSmall dir={isArabic ? 'ltr' : ''}>{fields.from}</TextSmall>
          </div>
          <div className={styles.subtitle}>
            <TextSmall className={styles.secondary}>{t('To')}</TextSmall>
            <TextSmall dir={isArabic ? 'ltr' : ''}>{fields.to}</TextSmall>
          </div>
          <div className={styles.subtitle}>
            <TextSmall className={styles.secondary}>{t('Wallet.Reference number')}</TextSmall>
            <TextSmall>{data.referenceId}</TextSmall>
          </div>
          {!!data.tradingAccountTransactions.length && (
            <div className={styles.subtitle}>
              <TextSmall className={styles.secondary}>{t('Trading Account.Platform')}</TextSmall>
              <TextSmall>{platformTypeName}</TextSmall>
            </div>
          )}
          {transactionType === TransactionType.TradingAccountBalanceConversion && (
            <>
              <div className={styles.subtitle}>
                <TextSmall className={styles.secondary}>{t('IB.Converted from')}</TextSmall>
                <TextSmall>
                  {formatMoney(creditTransaction?.otherAmount, creditTransaction?.otherCurrency.id)}
                </TextSmall>
              </div>
              <div className={styles.subtitle}>
                <TextSmall className={styles.secondary}>{t('IB.Converted to')}</TextSmall>
                <TextSmall>
                  {formatMoney(debitTransaction?.otherAmount, debitTransaction?.otherCurrency.id)}
                </TextSmall>
              </div>
            </>
          )}
          {!shouldHideRate && (
            <div className={styles.subtitle}>
              <TextSmall className={styles.secondary}>
                {t('IB.Transaction exchange rate')}
              </TextSmall>
              <TextSmall className='is-flex is-align-items-center'>
                <TextSmall className='is-flex is-align-items-center'>
                  {formatNumber(fields.rate)}
                </TextSmall>
                {!isTransactionTradingAccountBalanceConversion(data) && (
                  <TextSmall className='is-flex is-align-items-center ml-1'>
                    <IconButton onClick={() => setInfoModal(true)}>
                      <InfoIcon />
                    </IconButton>
                  </TextSmall>
                )}
              </TextSmall>
            </div>
          )}
          {!!showCurrency && (
            <div className={styles.subtitle}>
              <TextSmall className='text-secondary'>{t('Currency')}:</TextSmall>
              <TextSmall>{cryptoCurrency}</TextSmall>
            </div>
          )}
          {canBeCancelled && (
            <div className={styles.cancel}>
              <TextSmall onClick={() => setOptionModal({ data, visible: true, step: 2 })}>
                {t('Transactions.Cancel Transaction')}
              </TextSmall>
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}
