export function TickmillAppIcon() {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='48' height='48' fill='#CC0000' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.551 19.6968C16.4493 19.8734 16.3904 20.0714 16.3789 20.2748V26.7255L8.59961 31.2003V16.6628C8.62532 16.1533 8.76925 15.6567 9.02003 15.2123C9.27082 14.7679 9.62159 14.3877 10.0447 14.1019L22.5463 6.74713C22.9882 6.51913 23.4783 6.40015 23.9756 6.40015C24.4729 6.40015 24.963 6.51913 25.4048 6.74713L37.9043 14.1041C37.9655 14.1407 38.0238 14.1819 38.0788 14.2272L30.1664 18.7825L24.5596 15.5321C24.3783 15.4401 24.1779 15.3921 23.9745 15.3921C23.7711 15.3921 23.5707 15.4401 23.3894 15.5321L16.9646 19.2574C16.7943 19.3697 16.6527 19.5202 16.551 19.6968ZM31.5714 27.7254V21.2791L39.3507 16.7989V31.3363C39.325 31.8458 39.1811 32.3424 38.9303 32.7868C38.6795 33.2313 38.3287 33.6114 37.9056 33.8972L25.4051 41.2531C24.9632 41.4811 24.4731 41.6001 23.9758 41.6001C23.4785 41.6001 22.9884 41.4811 22.5466 41.2531L10.046 33.8972C9.98576 33.8592 9.92752 33.8181 9.87148 33.7741L17.7784 29.2188L23.3896 32.4692C23.5709 32.5612 23.7714 32.6092 23.9747 32.6092C24.1781 32.6092 24.3786 32.5612 24.5598 32.4692L30.9857 28.7439C31.1561 28.6315 31.2978 28.4809 31.3995 28.304C31.5012 28.1272 31.5601 27.929 31.5714 27.7254Z'
        fill='white'
      />
    </svg>
  )
}
