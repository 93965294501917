import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Loading } from '../../global/Loading/Loading'
import { useLogout } from '../../hooks/useLogout'
import { getVerifiedPhoneNumbers } from '../../model/AccountDetailedDto'
import { NameDto } from '../../model/NameDto'
import {
  Change2FAProvidersData,
  GoogleAuthenticator2FAProviderDataDto,
  TwoFAProviderEnum,
  TwoFAProviderItem,
  VerifyFistTimeUseData,
} from '../../model/TwoFactorAuthentication'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { ResponseError, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { FormSubmitValues } from '../../utils/formValidation'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { AccountSettingsBox } from './AccountSettingsBox'
import {
  ChangePasswordForm,
  ChangePasswordFormValues,
  PasswordInfoModal,
} from './ChangePasswordForm'
import {
  GoogleAuthenticatorFormModal,
  GoogleAuthenticatorModalFormValues,
} from './GoogleAuthenticatorFormModal'
import {
  ConfirmTurnOffTwoFactorAuthenticationModal,
  TwoFactorAuthentication,
  TwoFactorAuthenticationGenerateRecoveryCodesModal,
  TwoFactorAuthenticationInfoModal,
  TwoFactorAuthenticationPasswordFormModal,
  TwoFactorAuthenticationPasswordModalFormValues,
  TwoFactorAuthenticationSelectPhoneModal,
} from './TwoFactorAuthentication'

import styles from './AccountSettingsPage.module.scss'

export const SecuritySettingsPage: React.FC = () => {
  useScrollToTop()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const { logout } = useLogout()
  const { account } = useAccountReadContext()

  const [passwordModal, setPasswordModal] = useState(false)
  const [twoFactorAuthenticationInfoModal, setTwoFactorAuthenticationInfoModal] = useState(false)
  const [activeProviders, setActiveProviders] = useState<TwoFAProviderItem[]>([])
  const [selectedProvider, setSelectedProvider] = useState<NameDto | null>(null)
  const [selectPhoneModal, setSelectPhoneModal] = useState(false)
  const [turnOffConfirmationModal, setTurnOffConfirmationModal] = useState(false)
  const [twoFactorAuthenticationPasswordModal, setTwoFactorAuthenticationPasswordModal] = useState({
    isOpen: false,
    turnOn: false,
  })
  const [twoFactorGoogleAuthenticatorModal, setTwoFactorGoogleAuthenticatorModal] = useState(false)
  const [generateRecoveryCodesModal, setGenerateRecoveryCodesModal] = useState(false)
  const [selectedPhone, setSelectedPhone] = useState<string>()
  const [googleAuthenticatorData, setGoogleAuthenticatorData] =
    useState<GoogleAuthenticator2FAProviderDataDto | null>(null)
  const [googleAuthenticatorError, setGoogleAuthenticatorError] = useState(false)
  const [isGoogleAuthenticatorDataLoading, setIsGoogleAuthenticatorDataLoading] =
    useState<boolean>(false)
  const verifiedPhones = getVerifiedPhoneNumbers(account)

  const get2FAProvidersTypesCallback = useCallback(async () => {
    return apiClient.get2FAProvidersTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetch2FAProviders = useCallback(async () => {
    const twoFAProviders = await apiClient.get2FAProviders()
    setActiveProviders(twoFAProviders)
    return twoFAProviders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: twoFAProvidersTypes = [], isLoading: isTwoFAProvidersTypesLoading } = useFetchOne(
    get2FAProvidersTypesCallback
  )

  const { isLoading: isTwoFAProvidersLoading } = useFetchOne(fetch2FAProviders)

  const handleCloseGoogleAuthenticatorModal = () => {
    setTwoFactorGoogleAuthenticatorModal(false)
    setGoogleAuthenticatorError(false)
    setGoogleAuthenticatorData(null)
  }

  const getGoogleAuthenticatorData = async () => {
    try {
      const payload = { typeId: TwoFAProviderEnum.GoogleAuthenticator }
      setIsGoogleAuthenticatorDataLoading(true)
      const result: GoogleAuthenticator2FAProviderDataDto =
        await apiClient.enableGoogleAuthenticator2FAProviders(payload)

      result && setGoogleAuthenticatorData(result)
    } catch (err: ResponseError | any) {
      console.error(err)
      handleCloseGoogleAuthenticatorModal()
    } finally {
      setIsGoogleAuthenticatorDataLoading(false)
    }
  }

  const clearSessionAndLogout = async () => {
    logout()
    navigate('/logout')
  }

  const onClosePasswordModal = async () => {
    setPasswordModal(false)
    await clearSessionAndLogout()
  }

  const removeProvider = (id: number) => {
    setActiveProviders((prevProviders) => {
      return prevProviders.filter((provider) => provider.type.id !== id)
    })
  }

  const addProvider = (data: TwoFAProviderItem) => {
    setActiveProviders((prevProviders) => {
      return [...prevProviders, data]
    })
  }

  const onProviderToggle = (provider: NameDto, turnOn: boolean) => {
    setSelectedProvider(provider)
    if (!turnOn) {
      setTurnOffConfirmationModal(true)
    } else {
      switch (provider.id) {
        case TwoFAProviderEnum.GoogleAuthenticator:
          getGoogleAuthenticatorData()
          setTwoFactorGoogleAuthenticatorModal(true)
          break
        case TwoFAProviderEnum.Email:
          setTwoFactorAuthenticationPasswordModal({ isOpen: true, turnOn: true })
          break
        case TwoFAProviderEnum.SMS:
          const [verifiedPhone] = verifiedPhones || []
          if (verifiedPhone) {
            setSelectedPhone(verifiedPhone.countryCode + verifiedPhone.number)
          }
          setSelectPhoneModal(true)
          break
        default:
          break
      }
    }
  }

  const handleSubmitChangePassword = async (values: ChangePasswordFormValues) => {
    await apiClient.updatePassword(values)
    setPasswordModal(true)
  }

  const handleProviderDisable = async (data: Change2FAProvidersData) => {
    await apiClient.disable2FAProviders({ ...data, typeId: data.type.id })
    removeProvider(data.type.id)
  }

  const handleProviderEnable = async (data: Change2FAProvidersData) => {
    if (data.type.id === TwoFAProviderEnum.SMS && selectedPhone) {
      data.authMedium = selectedPhone
    }

    await apiClient.enable2FAProviders({ ...data, typeId: data.type.id })
    handleInitialShowRecoveryCodes()
    addProvider({ authMedium: data.authMedium ?? '', type: data.type })
  }

  const handleInitialShowRecoveryCodes = async () => {
    if (activeProviders.length < 1) {
      setGenerateRecoveryCodesModal(true)
    }
  }

  const onPasswordFormModalSubmit = async (
    values: FormSubmitValues<TwoFactorAuthenticationPasswordModalFormValues>
  ) => {
    if (!selectedProvider?.id || !values.password) {
      return
    }
    const payload = { type: selectedProvider, password: values.password }
    if (twoFactorAuthenticationPasswordModal.turnOn) {
      await handleProviderEnable(payload)
    } else {
      await handleProviderDisable(payload)
    }

    await fetch2FAProviders()
    setTwoFactorAuthenticationPasswordModal((prev) => ({ ...prev, isOpen: false }))
  }

  const onGoogleAuthenticatorFormModalSubmit = async (
    values: FormSubmitValues<GoogleAuthenticatorModalFormValues>
  ) => {
    if (selectedProvider?.id && values.verificationCode) {
      const payload: VerifyFistTimeUseData = {
        typeId: selectedProvider.id,
        code: values.verificationCode,
      }

      try {
        setIsGoogleAuthenticatorDataLoading(true)
        await apiClient.verify2FACodesFistTimeUse(payload)
        await fetch2FAProviders()
        handleCloseGoogleAuthenticatorModal()
        handleInitialShowRecoveryCodes()
        addProvider({ type: selectedProvider, authMedium: '' })
      } catch (err: ResponseError | any) {
        setGoogleAuthenticatorError(true)
        console.error(err)
      } finally {
        setIsGoogleAuthenticatorDataLoading(false)
      }
    }
  }

  const isLoading = isTwoFAProvidersTypesLoading || isTwoFAProvidersLoading

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      <TwoFactorAuthenticationInfoModal
        isOpen={twoFactorAuthenticationInfoModal}
        close={() => setTwoFactorAuthenticationInfoModal(false)}
      />
      <PasswordInfoModal isOpen={passwordModal} close={onClosePasswordModal} />
      <TwoFactorAuthenticationPasswordFormModal
        isOpen={twoFactorAuthenticationPasswordModal.isOpen}
        close={() =>
          setTwoFactorAuthenticationPasswordModal((prev) => ({ ...prev, isOpen: false }))
        }
        onSubmit={onPasswordFormModalSubmit}
      />
      <GoogleAuthenticatorFormModal
        isOpen={twoFactorGoogleAuthenticatorModal}
        close={handleCloseGoogleAuthenticatorModal}
        onSubmit={onGoogleAuthenticatorFormModalSubmit}
        isError={googleAuthenticatorError}
        data={googleAuthenticatorData}
        isLoading={isGoogleAuthenticatorDataLoading}
      />
      {!!verifiedPhones?.length && (
        <TwoFactorAuthenticationSelectPhoneModal
          isOpen={selectPhoneModal}
          close={() => setSelectPhoneModal(false)}
          selectedPhone={selectedPhone}
          selectPhone={setSelectedPhone}
          phoneNumbers={verifiedPhones}
          onConfirm={() => {
            setSelectPhoneModal(false)
            setTwoFactorAuthenticationPasswordModal({ isOpen: true, turnOn: true })
          }}
        />
      )}
      <ConfirmTurnOffTwoFactorAuthenticationModal
        onConfirm={() => {
          setTurnOffConfirmationModal(false)
          setTwoFactorAuthenticationPasswordModal({ isOpen: true, turnOn: false })
        }}
        close={() => setTurnOffConfirmationModal(false)}
        isOpen={turnOffConfirmationModal}
      />
      <TwoFactorAuthenticationGenerateRecoveryCodesModal
        close={() => setGenerateRecoveryCodesModal(false)}
        isOpen={generateRecoveryCodesModal}
      />
      <div className={styles.boxWrapper}>
        {!!twoFAProvidersTypes.length && (
          <AccountSettingsBox
            title={t('Profile.Two-factor authentication')}
            className={styles.boxFill}
            infoToggle={() => setTwoFactorAuthenticationInfoModal(true)}
          >
            <TwoFactorAuthentication
              onPhoneNumberChange={(phone, provider) => {
                setSelectedPhone(phone)
                setSelectedProvider(provider)
                setSelectPhoneModal(true)
              }}
              providers={twoFAProvidersTypes}
              activeProviders={activeProviders}
              onProviderToggle={onProviderToggle}
              onGenerateRecoveryCodes={() => setGenerateRecoveryCodesModal(true)}
              account={account}
            />
          </AccountSettingsBox>
        )}
        <AccountSettingsBox title={t('Profile.Password Change')}>
          <ChangePasswordForm onSubmit={handleSubmitChangePassword} />
        </AccountSettingsBox>
      </div>
    </Loading>
  )
}
