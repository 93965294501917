import { NameDto } from './NameDto'
import { PlatformTypeEnum } from './PlatformTypeEnum'
import { TradingAccountPlatformOverview } from './TradingAccountDetailDto'
import { CurrencyType } from './WalletDto'

const liveAccountGroupType = 'Live'

export interface CurrencyBalance {
  currency: CurrencyType
  balance: number
}

export interface BaseTradingAccountServer {
  id: number
  name: string
  publicName: string
}

export interface BaseTradingAccount {
  id: string
  name: string
  currency: NameDto<CurrencyType>
  server: BaseTradingAccountServer
  tickmillCompany: NameDto
  status: NameDto
  country: NameDto<string>
  accountType: NameDto
  platformType: NameDto<PlatformTypeEnum> & { dynamicLeverage: boolean }
  accountGroupType: NameDto | null
  externalId?: string
}

export interface TradingAccount extends BaseTradingAccount {
  platformOverview: {
    login: string
    group: string
    equity: number
    balance: number
    lastEndOfDayBalance: number
    usedMargin: number
    freeMargin: number
    marginLevel: number
    balances: CurrencyBalance[]
    availableToWithdraw: number
    availableToWithdrawUSD: number
    netLiquidationValueUSD: number
    reportingCurrency: CurrencyType
    balanceInDisplayCurrency?: number
  }
  client: NameDto<string>
  introducingBroker: NameDto<string> | null
  accountGroup: {
    id: string
    name: string
    currency: NameDto<CurrencyType>
    restrictions: []
    classificationType: NameDto
    tickmillCompany: NameDto
  }
  wallet: NameDto<string>
  accountGroupType: NameDto
  leverageType: NameDto
  restrictions: NameDto[]
  businessRules: CampaignBusinessRuleDto[]
  isSwapFree: boolean
  balance: number // TODO deprecated, to not use that
  equity: number // TODO deprecated, to not use that
  createdDate: string
  updatedDate: string
  campaigns: TradingAccountCampaign[]
}

export interface CampaignBusinessRuleDto {
  businessRule: BusinessRuleDto
  businessRuleValue: number | null
}

interface BusinessRuleDto {
  id: number
  name: string | null
  businessRuleType: NameDto
  hasValue: boolean
}

export interface TradingAccountCampaign {
  defaultCurrencyId: string
  id: string
  minDepositAmount: number
  name: string
  key: string
  status: NameDto<number>
}

export interface UpdateTradingAccountLeverage {
  leverageTypeId: number
}

export interface UpdateDemoTradingAccount {
  leverageTypeId: number
  accountGroupId: string
}

export interface DemoTradingAccount extends BaseTradingAccount {
  form: TradingAccountForm
  accountGroupServerId: string
  accountGroup: NameDto<string>
  leverage: NameDto
  lead: NameDto<string>
  created: string
  updated: string
  platformOverview: TradingAccountPlatformOverview
  introducingBroker: NameDto<string> | null
}

export interface AddDemoTradingAccount {
  id: string
  publicName: string
  dns: string
  platformType: NameDto
}

export interface TradingAccountForm {
  firstName: string
  lastName: string
  email: any
  phoneNumber: any
  depositAmount: number
}
export enum TradingAccountStatusType {
  Approved = 1,
  ReadOnly = 4,
}
export interface ResetTradingAccountPassword {
  tradingAccountId: string
  changeInvestorPassword: boolean
}

export enum BusinessRulesEnum {
  TransferFromPercentageProfit = 102,
}

export function isTradingAccountStatusApproved(status: NameDto): boolean {
  return status.id === TradingAccountStatusType.Approved
}

export const isLiveAccount = (accountGroupType: NameDto | null) =>
  accountGroupType?.name === liveAccountGroupType
