import React, { SyntheticEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../global/button/Button'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { createFormField } from '../global/formField/FormField'
import { createFormPasswordField } from '../global/formField/FormPasswordField'
import { Text } from '../ui/Typography/Typography'
import { TickmillCompaniesEnum } from '../utils/companyName.utils'
import { FormSubmitValues } from '../utils/formValidation'

import styles from './LoginPage.module.scss'

export interface LoginFormValues {
  email: string
  password: string
}

const PasswordField = createFormPasswordField<LoginFormValues>()
const EmailField = createFormField<LoginFormValues>()

const LoginFormUI: React.FunctionComponent<FormikProps<LoginFormValues> & OuterProps> = (props) => {
  const { handleSubmit, isSubmitting, setSubmitting, isValid, dirty, values } = props
  const { t } = useTranslation()

  const handleSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  const locale = useSessionLanguage()

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  return (
    <Form className={styles.form} onSubmit={handleSubmitForm}>
      <EmailField
        wrapperClassname={styles.field}
        name={'email'}
        label={t('Email Address')}
        type='email'
        placeholder={t('Email Address')}
        required
        hidePlaceholderAsterisk
      />
      <PasswordField
        className={styles.field}
        name='password'
        type={'password'}
        label={t('Password')}
        placeholder={t('Password')}
        required
        hidePlaceholderAsterisk
      />
      <div className={styles.action}>
        <Link to='/forgot-password' state={{ email: values.email }}>
          <Text>{t('Forgot Password.Forgot Password')}</Text>
        </Link>
      </div>
      <Button
        disabled={!(isValid && dirty)}
        className={styles.submitButton}
        type='submit'
        appearance='primary'
        size='M'
        loading={isSubmitting}
      >
        {t('Log in')}
      </Button>
      {props.entity !== TickmillCompaniesEnum.TICKMILL_PA && (
        <div className={styles.signup}>
          <Text>{t('Not a member')}</Text>
          <Link to='/sign-up' className={styles.signUpLinkBtn}>
            <Text>{t('Sign up.Sign up here')}</Text>
          </Link>
        </div>
      )}
    </Form>
  )
}

interface OuterProps {
  entity: TickmillCompaniesEnum
  onSubmit(values: FormSubmitValues<LoginFormValues>): Promise<void>
}

export const LoginForm = withFormik<OuterProps, LoginFormValues>({
  mapPropsToValues: () => {
    return {
      email: '',
      password: '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<LoginFormValues> = {}
    if (!values.email) {
      errors.email = t('Validation.Required')
    }
    if (!values.password) {
      errors.password = t('Validation.Required')
    }
    return errors
  },
  enableReinitialize: true,
})(LoginFormUI)
