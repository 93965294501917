import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Button } from '../../../../global/button/Button'
import { FieldGroup } from '../../../../global/form/FieldGroup'
import { createFormField } from '../../../../global/formField/FormField'
import { CardPaymentProviderProceedModal } from '../../../../global/modal/CardPaymentProviderProceedModal'
import { PaymentProviderProceedModal } from '../../../../global/modal/PaymentProviderProceedModal'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'
import { BlueInfoIcon } from '../../../../icons/BlueInfoIcon'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import {
  isPaymentProviderSCOrbitalCrypto,
  isPaymentProviderSCZotapayCrypto,
  isPaymentProviderSCZotapayCryptoMetablocks,
} from '../../../../model/PaymentProviderId'
import {
  isPaymentProviderBankType,
  isPaymentProviderCardProviderType,
  isPaymentProviderPSPType,
  isPaymentProviderPaymentAgentType,
} from '../../../../model/PaymentProviderType'
import { WalletPaymentProviderCurrency } from '../../../../model/WalletPaymentProviderParameters'
import { Note } from '../../../../ui/Note/Note'
import { TextSmall } from '../../../../ui/Typography/Typography'
import { DepositFormWarningNote } from '../../../DepositFormWarningNote'
import { CurrencyAmountChip } from '../../../Trading-Accounts/CurrencyAmountChip'
import { WalletWithdrawFormValues } from '../../WalletWithdraw/WalletWithdrawForm/WalletWithdrawForm'
import { WalletDepositCurrencyModal } from '../WalletDepositCurrencyModal'
import { CurrencyAmountField } from './CurrencyAmountField'
import { FormTermsConditionsPSP } from './FormTermsConditionsFactory'
import { WalletDepositFormValues } from './WalletDepositForm'
import { WalletFormFieldsFactory } from './WalletFormFieldsFactory'

import styles from './WalletDepositForm.module.scss'

const FormField = createFormField<WalletDepositFormValues>()

interface WalletDepositPSPFieldsProps {
  showAmountPresets?: boolean
  onCancel(): void
  minAmount?: number
  onClickTermsConditions(): void
}

export const WalletDepositPSPFields: React.FC<WalletDepositPSPFieldsProps> = (props) => {
  const { onCancel, minAmount, showAmountPresets, onClickTermsConditions } = props
  const { t } = useTranslation()

  // Required by WCA-2693 - New Proceed modal should only open for "Credit or Debit Card" method
  const creditDebitCardMethodId = '2d5792d8-4986-11ea-9e5d-000c29df7a17'

  const { values, setValues, isValid, handleSubmit, validateForm } =
    useFormikContext<WalletDepositFormValues>()

  const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false)
  const [isPaymentProviderProceedModalOpen, setPaymentProviderProceedModalOpen] = useState(false)
  const [isCardPaymentProviderProceedModalOpen, setCardPaymentProviderProceedModalOpen] =
    useState(false)

  const { values: walletValues } = useFormikContext<WalletWithdrawFormValues>()

  const handleExitPageConfirmationModalOpen = () => {
    onCancel()
  }

  useEffect(() => {
    validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCurrency = (walletPaymentProviderCurrency: WalletPaymentProviderCurrency) => {
    setValues({
      ...values,
      paymentProvider: {
        ...values.paymentProvider,
        currency: walletPaymentProviderCurrency,
      },
    })
    setCurrencyModalOpen(false)
  }

  const handleCurrencyModalOpen = () => {
    setCurrencyModalOpen(true)
  }

  const handleCurrencyModalClose = () => {
    setCurrencyModalOpen(false)
  }

  const handleSubmitForm = () => {
    handleSubmit()
  }

  const handlePaymentProviderProceed = () => {
    if (isPaymentProviderPSPType(values.paymentProvider.providerCategory.id)) {
      setPaymentProviderProceedModalOpen(true)
    }

    if (isPaymentProviderCardProviderType(values.paymentProvider.providerCategory.id)) {
      if (values.paymentProvider?.id === creditDebitCardMethodId) {
        setCardPaymentProviderProceedModalOpen(true)
      } else {
        setPaymentProviderProceedModalOpen(true)
      }
    }

    if (isPaymentProviderBankType(values.paymentProvider.providerCategory.id)) {
      handleSubmit()
    }

    if (isPaymentProviderPaymentAgentType(values.paymentProvider.providerCategory.id)) {
      handleSubmit()
    }
  }

  const handlePaymentProviderProceedModalClose = () => {
    setPaymentProviderProceedModalOpen(false)
  }

  const handleCardPaymentProviderProceedModalClose = () => {
    setCardPaymentProviderProceedModalOpen(false)
  }

  const disabled =
    values?.paymentProvider?.parameters &&
    !(values.paymentProvider.parameters.currencies.length > 1)

  const isCryptoNoteInfoVisible =
    isPaymentProviderSCOrbitalCrypto(values?.paymentProvider?.id) ||
    isPaymentProviderSCZotapayCrypto(values?.paymentProvider?.id) ||
    isPaymentProviderSCZotapayCryptoMetablocks(values?.paymentProvider?.id)

  const isAdditionalCryptoTextVisible =
    isPaymentProviderSCOrbitalCrypto(values?.paymentProvider?.id) ||
    isPaymentProviderSCZotapayCrypto(values?.paymentProvider?.id)

  return (
    <React.Fragment>
      {values.paymentProvider.id && (
        <React.Fragment>
          {isCurrencyModalOpen && (
            <WalletDepositCurrencyModal
              onSelectOption={handleCurrency}
              onClose={handleCurrencyModalClose}
            />
          )}
          {isPaymentProviderProceedModalOpen && (
            <PaymentProviderProceedModal
              onProceed={handleSubmitForm}
              onClose={handlePaymentProviderProceedModalClose}
            />
          )}
          {isCardPaymentProviderProceedModalOpen && (
            <CardPaymentProviderProceedModal
              onProceed={handleSubmitForm}
              onClose={handleCardPaymentProviderProceedModalClose}
            />
          )}
          <WalletFormFieldsFactory />
          <FieldGroup>
            <CurrencyAmountField />
            <div className={styles.currencyFieldWrapper}>
              <FormField
                name='paymentProvider.currency.id'
                label={t('Wallet.Currency')}
                disabled={disabled}
                rightIcon={!disabled && <DropArrowDownIcon />}
                onClick={handleCurrencyModalOpen}
              />
            </div>
          </FieldGroup>
          {showAmountPresets && (
            <FieldGroup>
              <CurrencyAmountChip minValue={minAmount} />
            </FieldGroup>
          )}
          {isCryptoNoteInfoVisible && (
            <div className='mb-4 mt-2'>
              <Note
                renderLeft={<BlueInfoIcon />}
                text={t('Wallet.Deposit the exact amount initially selected')}
              />
            </div>
          )}
          <FormField
            name='comment'
            label={t('Wallet.Additional Comment')}
            placeholder={t('Wallet.Additional Comment')}
          />
          <DepositFormWarningNote paymentProvider={walletValues?.paymentProvider} />
          {isAdditionalCryptoTextVisible && (
            <div className='mt-5'>
              <TextSmall>{t('Crypto info')}</TextSmall>
            </div>
          )}
          <div className='mt-4 mb-5'>
            <FormTermsConditionsPSP onClickTermsConditions={onClickTermsConditions} />
          </div>
          <TransactionFormActionButtonTemplate>
            <Button
              appearance='secondary'
              size='L'
              type='button'
              onClick={handleExitPageConfirmationModalOpen}
            >
              {t('Cancel')}
            </Button>
            <Button
              appearance='primary'
              size='L'
              type='button'
              onClick={handlePaymentProviderProceed}
              disabled={!isValid}
            >
              {t('Confirm')}
            </Button>
          </TransactionFormActionButtonTemplate>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
