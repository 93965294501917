import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useNavigate } from 'react-router-dom'

import { HowIBWorksModal } from '../../Profile/IntroducingBroker/HowIBWorksModal'
import { HowMultiTierWorksModal } from '../../Profile/IntroducingBroker/HowMultiTierWorksModal'
import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { CopyLink } from '../../global/copyLink/CopyLink'
import IconButton from '../../global/iconButton/IconButton'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { CloseIcon } from '../../icons/CloseIcon'
import { CopyIcon } from '../../icons/CopyIcon'
import { DocumentIcon } from '../../icons/DocumentIcon'
import { DummyIcon } from '../../icons/DummyIcon'
import { FreeTextAnswer, IBTestSubCategory } from '../../model/TestSectionsDto'
import { Text, TextH1, TextH3, TextStrong } from '../../ui/Typography/Typography'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { ResponseError, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { useIBReferralCodes } from '../../utils/useIBReferralCodes'
import { wait } from '../../utils/wait'
import BecomeIBModal from './BecomeIBModal'
import { IBCarousel } from './IBCarousel'
import { IBMultiTierCarousel } from './IBMultiTierCarousel'
import { IBQuestionnaire } from './IBQuestionnaire'
import { IBSignUpForm } from './IBSignUpForm'

import styles from './IBSignUpPage.module.scss'

interface IBSignUpPageProps {
  setShowThankYou?: React.Dispatch<React.SetStateAction<boolean>>
  reloadCodes?: () => void
}

export const IBSignUpPage: React.FC<IBSignUpPageProps> = (props) => {
  const { setShowThankYou, reloadCodes } = props

  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const { refreshAccount } = useAccountWriteContext()
  const { scrollIntoView } = useScrollToElementIds()
  const { refreshReferralCodes, isLoading: referralCodesLoading } = useIBReferralCodes()
  const [becomeIBModal, setBecomeIBModal] = useState(false)
  const [earnRewardsModal, setEarnRewardsModal] = useState(false)

  const [subCategoryId, setSubCategoryId] = useState<IBTestSubCategory>()
  const [loading, setLoading] = useState(false)
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const [ibTestId, setIBTestId] = useState<string | undefined>(undefined)
  const [displaySection, setDisplaySection] = useState<'type' | 'questionnaire'>('type')

  const ibSignUpTestCallback = useCallback(async () => {
    if (subCategoryId) {
      return apiClient.getIBSignUpTest(locale, subCategoryId)
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, subCategoryId])

  const { data: ibSignUpTest, isLoading: isLoadingIBSignUpTest } = useFetchOne(ibSignUpTestCallback)

  const legalDocumentsCallback = useCallback(async () => {
    const ibScheme =
      subCategoryId === IBTestSubCategory.Default
        ? '0'
        : subCategoryId === IBTestSubCategory.MultiTier
        ? '1'
        : ''
    return apiClient.getIntroducingBrokerLegalDocuments(
      locale,
      ibScheme ? `&IbSchemes=${ibScheme}` : ''
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, subCategoryId])

  const { data: legalDocuments = [], isLoading: isLoadingLegalDocuments } =
    useFetchOne(legalDocumentsCallback)

  const signUpNextStep = async () => setDisplaySection('questionnaire')

  const handleSignUpSubmit = async (selectedAnswers: string[], freeAnswers: FreeTextAnswer[]) => {
    try {
      scrollIntoView([ScrollToIds.IBSignupHeader, ScrollToIds.IBProfileHeader])
      if (!account || !ibSignUpTest?.sections[0].questions) {
        return
      }
      setLoading(true)
      if (setShowThankYou) {
        setShowThankYou(true)
      }

      await apiClient.signUpIntroducingBroker({
        category: 'IB information',
        clientId: account?.id,
        testId: ibSignUpTest?.id,
        dateTaken: new Date(),
        selectedAnswers,
        freeAnswers,
      })

      const testInfo = await apiClient.getIntroducingBrokerTestInfo(locale)

      if (setIBTestId) {
        setIBTestId(testInfo.id)
      }

      await wait()
      await refreshAccount(locale)
      await wait()
      await refreshReferralCodes()

      await wait()
      setLoading(false)
    } catch (e: ResponseError | any) {
      setLoading(false)
      const error = e as ResponseError
      console.error(error.response.response)
    }
  }

  useLayoutEffect(() => {
    scrollIntoView([ScrollToIds.IBProfileHeader])
  }, [
    ibTestId,
    loading || (isLoadingIBSignUpTest && isLoadingLegalDocuments) || referralCodesLoading,
  ])

  return (
    <Loading
      showLoadingIcon
      isLoading={
        loading || (isLoadingIBSignUpTest && isLoadingLegalDocuments) || referralCodesLoading
      }
    >
      {becomeIBModal && (
        <Modal
          closeModal={() => setBecomeIBModal(false)}
          render={() => (
            <BecomeIBModal subCategoryId={subCategoryId} onCancel={() => setBecomeIBModal(false)} />
          )}
        />
      )}

      {earnRewardsModal && (
        <Modal
          closeModal={() => setEarnRewardsModal(false)}
          render={() => (
            <InformationModal
              title={t(`IB.Tickmill's Loyalty Program offers you more earning opportunities`)}
              onCancelText={t('Got It')}
              onCancel={() => setEarnRewardsModal(false)}
            >
              <div className={styles.earnModal}>
                <div className={styles.item}>
                  <TextH3>{t('IB.Up to US$ 250,000')}</TextH3>
                  <Text isParagraph>
                    {t('IB.Redeem cash rewards on top of your regular IB payouts')}
                  </Text>
                </div>
                <div className={styles.item}>
                  <TextH3>{t('IB.9 Volume Tiers')}</TextH3>
                  <Text isParagraph>
                    {t(
                      'IB.Achieve the volume requirement on each tier to redeem the respective prizes, from US$ 100 to US$ 250,000'
                    )}
                  </Text>
                  <a
                    className='is-link'
                    target='_blank'
                    href='https://www.tickmill.com/partners/ib-loyalty'
                    rel='noreferrer'
                  >
                    {t('Loyalty Program.Find out more')}
                  </a>
                </div>
              </div>
            </InformationModal>
          )}
        />
      )}

      {ibTestId ? (
        <div className={styles.ibSignupPage}>
          <div className={styles.wrapper}>
            <ThankYouPage
              reloadCodes={reloadCodes}
              multiTier={subCategoryId === IBTestSubCategory.MultiTier}
            />
          </div>
        </div>
      ) : (
        <div id={ScrollToIds.IBSignupContainer} className={styles.ibSignupPage}>
          {displaySection === 'questionnaire' && (
            <div className={styles.titleWrapper}>
              <IconButton
                onClick={() => {
                  setDisplaySection('type')
                  scrollIntoView([ScrollToIds.IBSignupHeader, ScrollToIds.IBProfileHeader])
                }}
              >
                <CloseIcon />
              </IconButton>
              <TextStrong className={styles.title}>
                {subCategoryId === IBTestSubCategory.MultiTier
                  ? t('IB.Multitier Program')
                  : t('IB.Introducing Broker Program')}
              </TextStrong>
              <DummyIcon />
            </div>
          )}
          <div className={styles.wrapper}>
            {displaySection === 'type' && (
              <div className={styles.formInputWrapper}>
                <TextH3>{t('IB.Register')}</TextH3>
                <p className='is-link' onClick={() => setBecomeIBModal(true)}>
                  {t('IB.Why should I become an IB?')}
                </p>
                <p className='is-link' onClick={() => setEarnRewardsModal(true)}>
                  {t('IB.Earn additional rewards with our IB Loyalty Program')}
                </p>
                <IBSignUpForm
                  subCategoryId={subCategoryId}
                  setSubCategoryId={setSubCategoryId}
                  handleSignUp={signUpNextStep}
                />
              </div>
            )}
            {displaySection === 'questionnaire' && ibSignUpTest && (
              <div className={styles.formInputWrapper}>
                <IBQuestionnaire
                  legalDocuments={legalDocuments}
                  testData={ibSignUpTest}
                  onSubmit={handleSignUpSubmit}
                  isMultiTier={subCategoryId === IBTestSubCategory.MultiTier}
                />
              </div>
            )}
            <div className={styles.carouselWrapper}>
              {subCategoryId === IBTestSubCategory.Default && <IBCarousel />}
              {subCategoryId === IBTestSubCategory.MultiTier && <IBMultiTierCarousel />}
              {subCategoryId !== IBTestSubCategory.Default &&
                subCategoryId !== IBTestSubCategory.MultiTier && <DocumentIcon size={100} />}
            </div>
          </div>
        </div>
      )}
    </Loading>
  )
}

interface ThankYouPageProps {
  multiTier?: boolean
  reloadCodes?: () => void
}

const ThankYouPage: React.FC<ThankYouPageProps> = (props) => {
  const { multiTier, reloadCodes } = props

  const navigate = useNavigate()
  const { account } = useAccountReadContext()
  const { ibCode, mtCode } = useIBReferralCodes()

  const [isHowIBWorksModalOpen, setHowIBWorksModalOpen] = useState(false)
  const [isHowMIBWorksModalOpen, setHowMIBWorksModalOpen] = useState(false)

  const { t } = useTranslation()

  const navigateTo = () => {
    if (reloadCodes) {
      reloadCodes()
    }
    if (multiTier) {
      if (account?.visibilityConfiguration.hasMultiTier) {
        navigate('/dashboard/introducing-broker/multi-tier')
      } else {
        navigate('/dashboard/introducing-broker/wallets')
      }
    } else if (!matchPath(window.location.pathname, 'dashboard/introducing-broker/wallets')) {
      navigate('/dashboard/introducing-broker/wallets')
    }
  }

  if (ibCode || (multiTier && mtCode)) {
    return (
      <>
        {isHowIBWorksModalOpen && (
          <Modal
            closeModal={() => setHowIBWorksModalOpen(false)}
            render={() => (
              <HowIBWorksModal
                ibCode={multiTier ? mtCode : ibCode}
                onCancel={() => setHowIBWorksModalOpen(false)}
              />
            )}
          />
        )}
        {isHowMIBWorksModalOpen && (
          <Modal
            closeModal={() => setHowMIBWorksModalOpen(false)}
            render={({ closeModal }) => (
              <HowMultiTierWorksModal onCancel={closeModal} ibCode={mtCode} />
            )}
          />
        )}
        <div className={styles.feedbackWrapper}>
          <div className={styles.header}>
            <TextH1>{t('Thank You!')}</TextH1>
            <Text>{t('IB.You have become an Introducing Broker!')}</Text>
            <Text>
              {t(
                'IB.Invite traders to join Tickmill by using your personal link and get a permanent commission on every trade they make.'
              )}
            </Text>
          </div>
          <div className={styles.codeField}>
            <Text>{t('IB.Your Referral Code') + ':'}</Text>
            <div className={styles.codeWrapper}>
              <TextH3>{multiTier ? mtCode : ibCode}</TextH3>
              <CopyLink value={multiTier ? mtCode : ibCode}>
                <CopyIcon size={20} />
              </CopyLink>
            </div>
          </div>
          {!multiTier && (
            <div>
              <span className='is-link' onClick={() => setHowIBWorksModalOpen(true)}>
                {t('IB.How IB works?')}
              </span>
            </div>
          )}
          {!!multiTier && (
            <div>
              <span className='is-link' onClick={() => setHowMIBWorksModalOpen(true)}>
                {t('IB.MultiTier.How Multi-tier works')}?
              </span>
            </div>
          )}
          <Button
            className={styles.submitButton}
            type='button'
            appearance='secondary'
            size='M'
            fullWidth
            onClick={navigateTo}
          >
            {t('Ok')}
          </Button>
        </div>
      </>
    )
  }

  return (
    <div className={styles.feedbackWrapper}>
      <div className={styles.header}>
        <TextH3>{t('IB.Thank you for submitting your application!')}</TextH3>
        <p>{t('IB.Your application is waiting for activation. Thank you for your patience.')}</p>
        <Button type='button' appearance='secondary' size='L' onClick={navigateTo}>
          {t('Ok')}
        </Button>
      </div>
    </div>
  )
}
