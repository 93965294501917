import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Button } from '../../../../global/button/Button'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { createFormCheckBoxField } from '../../../../global/formField/FormCheckBoxField'
import { Locale } from '../../../../global/locale/Locale'
import { WalletPaymentAgentTransferInformationDto } from '../../../../model/WalletPaymentAgentTransferInformationDto'
import { WalletDepositCUPTermsModal } from '../WalletDepositTermsModal'
import { WalletDepositFormValues, isChinaUnionPayPSP } from './WalletDepositForm'

const FormCheckBoxField = createFormCheckBoxField<WalletDepositFormValues>()

export const FormTermsConditionsPSP: FC<{ onClickTermsConditions(): void }> = ({
  onClickTermsConditions,
}) => {
  const [isCUPTermsModalOpen, setCUPTermsModalOpen] = useState(false)

  const locale = useSessionLanguage()

  const handleCUPTermsModalOpen = () => {
    setCUPTermsModalOpen(true)
  }

  const handleCUPTermsModalClose = () => {
    setCUPTermsModalOpen(false)
  }

  const { t } = useTranslation()

  const { values } = useFormikContext<WalletDepositFormValues>()

  const { title, terms } = getChinaUnionPayResponsibility(locale)
  return (
    <React.Fragment>
      {isCUPTermsModalOpen && (
        <WalletDepositCUPTermsModal
          onClose={handleCUPTermsModalClose}
          title={title}
          terms={terms}
        />
      )}
      <FormCheckBoxField
        name='terms.responsibility'
        value={values.terms.responsibility}
        isTextSmall
      >
        <span>
          {t('I hereby acknowledge that Tickmill shall not be held responsible for any delays')}
        </span>
      </FormCheckBoxField>
      <FormCheckBoxField name='terms.paymentOperations' isTextSmall>
        {t('I have read all instructions and agree with')}{' '}
        <Button appearance='link' type='button' onClick={onClickTermsConditions}>
          {t('Terms & Conditions')}
        </Button>{' '}
        {t('of payment operations')}
      </FormCheckBoxField>
      {isChinaUnionPayPSP(values.paymentProvider.id || '') && (
        <FormCheckBoxField
          name='terms.cupResponsibility'
          value={values.terms.cupResponsibility}
          isTextSmall
        >
          <span>
            {t('I have read all instructions and agree with')}{' '}
            <Button appearance='link' type='button' onClick={handleCUPTermsModalOpen}>
              {t('Important Notice for China UnionPay Deposits')}
            </Button>
          </span>
        </FormCheckBoxField>
      )}
    </React.Fragment>
  )
}

interface FormTermsConditionsPaymentAgentProps {
  paymentAgent: WalletPaymentAgentTransferInformationDto
  onClickTermsConditions(): void
}

export const FormTermsConditionsPaymentAgent: React.FC<FormTermsConditionsPaymentAgentProps> = (
  props
) => {
  const { paymentAgent, onClickTermsConditions } = props

  const { values, setValues } = useFormikContext<WalletDepositFormValues>()

  useEffect(() => {
    setValues({
      ...values,
      terms: {
        ...values.terms,
        paymentOperations: paymentAgent.powerOfAttorney.accepted,
        powerOfAttorney: paymentAgent.powerOfAttorney.accepted,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAgent.powerOfAttorney.accepted])

  const { t } = useTranslation()

  return (
    <React.Fragment>
      {!paymentAgent.powerOfAttorney.accepted && <br />}
      <FormCheckBoxField
        name='terms.powerOfAttorney'
        value={values.terms.powerOfAttorney}
        disabled={paymentAgent.powerOfAttorney.accepted}
      >
        {t('I have read, understood and consent to the')}{' '}
        <a
          href={paymentAgent.powerOfAttorneyUrl}
          target='_blank'
          rel='noreferrer'
          className='is-link'
        >
          {t('Payment Agent.Power of Attorney')}
        </a>
        {'.'}
      </FormCheckBoxField>
      <FormCheckBoxField
        name='terms.paymentOperations'
        value={values.terms.paymentOperations}
        disabled={paymentAgent.powerOfAttorney.accepted}
      >
        {t('I have read all instructions and agree with')}{' '}
        <Button appearance='link' type='button' onClick={onClickTermsConditions}>
          {t('Terms & Conditions')}
        </Button>{' '}
        {t('of payment operations')}
      </FormCheckBoxField>
    </React.Fragment>
  )
}

export const getChinaUnionPayResponsibility = (
  locale: Locale
): {
  title: string
  terms: string[]
} => {
  const list = {
    en: {
      title: 'Important Notice for China UnionPay Deposits',
      terms: [
        'We do not accept Alipay, WeChat, or ATM payments. This may result in delays or loss of funds being credited.',
        'The beneficiary bank account is valid for the current transaction only. For each deposit, please initiate a deposit request in the Client Area to obtain the latest beneficiary bank account details. DO NOT transfer funds directly to previously used bank accounts, this may result in delays or loss of funds being credited.',
        'If the payment information page requires you to fill in a memo or remark when making the payment, please fill in the provided memo or remark as requested. However, if there is no requirement to fill in a memo or remark, please leave it blank.',
        'The payment amount must match the specified amount on the payment page to ensure successful deposit. Any discrepancies may result in the deposit not being credited or lost.',
        'Please complete the payment within the specified time as indicated on the payment page. If the time is exceeded, please initiate a new deposit request, otherwise it may result in delays or loss of funds being credited.',
        'Please read the instructions provided on the payment information page carefully and follow the instructions to complete the transaction.',
        'If the deposit fails to be credited to your account in a timely manner, please contact us at <a href="mailto:cn@tickmill.com">cn@tickmill.com</a> within 72 hours and provide the valid payment proof as requested to verify your deposit. We will not be held responsible for any losses, including but not limited to deposit delays or losses, resulting from your failure to provide the required documentation in a timely manner.',
        'Tickmill shall not be held responsible for any financial losses incurred because of violation of the above terms.',
      ],
    },

    zh: {
      title: '中国银联入金注意事项',
      terms: [
        '不接受支付宝、微信、ATM转账，这将导致入金到账延迟或丢失。',
        '收款信息仅当次有效。每次入金请在客户专区重新发起入金请求以获取最新的收款信息。请勿直接转账至之前使用过的银行账户，否则可能导致入金到账延迟或丢失。',
        '如果收款信息的页面要求转账时填写附言(备注)，请在转账时在汇款附言栏填写页面提供的附言(备注)内容。但是如果没有要求您填写附言(备注)，请留空不要填写。',
        '转账金额必须与收款页面要求的金额一致，否则将导致入金到账延迟或丢失。',
        '请在页面规定的时间内完成转账，如果超时请重新发起入金请求，否则将导致入金到账延迟或丢失。',
        '请仔细阅读收款信息页面的提示信息，并按指示完成操作。',
        '如果入金未及时到账，请在72小时内通过 <a href="mailto:cn@tickmill.com">cn@tickmill.com</a> 与我们联系并提供我们要求的有效付款凭证以验证您的入金。我们不对因您未能及时提供所需文件导致的损失负责，包括但不限于入金到账延迟或丢失。',
        '对于因违反以上条款造成的资金损失，本公司概不负责。',
      ],
    },
  }
  return list[locale as 'en' | 'zh'] || []
}
