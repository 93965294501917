import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import IconButton from '../../global/iconButton/IconButton'
import { CloseIcon } from '../../icons/CloseIcon'
import { useWindowResize } from '../../utils/domUtils'
import { Text, TextStrong } from '../Typography/Typography'

import styles from './Alert.module.scss'

interface AlertProps {
  text: string
  renderLeft?: React.ReactNode
  renderRight?: React.ReactNode
  onClose?(): void
}

export const Alert: React.FC<AlertProps> = (props) => {
  const isMobile = useWindowResize()
  return isMobile ? <MobileAlert {...props} /> : <DesktopAlert {...props} />
}

const DesktopAlert: React.FC<AlertProps> = (props) => {
  const { text, renderLeft, renderRight } = props

  return (
    <div className={styles.alert}>
      <AlertContent>
        <div>{renderLeft}</div>
        {text && <Text isParagraph>{text}</Text>}
        <div>{renderRight}</div>
      </AlertContent>
      <NoteCloseButton {...props} className={styles.close} />
    </div>
  )
}

const MobileAlert: React.FC<AlertProps> = (props) => {
  const { text, renderRight } = props

  const { t } = useTranslation()

  return (
    <div className={styles.alert}>
      <AlertContent>
        <NoteCloseButton {...props} className={styles.close} />
        <div className={styles.text}>
          {text && <TextStrong>{text}</TextStrong>}
          <Text isParagraph>{t('Tap for more details')}</Text>
        </div>
      </AlertContent>
      <div>{renderRight}</div>
    </div>
  )
}

const AlertContent: React.FC<PropsWithChildren> = (props) => {
  const { children } = props

  return <div className={styles.content}>{children}</div>
}

interface NoteCloseButtonProps extends AlertProps {
  className?: string
}

const NoteCloseButton: React.FC<NoteCloseButtonProps> = (props) => {
  const { onClose, className } = props

  return (
    <IconButton onClick={onClose} className={className}>
      <CloseIcon />
    </IconButton>
  )
}
