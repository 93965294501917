import React from 'react'

import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { BankAccount, isBankAccountApproved } from '../../model/BankAccount'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import { DocumentClientAreaDto } from '../../model/DocumentClientAreaDto'
import { PageMetaData, PageQuery } from '../../utils/ApiClient'
import { useWindowResize } from '../../utils/domUtils'
import { UploadedDocumentsCards as UploadedDocumentsCardsView } from './UploadedDocumentCards'
import { UploadedDocumentsTable as UploadedDocumentsTableView } from './UploadedDocumentsTable'

interface UploadedDocumentsProps {
  documents: DocumentClientAreaDto[]
  bankAccounts: BankAccount[]
  isLoading: boolean
  pageQuery?: PageQuery
  meta?: PageMetaData
  setPageQuery: ((pageQuery?: PageQuery | undefined) => void) | undefined
  forceRefresh(): void
  setIsPaginationEntrySelected?: (isPaginationEntrySelected: boolean) => void
}

export const isUploadLocked = (
  doc: DocumentClientAreaDto,
  account: AccountDetailedDto | undefined
) => {
  return (
    (doc.category.id === DocumentCategoryType.Personal && account?.isPersonalInfoLocked) ||
    (doc.category.id === DocumentCategoryType.Address && account?.isAddressInfoLocked) ||
    (doc.category.id === DocumentCategoryType.Additional && account?.isAdditionalInfoLocked)
  )
}

const useShowUploadedDocuments = (props: UploadedDocumentsProps) => {
  const { documents, meta } = props

  return {
    isPage: !!documents.length && meta,
  }
}

export const UploadedDocuments: React.FC<UploadedDocumentsProps> = (props) => {
  const {
    documents,
    forceRefresh,
    meta,
    bankAccounts,
    isLoading,
    setPageQuery,
    setIsPaginationEntrySelected,
  } = props

  const isMobile = useWindowResize()
  const { isPage } = useShowUploadedDocuments(props)

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      {!isMobile && (
        <UploadedDocumentsTableView
          documents={documents}
          forceRefresh={forceRefresh}
          readOnlyDocumentIds={filterBankAccountsDocumentIds(bankAccounts)}
        />
      )}
      {isMobile && (
        <UploadedDocumentsCardsView
          documents={documents}
          forceRefresh={forceRefresh}
          readOnlyDocumentIds={filterBankAccountsDocumentIds(bankAccounts)}
        />
      )}
      {isPage && setIsPaginationEntrySelected && (
        <Paging
          scrollToHeaderId={ScrollToIds.UploadedDocumentsHeader}
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsPaginationEntrySelected(true)
            }
            setPageQuery!({
              pageIndex,
              pageSize,
            })
          }}
          mode='simple'
        />
      )}
    </Loading>
  )
}

export const UploadedDocumentsCards = (props: UploadedDocumentsProps) => {
  const { documents, isLoading, bankAccounts, forceRefresh } = props

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      <UploadedDocumentsCardsView
        documents={documents}
        forceRefresh={forceRefresh}
        readOnlyDocumentIds={filterBankAccountsDocumentIds(bankAccounts)}
      />
    </Loading>
  )
}

const filterBankAccountsDocumentIds = (bankAccounts: BankAccount[]): string[] => {
  return bankAccounts?.flatMap((item) =>
    isBankAccountApproved(item) && item.document ? [item.document.id] : []
  )
}
