import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TMEntityLogo } from '../../icons/TickmillLogoIcons'
import { LuckyDrawCampaign, Sector } from '../../model/LuckyDrawDto'
import { TextH3, TextHero, TextSmallStrong } from '../../ui/Typography/Typography'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { Button } from '../button/Button'
import { useSessionEntity } from '../context/EntityContext'
import { useSessionLanguage } from '../context/SessionSettingsContext'
import { FortuneWheel, WheelRef } from '../fortuneWheel/FortuneWheel'

import styles from './LuckyDrawModal.module.scss'

export interface LuckyDrawModalProps {
  luckyCampaign: LuckyDrawCampaign
  passSetPlaying: (playing: boolean) => void
  fetchCampaign?: () => void
  onClose: () => void
}

export const LuckyDrawModal: React.FC<LuckyDrawModalProps> = (props) => {
  const { luckyCampaign, passSetPlaying, fetchCampaign, onClose } = props

  const { t } = useTranslation()

  const isMobile = useWindowResize()
  const locale = useSessionLanguage()
  const entity = useSessionEntity()

  const apiClient = useApiClient(ClientApiClient)

  const fortuneWheelRef = useRef<WheelRef>(null)
  const [winner, setWinner] = useState<number>(2)
  const [playing, setPlaying] = useState<boolean>(false)
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
  const [won, setWon] = useState<boolean>(false)
  const [showMobileWin, setShowMobileWin] = useState<boolean>(false)
  const [chances, setChances] = useState<number>(0)
  const [drawn, setDrawn] = useState<boolean>(false)
  const [sectors, setSectors] = useState<Sector[]>([])

  const { campaignId, minDeposit, maxReward } = luckyCampaign

  const Logo = TMEntityLogo[entity]

  const getPrizes = async () => {
    const prizes = await apiClient.getLuckyDrawPrizes(campaignId, locale)
    if (prizes.length % 2) {
      prizes.push({
        id: 'no-prize',
        amount: t('luckyDraw.noPrize'),
        campaignId,
      })
    }
    setSectors(prizes)
  }

  const getChances = async () => {
    setChances(await apiClient.getLuckyDrawChances(campaignId))
  }

  const spinTheWheel = async () => {
    await setPlaying(true)
    const prize = await apiClient.getLuckDrawPrize(campaignId)
    await setWinner(sectors?.findIndex((sector) => sector.id === prize.id))
    await setDrawn(true)
    fortuneWheelRef?.current?.startSpinning()
  }

  useEffect(() => {
    getPrizes()
    getChances()
    fetchCampaign?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (won && isMobile) {
      setTimeout(() => setShowMobileWin(true), 1000)
    }
  }, [won, isMobile])

  useEffect(() => {
    passSetPlaying(playing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing])

  const TermsLink = (
    <span className={styles.termsLink}>
      <a href={luckyCampaign.termsAndConditionsUrl} target='_blank' rel='noopener noreferrer'>
        {t('luckyDraw.termsConditions')}
      </a>
    </span>
  )

  const WonText = (
    <div className={styles.winningsBox}>
      <div>
        {sectors.length && (sectors[winner]?.amount as number) > 0 ? (
          <>
            <TextSmallStrong isParagraph className={styles.winnings}>
              {t('luckyDraw.congratulations')}
              <br />
              {t('luckyDraw.youHaveWon')}{' '}
              <span className={styles.highlight}>
                <>
                  {(sectors.length > 0 && sectors[winner].currency?.id) || <span>USD</span>}{' '}
                  {sectors.length > 0 ? sectors[winner].amount : 0}
                  <span>!</span>
                </>
              </span>
            </TextSmallStrong>
            <TextSmallStrong isParagraph className={styles.note}>
              {t('luckyDraw.willDeposit', {
                currency: sectors[winner]?.currency?.id || 'USD',
              })}
            </TextSmallStrong>
          </>
        ) : (
          <TextSmallStrong isParagraph className={styles.winnings}>
            {t('luckyDraw.noPrizeWon')}
          </TextSmallStrong>
        )}
      </div>
      <div className={styles.buttonBox}>
        <Button
          className={styles.submitButton}
          onClick={() => onClose()}
          appearance='secondary'
          size='S'
        >
          {t('luckyDraw.buttons.close')}
        </Button>
      </div>
    </div>
  )

  const NoSpinsText = (
    <div className={styles.interactiveBox}>
      <div className={styles.agreeBox}>
        <label className={styles.agree}>
          <span>
            {luckyCampaign.canGenerateMoreChances
              ? t('luckyDraw.newDeposit')
              : t('luckyDraw.usedAllChances')}{' '}
            {TermsLink} {t('luckyDraw.forDetails')}
          </span>
        </label>
      </div>
      <div className={classNames(styles.buttonBox, styles.rightFlex)}>
        <Button
          className={styles.submitButton}
          type='submit'
          disabled={playing}
          onClick={() => onClose()}
          appearance='plain'
          size='S'
        >
          {t('luckyDraw.buttons.close')}
        </Button>
      </div>
    </div>
  )

  return (
    <div className={styles.luckyDrawModal}>
      <section
        className={classNames(styles.wheelBox, {
          [styles.mobile]: isMobile && !drawn,
          [styles.lightBg]: showMobileWin,
        })}
      >
        <div className={styles.titleBox}>
          <TextHero
            className={classNames(styles.title, {
              [styles.lightTitle]: drawn && !showMobileWin,
            })}
          >
            {t('luckyDraw.title')}
          </TextHero>
          <div
            className={classNames(styles.logo, {
              [styles.logoLight]: !showMobileWin,
            })}
          >
            <Logo />
          </div>
        </div>
        {won ? (
          <div
            className={classNames(styles.prizeBoxWrap, {
              [styles.miniWrap]: showMobileWin,
            })}
          >
            <div
              className={classNames(styles.prizeBox, {
                [styles.mini]: showMobileWin,
              })}
            >
              <h2 className={styles.prizeText}>
                {t('luckyDraw.youWon')}
                <br />
                {sectors.length > 0 ? <span>{sectors[winner].amount}</span> : <span>0</span>}
                <span>$</span>
              </h2>
            </div>
          </div>
        ) : sectors.length > 0 ? (
          <FortuneWheel
            ref={fortuneWheelRef}
            winnerIndex={winner}
            sectors={sectors}
            setWon={() => {
              setPlaying(false)
              setWon(true)
            }}
          />
        ) : null}
        {showMobileWin && WonText}
      </section>
      <section
        className={classNames(styles.detailsBox, {
          [styles.mobile]: isMobile && drawn,
        })}
      >
        <div className={styles.titleBox}>
          <TextH3 className={styles.title}>{t('luckyDraw.title')}</TextH3>
          <div className={styles.logo}>
            <Logo />
          </div>
        </div>
        {won ? (
          WonText
        ) : (
          <>
            {chances > 0 ? (
              <TextSmallStrong isParagraph className={styles.note}>
                {t('luckyDraw.youHaveChances', { chances })}
              </TextSmallStrong>
            ) : luckyCampaign.canGenerateMoreChances ? (
              <TextSmallStrong isParagraph className={styles.note}>
                {t('luckyDraw.subtitle')}
              </TextSmallStrong>
            ) : null}
            <ul className={styles.conditions}>
              {t<string, string[]>('luckyDraw.newConditions', {
                returnObjects: true,
                minDeposit,
                maxReward,
              })?.map((condition, index) => (
                <li className={styles.term} key={index}>
                  {condition}
                </li>
              ))}
            </ul>
            {chances ? (
              <div
                className={classNames(styles.interactiveBox, {
                  [styles.rightFlex]: playing,
                })}
              >
                {!playing && (
                  <div className={styles.agreeBox}>
                    <label className={styles.agree}>
                      <input
                        type='checkbox'
                        checked={termsAccepted}
                        className={styles.checkbox}
                        onChange={(e) => setTermsAccepted(e.target.checked)}
                      ></input>
                      <span>
                        {t('luckyDraw.agree') + ' '}
                        {TermsLink}
                      </span>
                    </label>
                  </div>
                )}
                <div className={styles.buttonBox}>
                  {!playing && (
                    <Button
                      className={styles.submitButton}
                      type='submit'
                      disabled={playing}
                      onClick={() => onClose()}
                      appearance='plain'
                      size='S'
                    >
                      {t('luckyDraw.buttons.notNow')}
                    </Button>
                  )}
                  <Button
                    appearance='primary'
                    size='S'
                    disabled={!termsAccepted || !chances || playing}
                    onClick={spinTheWheel}
                    className={styles.submitButton}
                  >
                    {t('luckyDraw.buttons.draw')}
                  </Button>
                </div>
              </div>
            ) : (
              NoSpinsText
            )}
          </>
        )}
      </section>
    </div>
  )
}
