import { IconProps, iconColor } from './IconProps'

export function RefreshIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_15398_28407)'>
        <path
          d='M15.7642 7.3919C15.0193 6.04128 13.8293 4.9907 12.3967 4.41916C10.9641 3.84762 9.37773 3.79048 7.90776 4.25748C6.43779 4.72448 5.17522 5.68673 4.33518 6.98027C3.49515 8.2738 3.12961 9.8186 3.30086 11.3514C3.47212 12.8843 4.16956 14.3103 5.27436 15.3865C6.37916 16.4628 7.82295 17.1227 9.35974 17.2538C10.8965 17.3848 12.4312 16.979 13.7023 16.1054C14.9734 15.2318 15.9023 13.9445 16.3307 12.4628M15.7642 7.3919L16.1088 3.66253M15.7642 7.3919L12.2587 7.18868'
          stroke={iconColor(props)}
          strokeWidth='1.5'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_15398_28407'>
          <rect width={size} height={size} fill={iconColor(props)} />
        </clipPath>
      </defs>
    </svg>
  )
}
