import React, { MouseEventHandler, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import IconButton from '../../global/iconButton/IconButton'
import { ConfirmationModal } from '../../global/modal/ConfirmationModal'
import { Modal } from '../../global/modal/Modal'
import { Table } from '../../global/table/Table'
import { InfoIcon } from '../../icons/InfoIcon'
import { RefreshIcon } from '../../icons/RefreshIcon'
import { TrashIcon } from '../../icons/TrashIcon'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import {
  isDocumentCategoryAdditionalType,
  isDocumentCategoryAddressType,
  isDocumentCategoryAppropriatenessTestType,
  isDocumentCategoryPaymentsType,
  isDocumentCategoryPersonalType,
} from '../../model/DocumentCategories'
import {
  DocumentClientAreaDto,
  isDocumentCategoryAppropriatenessTestStatus,
  isDocumentClientAreaExpiredStatus,
} from '../../model/DocumentClientAreaDto'
import {
  isDocumentStatusApproved,
  isDocumentStatusExpired,
  isDocumentStatusPendingApproval,
  isDocumentStatusRejected,
} from '../../model/DocumentStatus'
import { Tooltip } from '../../ui/Popups/Tooltip/Tooltip'
import { isDocumentManagement2MonthExpired } from '../../utils/AccountAccess/accountDocumentStatuses'
import { useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { getCentralRowIdConditionally } from '../../utils/getItemId'
import { DocumentBadgeStatus } from './DocumentBadgeStatus'
import { UploadedDocumentStatusHistoryModal } from './UploadedDocumentStatusHistory'
import { isUploadLocked } from './UploadedDocuments'

import styles from './DocumentManagementPage.module.scss'

interface UploadedDocumentsProps {
  documents: DocumentClientAreaDto[]
  forceRefresh(): void
  readOnlyDocumentIds: string[]
}

export const UploadedDocumentsTable: React.FC<UploadedDocumentsProps> = (props) => {
  const { documents, forceRefresh } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const apiClient = useApiClient(ClientApiClient)

  const [documentStatusHistory, setDocumentStatusHistory] = useState<DocumentClientAreaDto>()
  const [isUploadedDocumentStatusHistoryModalOpen, setUploadedDocumentStatusHistoryModalOpen] =
    useState(false)
  const [isDocumentDeleteConfirmationModalOpen, setIsDocumentDeleteConfirmationModalOpen] =
    useState(false)
  const [selectedDocument, setSelectedDocument] = useState<DocumentClientAreaDto | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  const handleUploadedDocumentStatusHistoryModalOpen = (
    documentClientArea: DocumentClientAreaDto
  ) => {
    setDocumentStatusHistory(documentClientArea)
    setUploadedDocumentStatusHistoryModalOpen(true)
  }

  const handleUploadedDocumentStatusHistoryModalClose = () => {
    setDocumentStatusHistory(undefined)
    setUploadedDocumentStatusHistoryModalOpen(false)
  }

  const handleDeleteFileConfirmationModalClose = () => {
    setIsDocumentDeleteConfirmationModalOpen(false)
  }

  const handleDocumentRenew = (document: DocumentClientAreaDto) => {
    if (document) {
      if (isDocumentCategoryPersonalType(document.category.id)) {
        navigate(`/profile/document-management/identification`, {
          state: {
            document,
            documentCategory: document.category,
            documentCategoryType: document.type,
          },
        })
      }
      if (isDocumentCategoryAddressType(document.category.id)) {
        navigate(`/profile/document-management/proof-of-address`, {
          state: {
            document,
            documentCategory: document.category,
            documentCategoryType: document.type,
          },
        })
      }
      if (isDocumentCategoryAppropriatenessTestType(document.category.id)) {
        navigate(`/profile/document-management/company-proof`, {
          state: {
            document,
            documentCategory: document.category,
            documentCategoryType: document.type,
          },
        })
      }
      if (isDocumentCategoryAdditionalType(document.category.id)) {
        navigate(`/profile/document-management/additional`, {
          state: {
            document,
            documentCategory: document.category,
            documentCategoryType: document.type,
          },
        })
      }
      if (isDocumentCategoryPaymentsType(document.category.id)) {
        navigate(`/profile/document-management/payments`, {
          state: {
            document,
            documentCategory: document.category,
            documentCategoryType: document.type,
          },
        })
      }
    }
  }

  const handleDocumentDeleteConfirmation = (document: DocumentClientAreaDto) => {
    setSelectedDocument(document)
    setIsDocumentDeleteConfirmationModalOpen(true)
  }

  const handleDocumentDelete = async () => {
    if (selectedDocument) {
      try {
        setLoading(true)
        await apiClient.deleteDocument(selectedDocument.id)
        forceRefresh()
      } finally {
        setLoading(false)
        setIsDocumentDeleteConfirmationModalOpen(false)
        setSelectedDocument(null)
      }
    }
  }

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      {isUploadedDocumentStatusHistoryModalOpen && (
        <UploadedDocumentStatusHistoryModal
          documentClientArea={documentStatusHistory}
          onClose={handleUploadedDocumentStatusHistoryModalClose}
        />
      )}
      {isDocumentDeleteConfirmationModalOpen && (
        <Modal
          closeModal={handleDeleteFileConfirmationModalClose}
          render={({ closeModal }) => (
            <DocumentDeleteConfirmationModal
              onConfirm={handleDocumentDelete}
              onClose={closeModal}
            />
          )}
        />
      )}
      <div className={styles.table}>
        <Table>
          <thead>
            <tr>
              <th>{t('Profile.Document Category')}</th>
              <th>{t('Profile.Document Type')}</th>
              <th>{t('Profile.Document Name')}</th>
              <th>{t('Profile.Upload date')}</th>
              <th>{t('Profile.Expiry date')}</th>
              <th className='has-text-centered'>{t('Profile.Status')}</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {documents.map((document, index) => {
              return (
                <UploadedDocument
                  {...props}
                  document={document}
                  onUploadedDocumentStatusHistoryModalOpen={() =>
                    handleUploadedDocumentStatusHistoryModalOpen(document)
                  }
                  onDocumentRenew={() => handleDocumentRenew(document)}
                  onDocumentDeleteConfirmation={() => handleDocumentDeleteConfirmation(document)}
                  key={document.id}
                  {...getCentralRowIdConditionally(index, documents.length, 'uploaded-documents')}
                />
              )
            })}
          </tbody>
        </Table>
      </div>
    </Loading>
  )
}

interface UploadedDocumentProps extends UploadedDocumentsProps {
  document: DocumentClientAreaDto
  onUploadedDocumentStatusHistoryModalOpen(): void
  onDocumentRenew(): void
  onDocumentDeleteConfirmation(): void
  id?: string
}

const UploadedDocument: React.FC<UploadedDocumentProps> = (props) => {
  const {
    document,
    id,
    onUploadedDocumentStatusHistoryModalOpen,
    onDocumentRenew,
    onDocumentDeleteConfirmation,
  } = props
  const { type, status, category } = document

  const [auth] = useContext(AuthSessionContext)

  const dateFormat = auth?.dateFormatType?.name
  const expiryDate =
    document?.expiryDate && formatDate(document.expiryDate, { formatType: dateFormat })
  const uploadedDate =
    document?.uploadedDate && formatDate(document.uploadedDate, { formatType: dateFormat })

  return (
    <tr id={id}>
      <td>{category.name}</td>
      <td>{type.name}</td>
      <td>
        <span className={styles.documentName}>{document.name}</span>
      </td>
      <td>{uploadedDate}</td>
      <td>
        {expiryDate && (
          <p className='is-flex is-align-items-center'>
            {isDocumentClientAreaExpiredStatus(document) ? (
              <span className={classNames('ml-1', styles.error)}>{expiryDate}</span>
            ) : (
              expiryDate
            )}
            {!isDocumentClientAreaExpiredStatus(document) && <ExpiryWarning document={document} />}
          </p>
        )}
      </td>
      <td className='has-text-centered'>
        <IconButton onClick={onUploadedDocumentStatusHistoryModalOpen}>
          <DocumentBadgeStatus {...status} />
        </IconButton>
      </td>
      <td width={110} />
      <td>
        <div className={styles.buttonsWrapper}>
          <RefreshButton document={document} onClick={onDocumentRenew} />
          <DeleteButton {...props} document={document} onClick={onDocumentDeleteConfirmation} />
        </div>
      </td>
    </tr>
  )
}

interface DocumentButtonProps {
  document: DocumentClientAreaDto
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export const RefreshButton: React.FC<DocumentButtonProps> = (props) => {
  const { document, onClick } = props

  if (isDocumentRenewableAllowed(document)) {
    return (
      <IconButton onClick={onClick}>
        <span className={styles.iconButton}>
          <RefreshIcon color='contrastMedium' size={20} />
        </span>
      </IconButton>
    )
  }

  return null
}

export const DeleteButton: React.FC<DocumentButtonProps & { readOnlyDocumentIds: string[] }> = (
  props
) => {
  const { document, onClick, readOnlyDocumentIds = [] } = props

  if (isDocumentDeleteAllowed(readOnlyDocumentIds)(document)) {
    return (
      <IconButton onClick={onClick}>
        <span className={styles.iconButton}>
          <TrashIcon color='contrastMedium' size={20} />
        </span>
      </IconButton>
    )
  }

  return null
}

interface ExpiryWarningProps {
  document: DocumentClientAreaDto
}

export const ExpiryWarning: React.FC<ExpiryWarningProps> = ({ document }) => {
  const { expiryDate } = document
  const { t } = useTranslation()

  if (!expiryDate) {
    return null
  }

  if (
    isDocumentManagement2MonthExpired(expiryDate) &&
    !isDocumentClientAreaExpiredStatus(document)
  ) {
    return (
      <div className={classNames('is-flex', 'has-text-centered ml-1')}>
        <IconButton>
          <Tooltip autoHide={2000} content={t('Expires soon')} direction={'topRight'}>
            <InfoIcon size={12} />
          </Tooltip>
        </IconButton>
      </div>
    )
  }

  return null
}

interface DocumentDeleteConfirmationModalProps {
  onConfirm(): void
  onClose(): void
}

const DocumentDeleteConfirmationModal: React.FC<DocumentDeleteConfirmationModalProps> = (props) => {
  const { onConfirm, onClose } = props

  const { t } = useTranslation()

  return (
    <ConfirmationModal
      title={t('Profile.Uploaded Document')}
      renderFooter={() => (
        <React.Fragment>
          <button className='button' onClick={onClose} type='button'>
            {t('Cancel')}
          </button>
          <button className='button' onClick={onConfirm} type='button'>
            {t('Confirm')}
          </button>
        </React.Fragment>
      )}
      onConfirm={onConfirm}
      onCancel={onClose}
    >
      <p>{t('Profile.Do you really want to delete this document?')}</p>
    </ConfirmationModal>
  )
}

const isDocumentRenewableAllowed = (
  document: DocumentClientAreaDto,
  account?: AccountDetailedDto
): boolean => {
  if (
    isDocumentStatusExpired(document.status.id) &&
    !isDocumentCategoryAppropriatenessTestStatus(document) &&
    !isUploadLocked(document, account)
  ) {
    return true
  }

  if (
    isDocumentStatusPendingApproval(document.status.id) &&
    !isDocumentCategoryAppropriatenessTestStatus(document) &&
    !isUploadLocked(document, account)
  ) {
    return true
  }

  if (
    isDocumentStatusRejected(document.status.id) &&
    !isDocumentCategoryAppropriatenessTestStatus(document) &&
    !isUploadLocked(document, account)
  ) {
    return true
  }

  if (
    isDocumentStatusApproved(document.status.id) &&
    isDocumentManagement2MonthExpired(document.expiryDate) &&
    !isDocumentCategoryAppropriatenessTestStatus(document) &&
    !isUploadLocked(document, account)
  ) {
    return true
  }

  return false
}

const isDocumentDeleteAllowed =
  (readOnlyDocumentIds: string[]) => (document: DocumentClientAreaDto) => {
    if (readOnlyDocumentIds?.includes(document.id)) {
      return false
    }

    return isDocumentStatusPendingApproval(document.status.id)
  }
