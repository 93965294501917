import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { useDisableMainScroll } from '../../../hooks/useDisableMainScroll'
import { CloseIcon } from '../../../icons/CloseIcon'
import { HamburgerIcon } from '../../../icons/HamburgerIcon'
import { NotificationCircledIcon } from '../../../icons/NotificationCircledIcon'
import { TMEntityLogoFactory } from '../../../icons/TickmillLogoIcons'
import { UserCircledIcon } from '../../../icons/UserCircledIcon'
import { hasVerifiedPhoneNumbers } from '../../../model/AccountDetailedDto'
import { isTickmillProductTypeETD } from '../../../model/TickmillProductType'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { ProductSwitch } from '../../../ui/ProductSwitch/ProductSwitch'
import { isAccountDocumentAllowedUpload } from '../../../utils/AccountAccess/accountDocumentStatuses'
import { requireDepositBeforeKYC } from '../../../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useNotificationFunctionality, useNotificationUnread } from '../../../utils/notifications'
import { isSignUpPath, useBasePath } from '../../../utils/path'
import { CookieConsent } from '../../CookieConsent/CookieConsent'
import { useSessionEntity } from '../../context/EntityContext'
import { useProductReadContext } from '../../context/ProductContext'
import IconButton from '../../iconButton/IconButton'
import { LanguageVariants } from '../../localeSelector/LanguageVariants'
import { LocaleSelector } from '../../localeSelector/LocaleSelector'
import { NavAccountMenu } from '../NavAccountMenu'
import { MobileNavTabs } from './MobileNavTabs'
import { useDropdownHeight } from './useDropdownHeight'

import styles from './MobileNav.module.scss'

export const MobileNav: React.FC = () => {
  const { enableMainScroll, disableMainScroll } = useDisableMainScroll()
  const location = useLocation()
  const entity = useSessionEntity()
  const redirectionLink = useBasePath(true)
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()
  const navigate = useNavigate()
  const { enableNotifications } = useNotificationFunctionality()
  const { hasUnread } = useNotificationUnread()
  const { headerRef, dropdownRef } = useDropdownHeight()
  const { isDefaultCFDProductType } = useProductReadContext()

  const isProductTypeCFD = isDefaultCFDProductType()
  const isSignUp = isSignUpPath(location.pathname)

  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false)
  const [isAccountMenuOpen, setAccountMenuOpen] = useState(false)

  const color = isTickmillProductTypeETD(product) ? 'alwaysWhite' : undefined

  const closeMenus = () => {
    enableMainScroll()
    setBurgerMenuOpen(false)
    setAccountMenuOpen(false)
  }

  const handleScroll = (val: boolean) => (val ? enableMainScroll() : disableMainScroll())

  const toggleBurgerMenu = () => {
    handleScroll(isBurgerMenuOpen)
    setBurgerMenuOpen(!isBurgerMenuOpen)
  }

  const toggleAccountMenu = () => {
    handleScroll(isAccountMenuOpen)
    setAccountMenuOpen(!isAccountMenuOpen)
  }

  useEffect(() => {
    setBurgerMenuOpen(false)
    setAccountMenuOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const requiresAction = useMemo(
    () =>
      isAccountDocumentAllowedUpload(account) ||
      !hasVerifiedPhoneNumbers(account) ||
      requireDepositBeforeKYC(account),
    [account]
  )

  return (
    <div ref={headerRef} className={styles.navigationWrapper}>
      <CookieConsent />
      <nav className={styles.nav} role='navigation' aria-label='main navigation'>
        <div className={styles.container}>
          {isBurgerMenuOpen && account?.visibilityConfiguration.showProductSwitcher ? (
            <ProductSwitch onChange={toggleBurgerMenu} />
          ) : (
            <Link to={redirectionLink}>
              <TMEntityLogoFactory variant={entity} />
            </Link>
          )}
          <div className={styles.navbarItemWrapper}>
            {enableNotifications && (
              <IconButton onClick={() => navigate('/notifications')}>
                <AlertIconWrapper isActive={!!hasUnread}>
                  <NotificationCircledIcon color={color} size={24} />
                </AlertIconWrapper>
              </IconButton>
            )}
            <AlertIconWrapper isActive={requiresAction}>
              <IconButton onClick={toggleAccountMenu}>
                <UserCircledIcon color={color} size={24} />
              </IconButton>
            </AlertIconWrapper>
            <IconButton className={styles.burgerButton} onClick={toggleBurgerMenu}>
              {isBurgerMenuOpen ? (
                <CloseIcon color={color} size={16} />
              ) : (
                <HamburgerIcon
                  size={24}
                  color={isProductTypeCFD || isSignUp ? undefined : 'alwaysWhite'}
                />
              )}
            </IconButton>
          </div>
        </div>
        <div
          className={classNames(styles.menu, {
            [styles.active]: isBurgerMenuOpen,
          })}
        >
          <div ref={dropdownRef} className={styles.dropdown}>
            <MobileNavTabs />
            <hr className={styles.divider} />
            <LocaleSelector />
          </div>
        </div>
        <LanguageVariants />
        <NavAccountMenu isOpen={isAccountMenuOpen} close={closeMenus} />
      </nav>
    </div>
  )
}
