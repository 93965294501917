import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { SelectField } from '../../../global/field/SelectField'
import { TextField } from '../../../global/field/TextField'
import { Modal } from '../../../global/modal/Modal'
import { SubIBAccount } from '../../../model/MultiTierDto'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useWindowResize } from '../../../utils/domUtils'
import { useFetchOne } from '../../../utils/useFetch'

import styles from './MultiTierModals.module.scss'

interface AddEditSubIBModalProps {
  onCancel(): void
  onSubmit(subAccountId: number, commissionPlanGroupId: string): void
  data: SubIBAccount
}

export const AddEditSubIBModal: React.FC<AddEditSubIBModalProps> = (props) => {
  const { onCancel, onSubmit, data } = props
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const introducingBrokerId = account?.clientIntroducingBroker?.id || ''
  const [commissionPlan, setCommissionPlan] = useState<string>(data.commissionPlanGroup.id)
  const [error, setError] = useState<string>()

  const apiClient = useApiClient(ClientApiClient)
  const isMobile = useWindowResize()

  const callback = useCallback(
    async () => {
      const response = await apiClient.getIBSubAccountPlanOptions(introducingBrokerId)
      return response.filter((plan) => data.commissionPlanGroup.id === plan.id || !plan.isDefault)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [introducingBrokerId]
  )

  const { data: options, isLoading } = useFetchOne(callback)

  useEffect(() => {
    setError(undefined)

    if (!options) {
      return
    }

    const originalPlan = options.find((plan) => plan.id === data.commissionPlanGroup.id)
    const selectedPlan = options.find((plan) => plan.id === commissionPlan)

    if (!originalPlan || !selectedPlan) {
      return
    }

    const originalAmounts: { [key: number]: number } = {}
    originalPlan.commissionPlans.forEach((cp) => {
      originalAmounts[cp.tradingAccountType.id] = cp.amount
    })

    for (const cp of selectedPlan.commissionPlans) {
      if (cp.amount < originalAmounts[cp.tradingAccountType.id]) {
        setError(t('IB.MultiTier.IB Commission plan cannot be downgraded'))
        break
      }
    }
  }, [commissionPlan, options, data, t])

  return (
    <Modal
      closeModal={onCancel}
      render={() => (
        <React.Fragment>
          <header className='modal-card-head'>
            <p className='modal-card-title'>{t('IB.MultiTier.Edit Sub-IB Account')}</p>
          </header>
          <div className={styles.subIBModal}>
            <div className={styles.subIBModalRow}>
              {!isMobile && <span>{t('IB.MultiTier.Sub-IB Account')}:</span>}
              <TextField
                className={styles.inputField}
                readOnly
                label={isMobile ? t('IB.MultiTier.Sub-IB Account') : undefined}
                value={data.account.name}
                type='text'
              />
            </div>
            <div className={styles.subIBModalRow}>
              {!isMobile && <span>{t('IB.MultiTier.IB Commission Plan')}:</span>}
              <Loading isLoading={isLoading}>
                <SelectField
                  error={error}
                  options={options?.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  className={styles.inputField}
                  onChange={(v) => setCommissionPlan(v.target.value)}
                  value={commissionPlan}
                  label={t('IB.MultiTier.IB Commission Plan')}
                />
              </Loading>
            </div>
          </div>
          <footer className='modal-card-foot'>
            <button className='button' onClick={onCancel} type='button'>
              {t('Cancel')}
            </button>
            <button
              className='button'
              type='button'
              disabled={commissionPlan === data.commissionPlanGroup.id || !!error}
              onClick={() => onSubmit(data.account.id, commissionPlan)}
            >
              {t('Submit')}
            </button>
          </footer>
        </React.Fragment>
      )}
    />
  )
}
