import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { SubscriptionBundleDto } from '../../model/SubscriptionBundleDto'
import { SubscriptionCartItem } from '../../model/SubscriptionCart'
import { SubscriptionGroupDto } from '../../model/SubscriptionGroupDto'
import { SubscriptionDetail } from '../../model/SubscriptionTypes'
import {
  SubscriptionRequestType,
  UpdateSubscriptionRequest,
} from '../../model/UpdateSubscriptionRequest'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { TextTiny } from '../../ui/Typography/Typography'
import { SubscriptionBundle } from './SubscriptionBundle'
import { SubscriptionGroup } from './SubscriptionGroup'

import styles from './MarketDataPage.module.scss'

interface SubscriptionContentProps {
  subscriptionBundlesLoading: boolean
  subscriptionGroupsLoading: boolean
  subscriptionBundles: SubscriptionBundleDto[]
  subscriptionGroups: SubscriptionGroupDto[]
  subscribedBundles: SubscriptionBundleDto[]
  nonActiveSubscriptions: SubscriptionCartItem[]
  scrollToCart?: () => void
  onUpdateSubscription(data: UpdateSubscriptionRequest, selectedItem?: SubscriptionDetail): void
  onAddCartItem(
    subscriptionDetailId: string,
    subscriptionRequestTypeId: SubscriptionRequestType
  ): void
}

export const SubscriptionContent: React.FC<SubscriptionContentProps> = ({
  subscriptionBundlesLoading,
  subscriptionGroupsLoading,
  subscriptionBundles,
  subscriptionGroups = [],
  subscribedBundles,
  scrollToCart,
  onUpdateSubscription,
  onAddCartItem,
  nonActiveSubscriptions,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.products}>
        <Loading isModal showLoadingIcon isLoading={subscriptionBundlesLoading}>
          {subscriptionBundles?.length > 0 ? (
            subscriptionBundles.map((bundle) => (
              <SubscriptionBundle
                scrollToCart={scrollToCart}
                bundle={bundle}
                subscribedBundles={subscribedBundles}
                onUpdateSubscription={onUpdateSubscription}
                onAddCartItem={onAddCartItem}
                key={bundle.id}
                nonActiveSubscriptions={nonActiveSubscriptions}
              />
            ))
          ) : (
            <NoResults hideLink subtitle={t('Subscriptions.No products list')} />
          )}
        </Loading>
      </div>
      <h4 className={styles.standaloneProductsHeader}>{t('Market Data.Standalone Products')}</h4>

      <div className='is-hidden-mobile px-4'>
        <div className={classNames('mb-2', styles.header)}>
          <div className={styles.name}>
            <TextTiny className={styles.headerTextStyles}>{t('Market Data.Product')}</TextTiny>
          </div>
          <div className={styles.price}>
            <TextTiny className={styles.headerTextStyles}>{t('Market Data.Price')}</TextTiny>
          </div>
          <div className={styles.status}>
            <TextTiny className={styles.headerTextStyles}>{t('Market Data.Status')}</TextTiny>
          </div>
        </div>
      </div>
      <Loading showLoadingIcon isModal isLoading={subscriptionGroupsLoading}>
        <div className={styles.products}>
          {subscriptionGroups.length > 0 ? (
            subscriptionGroups.map((subscriptionGroup) => {
              return (
                <SubscriptionGroup
                  scrollToCart={scrollToCart}
                  subscribedBundles={subscribedBundles}
                  subscriptionGroup={subscriptionGroup}
                  onUpdateSubscription={onUpdateSubscription}
                  onAddCartItem={onAddCartItem}
                  nonActiveSubscriptions={nonActiveSubscriptions}
                  key={subscriptionGroup.id}
                />
              )
            })
          ) : (
            <NoResults hideLink subtitle={t('Subscriptions.No products list')} />
          )}
        </div>
      </Loading>
    </>
  )
}
