import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CopyButton } from '../global/button/CopyButton'
import { BlueInfoIcon } from '../icons/BlueInfoIcon'
import { CheckCircledIcon } from '../icons/CheckCircledIcon'
import { ExternalLinkIcon } from '../icons/ExternalLinkIcon'
import { MessengerIcon } from '../icons/MessengerIcon'
import { TelegramIcon } from '../icons/TelegramIcon'
import { WhatsAppIcon } from '../icons/WhatsAppIcon'
import { SocialMediaTypes } from '../model/WalletPaymentAgentProfileDto'
import { WalletPaymentAgentTransferInformationDto } from '../model/WalletPaymentAgentTransferInformationDto'
import { Note } from '../ui/Note/Note'
import { Text, TextH3, TextStrong } from '../ui/Typography/Typography'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { useWindowResize } from '../utils/domUtils'
import { extractDigitsFromString } from '../utils/formValidation'
import { useCallbackWithForceRefresh } from '../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../utils/useFetch'

import styles from './WalletAgentInformation.module.scss'

export const useAgentInformationFetch = (walletId: string, paymentAgentId: string) => {
  const apiClient = useApiClient(ClientApiClient)

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    () => apiClient.getPaymentAgentsTransferInformation(walletId, paymentAgentId),
    [paymentAgentId]
  )

  const { data: paymentAgent, isLoading } = useFetchOne(callback)

  return { paymentAgent, isLoading, forceRefresh }
}

export enum AgentInformationType {
  Deposit,
  Withdraw,
}

const isDeposit = (props: AgentInformationDetailsProps) => {
  return props?.type === AgentInformationType.Deposit
}

interface AgentInformationProps {
  type: AgentInformationType
  paymentAgent: WalletPaymentAgentTransferInformationDto
}

enum PaymentMethodType {
  Depomost = 'depomost',
}

const isDepomost = (props: AgentInformationDetailsProps) => {
  return props?.paymentAgent?.externalName?.toLowerCase() === PaymentMethodType.Depomost
}

export const AgentInformation: React.FC<AgentInformationProps> = (props) => {
  const { paymentAgent } = props
  const { t } = useTranslation()
  const isMobile = useWindowResize()

  return (
    paymentAgent && (
      <React.Fragment>
        <div className='pt-4'>
          <div className='pb-4'>
            <TextH3>{t('Payment Agent.Agent information')}</TextH3>
          </div>

          {isMobile && (
            <div className={styles.agentInformation}>
              <span className={styles.checkIcon}>
                <CheckCircledIcon />
              </span>
              <span>{t('Payment Agent.Verified Agent')}</span>
            </div>
          )}
          <div
            className={classNames(
              'columns is-vcentered is-gapless is-hidden-mobile',
              styles.agentInformation
            )}
          >
            <div className='column'>
              <div className='columns is-vcentered is-gapless'>
                <div className='pr-3'>
                  <span className={styles.checkIcon}>
                    <CheckCircledIcon />
                  </span>
                </div>
                <div className='column'>
                  <span>{t('Payment Agent.Verified Agent')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {paymentAgent.powerOfAttorney.accepted && (
          <AgentInformationDetails type={props.type} paymentAgent={paymentAgent} />
        )}
      </React.Fragment>
    )
  )
}

interface AgentInformationDetailsProps {
  type: AgentInformationProps['type']
  paymentAgent: WalletPaymentAgentTransferInformationDto
}

const SocialMediaIcons: Record<Partial<number>, ReactElement> = {
  [SocialMediaTypes.WhatsApp]: <WhatsAppIcon />,
  [SocialMediaTypes.Facebook]: <MessengerIcon />,
  [SocialMediaTypes.Telegram]: <TelegramIcon />,
}

const socialMediaIconsOrder = [
  SocialMediaTypes.WhatsApp,
  SocialMediaTypes.Telegram,
  SocialMediaTypes.Facebook,
]

export const AgentInformationDetails: React.FC<AgentInformationDetailsProps> = (props) => {
  const { paymentAgent } = props

  const { t } = useTranslation()

  return (
    <div className='pt-4'>
      <div className='field'>
        <label className={styles.textTiny}>{t('Payment Agent.Website')}</label>
        <div className='control'>
          <TextStrong>
            <div className='columns is-flex flex-direction'>
              <div className='column'>
                <a href={paymentAgent.website} target='_blank' rel='noreferrer'>
                  {paymentAgent.website}
                </a>
              </div>
              <div className='column is-flex-grow-0 has-text-right'>
                <a href={paymentAgent.website} target='_blank' rel='noreferrer'>
                  <ExternalLinkIcon size={16} />
                </a>
              </div>
            </div>
          </TextStrong>
        </div>
      </div>

      {isDeposit(props) && (
        <div className='mb-4'>
          <InfoNote {...props} />
        </div>
      )}

      <div className='field'>
        <label className={styles.textTiny}>{t('Payment Agent.Agent Balance')}</label>
        <div className='control'>
          <TextStrong>
            {paymentAgent.wallet.balance} {paymentAgent.wallet.currency.id}
          </TextStrong>
        </div>
        <label className={styles.textTiny}>
          {props.type === AgentInformationType.Deposit
            ? t('Payment Agent.Current max deposit amount')
            : t('Payment Agent.Current max withdrawal amount')}
        </label>
      </div>

      <div>
        <div className='block'>
          <TextH3>{t('Payment Agent.Who are Payment Agents?')}</TextH3>
        </div>
        <div className='block'>
          <Text isParagraph>{t('Payment Agent.Payment Agents are authorised persons who')}</Text>
        </div>
      </div>

      <div className='pt-5'>
        {paymentAgent.profiles.map((x, index) => {
          const digitNumber = extractDigitsFromString(x.phone ?? '')
          const phoneNumber = digitNumber ? '+' + digitNumber : ''
          const sortedSocialMediaLinks = x.socialMedia?.sort((a, b) => {
            return (
              socialMediaIconsOrder.indexOf(a.socialMediaType.id) -
              socialMediaIconsOrder.indexOf(b.socialMediaType.id)
            )
          })

          return (
            <div className='pb-2 block'>
              <div className='block'>
                <div className='block'>
                  <TextH3>
                    {t('Payment Agent.Responsible person')} #{index + 1}
                  </TextH3>
                </div>
                <div className='field'>
                  <label className={styles.textTiny}>{t('Payment Agent.Full Name')}</label>
                  <div className='control'>
                    <TextStrong>
                      {x.firstName} {x.lastName}
                    </TextStrong>
                  </div>
                </div>
              </div>

              <div className='block'>
                <div className='field'>
                  <label className={styles.textTiny}>{t('Payment Agent.Email')}</label>
                  <div className='control'>
                    <div className='columns is-flex flex-direction'>
                      <div className='column is-flex is-align-items-center'>
                        <a href={`mailto:${x.email}`} className='is-full'>
                          {x.email}
                        </a>
                      </div>
                      <div className='column is-flex-grow-0 has-text-right'>
                        <CopyButton value={x.email || ''} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='block'>
                <div className='field'>
                  <label className={styles.textTiny}>{t('Payment Agent.Phone')}</label>
                  <div className='control'>
                    <div className='columns is-flex flex-direction'>
                      <div className={classNames('column', styles.contacts)}>
                        <span>{phoneNumber}</span>

                        {sortedSocialMediaLinks?.map((link) => (
                          <a href={link.value ?? '#'} target='_blank' key={link.id}>
                            {SocialMediaIcons[link.socialMediaType.id]}
                          </a>
                        ))}
                      </div>
                      <div className='column is-flex-grow-0 has-text-right'>
                        <CopyButton value={phoneNumber} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const InfoNote: React.FC<AgentInformationDetailsProps> = (props) => {
  const { t } = useTranslation()

  const text = isDepomost(props)
    ? t('Payment Agent.Please continue to the Payment Agent website')
    : t('Payment Agent.Please contact the Payment Agent directly')

  return <Note text={text} renderLeft={<BlueInfoIcon size={24} />} />
}
