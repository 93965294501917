import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Loading } from '../../global/Loading/Loading'
import { useProductReadContext } from '../../global/context/ProductContext'
import { Modal } from '../../global/modal/Modal'
import { NegativeBalanceModal } from '../../global/modal/NegativeBalanceModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useHasUserAnyWallets } from '../../hooks/useHasUserAnyWallets'
import { useTradingAccountStatus } from '../../hooks/useTradingAccountStatus'
import { useTwoFactorAuthWithdrawal } from '../../hooks/useTwoFactorAuthWithdrawal'
import { ConversionIcon } from '../../icons/ConversionIcon'
import { DepositIcon } from '../../icons/DepositIcon'
import { TransferIcon } from '../../icons/TransferIcon'
import { WithdrawIcon } from '../../icons/WithdrawIcon'
import { TotalWalletBalanceDto } from '../../model/TotalWalletBalanceDto'
import { WalletTypeEnum } from '../../model/WalletDto'
import { FirstTimeGuideCarousel } from '../../ui/FirstTimeGuideCarousel/FirstTimeGuideCarousel'
import { Grid } from '../../ui/Layouts/Grid/Grid'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useAccountActionAccessCheck } from '../../utils/AccountAccess/accountAccessCheck'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { useShowFirstTimeGuide } from '../../utils/FirstTimeGuideContext'
import { SharedContext } from '../../utils/SharedContext'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import {
  WalletRestrictions,
  isDepositEnabledFromAction,
  isRestricted,
} from '../../utils/wallet.utils'
import { Card, TransactionCardType } from './Card'

import styles from './TransactionsPage.module.scss'

interface TransactionCardsProps {
  areWalletsLoading: boolean
  hasCanDepositWallet: boolean
  hasUnrestrictedWallet: boolean
  onTwoFAWithdrawal: () => Promise<void>
}

const useTransactionsFetch = () => {
  const { account } = useAccountReadContext()
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => {
    if (account?.displayCurrency?.id) {
      return await apiClient.getTotalBalanceByCurrency(account.displayCurrency.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { data, isLoading } = useFetchOne(callback)

  return { data, isLoading }
}

const useTransactionsData = (data?: TotalWalletBalanceDto) => {
  const { t } = useTranslation()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { formatMoney } = useFormatNumber()

  const balance = data?.currency?.id ? `≈${formatMoney(data.balance, data.currency.id)}` : ''

  const description = !isDefaultCFDProductType()
    ? t('Transactions.Wallets balances', {
        balance,
      })
    : undefined

  return {
    balance,
    description,
  }
}

export const TransactionsPage: React.FC = () => {
  useScrollToTop()
  const { t } = useTranslation()
  const { onTwoFAWithdrawal } = useTwoFactorAuthWithdrawal()
  const { isDefaultCFDProductType } = useProductReadContext()
  const apiClient = useApiClient(ClientApiClient)
  const showFirstTimeGuid = useShowFirstTimeGuide()
  const walletType = isDefaultCFDProductType() ? WalletTypeEnum.CFD : WalletTypeEnum.ETD

  const walletsCallback = useCallback(async () => {
    return await apiClient.getWallets({ walletType })
  }, [])
  const { data: wallets = [], isLoading: areWalletsLoading } = useFetchOne(walletsCallback)

  const hasCanDepositWallet = wallets.some((wallet) => {
    return isDepositEnabledFromAction(wallet.depositAction)
  })
  const hasUnrestrictedWallet = wallets.some(
    (wallet) => !isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)
  )

  const transactionCardProps = {
    onTwoFAWithdrawal,
    areWalletsLoading,
    hasCanDepositWallet,
    hasUnrestrictedWallet,
  }

  return (
    <div>
      {/*{twoFactorWarningModalState.visible && (*/}
      {/*  <TwoFactorAuthWithdrawalWarningModal closeModal={onDismissModal} />*/}
      {/*)}*/}
      {showFirstTimeGuid && <FirstTimeGuideCarousel />}
      <PageHeader title={t('Transactions.Transactions')} />
      {isDefaultCFDProductType() && <TransactionsCardsCFD {...transactionCardProps} />}
      {!isDefaultCFDProductType() && <TransactionsCardsETD {...transactionCardProps} />}
    </div>
  )
}

const TransactionsCardsCFD = ({
  areWalletsLoading,
  hasCanDepositWallet,
  hasUnrestrictedWallet,
  onTwoFAWithdrawal,
}: TransactionCardsProps) => {
  const { t } = useTranslation()
  const { hasNegativeBalance, hasActiveTradingAccounts } = useTradingAccountStatus()

  const { data, isLoading } = useTransactionsFetch()
  const { description } = useTransactionsData(data)
  const isMobile = useWindowResize()
  const navigate = useNavigate()
  const { checkAccountTradersRoomAccess, checkAccountOpenAccountAccess } =
    useAccountActionAccessCheck()

  const [negativeBalanceModal, setNegativeBalanceModal] = useState(false)
  const [sharedState] = useContext(SharedContext)
  const { hasUserAnyWallets } = useHasUserAnyWallets()

  const allWalletsDisabled = useMemo(() => {
    return sharedState.wallets.every((wallet) => !wallet.depositAction.canDeposit)
  }, [sharedState.wallets])

  const handleWithdrawalClick = () => {
    checkAccountTradersRoomAccess(() => {
      if (hasNegativeBalance) {
        setNegativeBalanceModal(true)
      } else {
        onTwoFAWithdrawal()
      }
    })
  }

  const handleTransferClick = () => {
    if (!hasActiveTradingAccounts && hasUserAnyWallets) {
      return navigate(`/dashboard/traders-room/trading-accounts/new`)
    }
    checkAccountOpenAccountAccess(() => {
      navigate('/dashboard/traders-room/wallets/transfer')
    })
  }

  return (
    <Loading showLoadingIcon isLoading={isLoading || areWalletsLoading}>
      {negativeBalanceModal && isMobile && (
        <Modal
          closeModal={() => setNegativeBalanceModal(false)}
          render={({ closeModal }) => <NegativeBalanceModal onCancel={closeModal} />}
        />
      )}
      <Grid maxCardWidth={300} mobileSameSize>
        <Card
          title={t('Transactions.Deposit')}
          renderDescription={() => (
            <p className={styles.descriptionCFD}>{t('Transactions.Move Funds')}</p>
          )}
          buttonText={t('Transactions.Start Deposit')}
          appearance='primary'
          lockAction={allWalletsDisabled}
          to='/dashboard/traders-room/wallets/deposit'
          additionalDescription={description}
          icon={<DepositIcon size={80} />}
          isDisabled={!hasCanDepositWallet || !hasUnrestrictedWallet}
          cardType={TransactionCardType.depositCFD}
        />
        <Card
          title={t('Transactions.Withdraw')}
          renderDescription={() => (
            <p className={styles.descriptionCFD}>{t('Transactions.Move funds wallet bank')}</p>
          )}
          buttonText={t('Transactions.Start Withdrawal')}
          to='/dashboard/traders-room/wallets/withdrawal'
          onClick={handleWithdrawalClick}
          additionalDescription={description}
          icon={<WithdrawIcon size={80} />}
          isDisabled={hasNegativeBalance}
          cardType={TransactionCardType.withdrawalCFD}
        />
        <Card
          title={t('Transactions.Transfer Funds')}
          renderDescription={() => (
            <p className={styles.descriptionCFD}>{t('Transactions.Move funds between')}</p>
          )}
          buttonText={t('Transactions.Start Transfer')}
          to='/dashboard/traders-room/wallets/transfer'
          onClick={handleTransferClick}
          icon={<TransferIcon size={80} />}
          cardType={TransactionCardType.transferCFD}
        />
      </Grid>
    </Loading>
  )
}

const TransactionsCardsETD = ({
  areWalletsLoading,
  hasCanDepositWallet,
  hasUnrestrictedWallet,
  onTwoFAWithdrawal,
}: TransactionCardsProps) => {
  const { t } = useTranslation()

  const { data, isLoading } = useTransactionsFetch()
  const { description } = useTransactionsData(data)

  return (
    <Loading showLoadingIcon isLoading={isLoading || areWalletsLoading}>
      <Grid mobileSameSize>
        <Card
          bottomSpaced
          title={t('Transactions.Deposit')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Move Funds')}</p>
          )}
          buttonText={t('Transactions.Start Deposit')}
          appearance='primary'
          to='/dashboard/traders-room/wallets/deposit'
          additionalDescription={description}
          icon={<DepositIcon size={80} />}
          isDisabled={!hasCanDepositWallet || !hasUnrestrictedWallet}
          cardType={TransactionCardType.depositETD}
        />
        <Card
          bottomSpaced
          title={t('Transactions.Withdraw')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Move funds wallet bank')}</p>
          )}
          buttonText={t('Transactions.Start Withdrawal')}
          onClick={onTwoFAWithdrawal}
          to='/dashboard/traders-room/wallets/withdrawal'
          additionalDescription={description}
          icon={<WithdrawIcon size={80} />}
          cardType={TransactionCardType.depositETD}
        />
        <Card
          bottomSpaced
          title={t('Transactions.Transfer Funds')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Move funds between')}</p>
          )}
          buttonText={t('Transactions.Start Transfer')}
          to='/dashboard/traders-room/wallets/transfer'
          icon={<TransferIcon size={80} />}
          cardType={TransactionCardType.transferETD}
        />
        <Card
          bottomSpaced
          title={t('Transactions.Conversion')}
          renderDescription={() => (
            <p className={styles.descriptionETD}>{t('Transactions.Convert currencies')}</p>
          )}
          buttonText={t('Transactions.Start Conversion')}
          to='/dashboard/traders-room/transactions/conversion'
          icon={<ConversionIcon size={80} />}
          cardType={TransactionCardType.conversionETD}
        />
      </Grid>
    </Loading>
  )
}
