import React from 'react'

import { TMEntityLogoFactory } from '../../../icons/TickmillLogoIcons'
import { CookieConsent } from '../../CookieConsent/CookieConsent'
import { useSessionEntity } from '../../context/EntityContext'
import { LanguageVariants } from '../../localeSelector/LanguageVariants'
import { LocaleSelector } from '../../localeSelector/LocaleSelector'
import { useDropdownHeight } from './useDropdownHeight'

import styles from './MobileNav.module.scss'

export const MobileLoginNav: React.FC = () => {
  const entity = useSessionEntity()
  const { headerRef } = useDropdownHeight()

  return (
    <div ref={headerRef} className={styles.navigationWrapper}>
      <CookieConsent />
      <nav className={styles.nav} role='navigation' aria-label='main navigation'>
        <div className={styles.container}>
          <TMEntityLogoFactory variant={entity} />
          <LocaleSelector />
          <LanguageVariants />
        </div>
      </nav>
    </div>
  )
}
