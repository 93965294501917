import React, { PropsWithChildren } from 'react'

import { useWindowResize } from '../../utils/domUtils'
import { TextTiny } from '../Typography/Typography'

import styles from './Note.module.scss'

interface NoteProps {
  text: string
  renderRight?: React.ReactNode
  renderLeft?: React.ReactNode
}

export const Note: React.FC<NoteProps> = (props) => {
  const isMobile = useWindowResize()
  return isMobile ? <MobileNote {...props} /> : <DesktopNote {...props} />
}

const DesktopNote: React.FC<NoteProps> = (props) => {
  const { text, renderRight } = props

  return (
    <div className={styles.note}>
      <NoteContent {...props}>
        <TextTiny isParagraph>{text}</TextTiny>
        <div className={styles.right}>{renderRight}</div>
      </NoteContent>
    </div>
  )
}

const MobileNote: React.FC<NoteProps> = (props) => {
  const { text, renderRight } = props

  return (
    <div className={styles.note}>
      <NoteContent {...props}>
        <div className={styles.text}>
          <TextTiny isParagraph>{text}</TextTiny>
        </div>
      </NoteContent>
      <div className={styles.right}>{renderRight}</div>
    </div>
  )
}

const NoteContent: React.FC<PropsWithChildren<NoteProps>> = (props) => {
  const { children, renderLeft } = props

  return (
    <div className={styles.content}>
      <div className={styles.left}>{renderLeft}</div>
      {children}
    </div>
  )
}
