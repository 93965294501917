import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { TwoFactorAuthEvents, TwoFactorErrors } from '../TwoFactorAuth/useTwoFactorAuth'
import { useEntitySettings } from '../global/context/EntityContext'
import { useNotificationsContext } from '../global/context/NotificationsContext'
import { usePublicDomainsWriteContext } from '../global/context/PublicDomainsContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { ToastContext, errorToast } from '../global/toast/Toast'
import { ResponseError, useApiClient } from '../utils/ApiClient'
import { AuthSessionContext } from '../utils/AuthContext'
import { ClientApiClient } from '../utils/clientApi'
import {
  entityPerTickmillCompany,
  getTickmillCompanyByHostname,
  getTickmillSupportEmail,
} from '../utils/companyName.utils'
import { dispatchEntitySelected } from '../utils/cookie.utils'
import { loginToAuth } from '../utils/loginToAuth'
import { TokenStorage } from '../utils/tokenStorage'
import { useScrollToTop } from '../utils/useScrollToTop'
import { useLocallyPersistedState } from '../utils/useStorage'
import { LoginForm, LoginFormValues } from './LoginForm'

import styles from './LoginPage.module.scss'

export interface LoginPageProps {
  skipLogout?: boolean
}

export const LoginPage: React.FunctionComponent<LoginPageProps> & LoginPageProps = (props) => {
  useScrollToTop()

  const navigate = useNavigate()
  const apiClient = useApiClient(ClientApiClient)
  const { refreshDomains } = usePublicDomainsWriteContext()
  const locale = useSessionLanguage()
  const setToast = useContext(ToastContext)
  const [, setAuth] = useContext(AuthSessionContext) // eslint-disable-line @typescript-eslint/no-unused-vars
  const [, setNotificationSettings] = useNotificationsContext()
  const [, setLuckyDrawViewed] = useLocallyPersistedState('luckyDrawViewed', false)
  const { t } = useTranslation()
  const [entity, setEntity] = useEntitySettings()
  const actualEntity = entity.entity
  useEffect(() => {
    // setEntity to default when visit Login Page.
    if (!props.skipLogout) {
      setEntity({ entity: getTickmillCompanyByHostname() })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onLogin = async (values: LoginFormValues) => {
    try {
      if (props.skipLogout) {
        return undefined
      }

      const loginResponse = await apiClient.login(values)
      const hasTwoFactor = (loginResponse?.twoFactor?.length ?? 0) > 0

      if (hasTwoFactor) {
        localStorage.setItem('2fa.email', loginResponse.email)
        navigate('/login/2fa', {
          state: {
            twoFactorAuthData: {
              ...loginResponse,
              password: values.password,
              twoFactorAuthEvent: TwoFactorAuthEvents.LogIn,
            },
          },
        })
        return
      } else {
        setLuckyDrawViewed(false)
        setNotificationSettings({
          hideGeneralNotifications: false,
          hidePromoNotifications: false,
          hidePlatformNotifications: false,
        })

        const auth = loginToAuth(loginResponse)
        if (!auth) {
          return
        }
        setAuth(auth)
        refreshDomains()
        if (
          auth.tickmillCompany &&
          !window.env.APP_ENTITY.includes(entityPerTickmillCompany[auth.tickmillCompany.id]) &&
          process.env.NODE_ENV !== 'development'
        ) {
          navigate('/wrong-entity')
          return
        } else {
          localStorage.setItem('auth', JSON.stringify(auth))
          if (auth.accessToken) {
            TokenStorage.storeToken('access', auth.accessToken)
          }
          if (auth.refreshToken) {
            TokenStorage.storeToken('refresh', auth.refreshToken)
          }
          if (auth.sessionId) {
            TokenStorage.storeSession(auth.sessionId)
          }
          if (auth.tickmillCompany) {
            setEntity({ entity: auth.tickmillCompany.id })
          }
          if (auth.email && auth.firstName) {
            dispatchEntitySelected(
              auth?.tickmillCompany.id || getTickmillCompanyByHostname(),
              locale,
              auth.email,
              auth.firstName
            )
          }
        }
      }
    } catch (error: unknown) {
      if (error instanceof ResponseError) {
        const { response } = error.response
        if (response?.data.code === 'invalid_user_status_vulnerable_client') {
          setToast(
            errorToast({
              type: 'danger',
              dangerouslySetInnerHTML: {
                __html: t(
                  'Sign up.From the information you have provided, we do not think this product is appropriate for you at this time. If you would like to discuss further, please contact {{email}}.',
                  {
                    email: getTickmillSupportEmail(getTickmillCompanyByHostname(true)),
                  }
                ),
              },
            })
          )
        } else if (
          response?.data.code === TwoFactorErrors.TooManyAttempts ||
          response?.data.code === TwoFactorErrors.TooManySends
        ) {
          navigate('/login/2fa/expired', {
            state: {
              twoFactorAuthData: {
                twoFactorAuthEvent: TwoFactorAuthEvents.LogIn,
                email: values.email,
              },
            },
          })
          const errorTimer = error.response.response?.data.properties?.cooldownInMinutes || '30'
          setToast(
            errorToast({
              type: 'danger',
              dangerouslySetInnerHTML: {
                __html: t('errors.Too many resend attempts', {
                  minutes: errorTimer,
                }),
              },
            })
          )
        } else if (response?.data.code === 'invalid_credentials') {
          setToast(
            errorToast({
              type: 'danger',
              title: t('errors.Invalid credentials'),
            })
          )
        } else {
          return
        }
      }
      console.error(error)
      throw error
    }
  }

  return (
    <div className={styles.topWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <h2>{t('Log in')}</h2>
          <LoginForm entity={actualEntity} onSubmit={onLogin} />
        </div>
      </div>
    </div>
  )
}
