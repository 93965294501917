import cloneDeep from 'lodash.clonedeep'

import { parsedNotificationKey } from '../model/Notification'
import { NestedSearchQuery, PageQuery, SearchQuery, SortOrder } from './ApiClient'
import { isOne } from './validations'

export const sortingQueryBuilder = (sort?: string | null, sortOrder?: SortOrder): string | null => {
  if (!sort || !sortOrder) {
    return null
  }
  return `${sort} ${sortOrder}`
}

export const buildSearchQuery = (
  searchQuery?: SearchQuery | null,
  operator: 'OR' | 'AND' = 'AND',
  isNested = false
): string | null | undefined => {
  if (!searchQuery) {
    return null
  }

  const paramsFiltered = Object.entries(searchQuery).filter(([key, value]) => !!key && !!value)
  const params: (string | undefined)[] = paramsFiltered.map(([key, value]) => {
    if (key === 'nested') {
      return (value as NestedSearchQuery[])
        .map((nestedQuery) => buildSearchQuery(nestedQuery.queries, nestedQuery.operator, true))
        .join(` ${operator} `)
    }
    if (typeof value === 'string' || typeof value === 'number') {
      return `(${parsedNotificationKey(key)} == ${value})`
    }
    if (value && 'operator' in value) {
      return `(${parsedNotificationKey(key)} ${value?.operator} ${value?.value})`
    }
    return undefined
  })

  for (const key in params) {
    if (params.length - 1 > parseInt(key, 10)) {
      params[key] = `${params[key]} ${operator}`
    }
  }

  if (isNested) {
    return `(${params.join(' ')})`
  }
  const isPostNested = !!params[0] && !params[0][0].includes('OR') && !params[0][0].includes('AND')
  if (isOne(params.length) && isPostNested) {
    return params.join(' ').replace('(', '').replace(')', '')
  }
  return params.join(' ')
}

const handlePrefixedSearch = (query: PageQuery): PageQuery => {
  if (!query.search) {
    return query
  }
  const searchKeys = Object.keys(query.search)
  searchKeys.forEach((key: string) => {
    if (key.startsWith('ca_search_') && query.search && typeof query.search !== 'string') {
      query[key] = query.search[key]
      delete query.search[key]
    }
    if (key.startsWith('query_search_') && query.search && typeof query.search !== 'string') {
      query[key.replace('query_search_', '')] = query.search[key]
      delete query.search[key]
    }
  })
  return query
}

export const preparePageQueryParams = (query?: PageQuery): URLSearchParams => {
  const cloneQuery = cloneDeep(query)
  const newQuery = handlePrefixedSearch(cloneQuery || {})
  const searchParams: PageQuery = {
    ...newQuery,
    search:
      typeof newQuery.search === 'string'
        ? newQuery.search
        : buildSearchQuery(newQuery?.search as SearchQuery | null, newQuery?.operator),
    sort: sortingQueryBuilder(newQuery?.sort, newQuery?.sortOrder) || null,
    pageSize: newQuery?.pageSize ?? 10,
    pageIndex: newQuery?.pageIndex ?? 1,
  }

  delete searchParams.sortOrder
  delete searchParams.operator

  const params = Object.entries(searchParams)
    .filter(([key, value]) => !!key && !!value)
    .reduce<[string, any][]>((acc, [key, value]) => {
      if (key.startsWith('ca_search') && !key.includes('Date') && typeof value === 'string') {
        const values = value.split(/[\s,]+/)
        // Ensure each item is a tuple of [string, any]
        const newProps = values.map((val: string): [string, any] => [key, val])
        return [...acc, ...newProps]
      } else {
        return [...acc, [key, value]]
      }
    }, [])

  return new URLSearchParams(params as any)
}
