import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export function TrashIcon(props: IconProps): JSX.Element {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.99967 5.83325L5.83301 16.6666H14.1663L14.9997 5.83325M4.99967 5.83325H3.33301M4.99967 5.83325H14.9997M14.9997 5.83325H16.6663M7.49967 3.33325H12.4997M8.33301 8.33325V14.1666M11.6663 8.33325V14.1666'
        stroke={props.color ? styles[props.color] : iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
