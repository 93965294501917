import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Modal } from '../global/modal/Modal'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { PasswordChangedConfirmation } from './PasswordResetConfirmation'
import { ResetPasswordFormComplete } from './ResetPasswordFormComplete'

import styles from './ForgotPasswordPage.module.scss'
import { useSnackbar } from '../global/context/SnackbarContext'

export interface ResetPasswordCompleteFormValues {
  email: string
  confirmPassword: string
  password: string
  token: string
}

export interface ResetPasswordFormValues {
  confirmPassword: string
  password: string
}

export const ResetPasswordPage: React.FC = () => {
  const { email, token } = useParams<{ email: string; token: string }>()
  const navigate = useNavigate()
	const { addSnackbar } = useSnackbar()
  const [modalVisible, setModalVisible] = useState(false)
  const apiClient = useApiClient(ClientApiClient)
  const { t } = useTranslation()
  if (!email || !token) {
    navigate('/login')
    return null
  }

  const onSubmit = async (values: ResetPasswordFormValues) => {
    if (email && token) {
      const decodedEmail = decodeURIComponent(email)
      await apiClient.resetPasswordComplete({
        password: values.password,
        email: decodedEmail,
        token,
      })
			addSnackbar.success({ message: t('Password has been reset successfully!') })
			navigate('/login')
    }
  }
  return (
    <React.Fragment>
      {modalVisible && (
        <Modal
          closeModal={() => setModalVisible(false)}
          render={({ closeModal }) => (
            <PasswordChangedConfirmation
              onCancel={closeModal}
              onConfirm={() => {
                setModalVisible(false)
                navigate('/login')
              }}
            />
          )}
        />
      )}
      <div className={styles.topWrapper}>
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <h2 className={styles.passwordChangeTitle}>{t('Profile.Password Change')}</h2>
            <ResetPasswordFormComplete email={email} token={token} onSubmit={onSubmit} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
