import { endOfMonth, formatDuration, getDay, getDaysInMonth, intervalToDuration } from 'date-fns'
import { OptionsWithTZ, format as formatTZ, utcToZonedTime } from 'date-fns-tz'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import { TFunction } from 'i18next'

import { SortOrder } from './ApiClient'
import { isZero } from './validations'

interface FormatDatePayload extends OptionsWithTZ {
  formatType?: string
  withTime?: boolean
  forceUTC?: boolean
  noTimezone?: boolean
}

type DateFormatType = Partial<FormatDatePayload>

export const OneDayInMS = 24 * 60 * 60 * 1000

export const dateComparator = (
  a: string | null | undefined,
  b: string | null | undefined,
  revert?: boolean
): 1 | -1 | 0 => {
  if (!a) {
    return -1
  }

  if (!b) {
    return 1
  }

  const dateA = parseISO(a)
  const dateB = parseISO(b)

  if (revert) {
    if (isBefore(dateA, dateB)) {
      return 1
    }
    if (isAfter(dateA, dateB)) {
      return -1
    }
  } else {
    if (isBefore(dateA, dateB)) {
      return -1
    }
    if (isAfter(dateA, dateB)) {
      return 1
    }
  }

  return 0
}

export const compareDates = (a: string, b: string, nextSort: SortOrder): 1 | -1 => {
  if (nextSort === 'ASC') {
    return isAfter(new Date(a), new Date(b)) ? 1 : -1
  }
  return isAfter(new Date(a), new Date(b)) ? -1 : 1
}

export function formatDate(date: string, options: DateFormatType): string {
  const {
    formatType = 'dd.MM.yyyy HH:mm',
    withTime = true,
    forceUTC = false,
    noTimezone = false,
  } = options

  try {
    if (date.length <= 10) {
      return date
    }

    const newFormatType = withTime ? formatType : removeTimeFromFormat(formatType)

    if (noTimezone) {
      return formatDateUTC(date, newFormatType as SupportedDateFormatType)
    }

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const dateByTimeZone =
      forceUTC && !date.endsWith('Z')
        ? utcToZonedTime(`${date}Z`, userTimezone)
        : utcToZonedTime(date, userTimezone)

    return formatTZ(dateByTimeZone, newFormatType)
  } catch (e: unknown) {
    return date
  }
}

const removeTimeFromFormat = (dateFormat: string): string =>
  dateFormat.replace('HH:mm:ss', '').replace('HH:mm', '').trim()

export function dateAsUTC(date: Date | string, skipDateTime?: boolean): Date {
  const dateFormat = date instanceof Date ? date : new Date(date)
  if (skipDateTime) {
    return new Date(Date.UTC(dateFormat.getFullYear(), dateFormat.getMonth(), dateFormat.getDate()))
  }
  return new Date(
    Date.UTC(
      dateFormat.getFullYear(),
      dateFormat.getMonth(),
      dateFormat.getDate(),
      dateFormat.getHours(),
      dateFormat.getMinutes(),
      dateFormat.getSeconds()
    )
  )
}

export const getExpirationDaysInterval = (expirationDate: string | undefined | null): number => {
  if (!expirationDate) {
    return 0
  }

  const firstDate = new Date(expirationDate)
  const secondDate = new Date()

  return Math.floor((firstDate.getTime() - secondDate.getTime()) / OneDayInMS)
}

export interface SelectOption {
  label: string
  value: string
  id: number
  disabled?: boolean
}

export function getMonthOptions(t: TFunction): SelectOption[] {
  return [
    {
      label: t('January'),
      value: t('January'),
      id: 1,
    },
    {
      label: t('February'),
      value: t('February'),
      id: 2,
    },
    {
      label: t('March'),
      value: t('March'),
      id: 3,
    },
    {
      label: t('April'),
      value: t('April'),
      id: 4,
    },
    {
      label: t('May'),
      value: t('May'),
      id: 5,
    },
    {
      label: t('June'),
      value: t('June'),
      id: 6,
    },
    {
      label: t('July'),
      value: t('July'),
      id: 7,
    },
    {
      label: t('August'),
      value: t('August'),
      id: 8,
    },
    {
      label: t('September'),
      value: t('September'),
      id: 9,
    },
    {
      label: t('October'),
      value: t('October'),
      id: 10,
    },
    {
      label: t('November'),
      value: t('November'),
      id: 11,
    },
    {
      label: t('December'),
      value: t('December'),
      id: 12,
    },
  ]
}

export function getDayOptions(month?: number): SelectOption[] {
  if (!month) {
    return []
  }
  const daysInMonth = getDaysInMonth(month)
  const days = []
  for (let i = 1; i <= daysInMonth; i++) {
    days.push({
      label: i.toString(),
      value: i.toString(),
      id: i,
    })
  }
  return days
}

export const getAgeOfDate = (date: Date | string): string => {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  const now = new Date()
  const diff = now.getTime() - date.getTime()
  const diffInHours = diff / 1000 / 60 / 60
  const diffInDays = diffInHours / 24
  const diffInMonths = diffInDays / 30
  const diffInYears = diffInMonths / 12

  if (diffInHours < 1) {
    if (isZero(Math.floor(diffInHours * 60))) {
      return '1min'
    }
    return `${Math.floor(diffInHours * 60)}min`
  } else if (diffInHours < 24) {
    return `${Math.floor(diffInHours)}h`
  } else if (diffInDays < 30) {
    return `${Math.floor(diffInDays)}d`
  } else if (diffInMonths < 12) {
    return `${Math.floor(diffInMonths)}m`
  } else {
    return `${Math.floor(diffInYears)}y`
  }
}

export const getDateFromMonth = (month: number): Date => {
  const date = new Date()
  date.setDate(1)
  date.setMonth(month - 1)
  if (date.getMonth() > new Date().getMonth()) {
    date.setFullYear(date.getFullYear() - 1)
  }
  return date
}

export const getDateFromDay = (month: number, day: number): Date => {
  const date = getDateFromMonth(month)
  date.setDate(day)
  return date
}

export const getDateRangeOfLastYear = (): {
  dateRangeStart: Date
  dateRangeEnd: Date
} => {
  const dateRangeStart = new Date()
  const dateRangeEnd = new Date()
  dateRangeStart.setFullYear(dateRangeStart.getFullYear() - 1)
  dateRangeStart.setMonth(dateRangeStart.getMonth() + 1)
  dateRangeStart.setDate(1)
  dateRangeEnd.setDate(new Date().getDate() - 1)
  return { dateRangeStart, dateRangeEnd }
}

export const getMonthsFromDateStrings = (dates: string[]): number[] => {
  const months = dates.map((date) => new Date(date).getMonth() + 1)
  return months.filter((month, index) => months.indexOf(month) === index)
}

export const getDaysFromDateStrings = (dates: string[], month: number): number[] => {
  const days = dates
    .map((date) => new Date(date))
    .filter((date) => date.getMonth() + 1 === month)
    .map((date) => date.getDate())
  return days.filter((day, index) => days.indexOf(day) === index)
}

export const getLastBusinessDayOfMonth = (): {
  getDate(date?: Date): Date
  getDay(date?: Date): number
} => {
  return {
    getDate: (date = new Date()): Date => {
      const nowDate = new Date()
      nowDate.setDate(getLastBusinessDayOfMonthInDays(date))
      return nowDate
    },
    getDay: (date = new Date()): number => getLastBusinessDayOfMonthInDays(date),
  }
}

const getLastBusinessDayOfMonthInDays = (date = new Date()): number => {
  const lastDay = endOfMonth(date)
  const dayOfWeek = getDay(lastDay)

  if (0 === dayOfWeek) {
    return lastDay.getDate() - 2
  }

  if (6 === dayOfWeek) {
    return lastDay.getDate() - 1
  }

  return lastDay.getDate()
}

export const isLastBusinessDay = (date?: string | Date | undefined): boolean => {
  const lastBusinessDayOfMonthDate = getLastBusinessDayOfMonth().getDay()

  const dateLeft = new Date().getDate()
  const dateRight = date ? new Date(date).getDate() : lastBusinessDayOfMonthDate

  const diff = dateLeft - dateRight

  return diff >= 0
}

export const secondsToMMSS = (seconds: number): string => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  const zeroPad = (num: number) => String(num).padStart(2, '0')

  const formatted = formatDuration(duration, {
    format: ['minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count),
    },
  })
  return formatted
}

export type SupportedDateFormatType =
  | 'yyyy-MM-dd'
  | 'dd/MM/yyyy'
  | 'MM/dd/yyyy'
  | 'yyyy-MM-dd HH:mm:ss'
  | 'dd/MM/yyyy HH:mm:ss'
  | 'MM/dd/yyyy HH:mm:ss'
  | 'dd.MM.yyyy HH:mm'
  | 'dd.MM.yyyy'
type FormatOutput = {
  [K in SupportedDateFormatType]: string
}

const formatDateUTC = (
  date: string,
  formatType?: SupportedDateFormatType
): FormatOutput[SupportedDateFormatType] => {
  const [year, month, day] = date.split('T')[0].split('-')

  const timePart = formatType?.includes('HH:mm:ss')
    ? '00:00:00'
    : formatType?.includes('HH:mm')
    ? '00:00'
    : ''

  switch (formatType) {
    case 'yyyy-MM-dd':
      return `${year}-${month}-${day}` as FormatOutput[SupportedDateFormatType]

    case 'dd/MM/yyyy':
      return `${day}/${month}/${year}` as FormatOutput[SupportedDateFormatType]

    case 'MM/dd/yyyy':
      return `${month}/${day}/${year}` as FormatOutput[SupportedDateFormatType]

    case 'dd.MM.yyyy HH:mm':
      return `${day}.${month}.${year} ${timePart}` as FormatOutput[SupportedDateFormatType]

    case 'yyyy-MM-dd HH:mm:ss':
      return `${year}-${month}-${day} ${timePart}` as FormatOutput[SupportedDateFormatType]

    case 'dd/MM/yyyy HH:mm:ss':
      return `${day}/${month}/${year} ${timePart}` as FormatOutput[SupportedDateFormatType]

    case 'MM/dd/yyyy HH:mm:ss':
      return `${month}/${day}/${year} ${timePart}` as FormatOutput[SupportedDateFormatType]
    default:
      return `${day}.${month}.${year}` as FormatOutput[SupportedDateFormatType]
  }
}
