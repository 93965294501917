import '../../model/AccountDetailedDto'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { OnboardingFlowTypeEnum } from '../../pages/Signup/Components/SignupStep'
import { useAccountReadContext } from '../AccountContextContext'

export const useAccountCompanyStatuses = () => {
  const { account } = useAccountReadContext()

  const accountVisibilityLockUnlock = isAccountCompanyDepositStandard(account)
  const accountSkipCompanyDocuments = isAccountSkipCompanyDocuments(account)
  const accountVisibilityDocumentManagement = isAccountProofOfIdentificationVerified(account)

  return {
    isAccountCompanyDepositStandard: accountVisibilityLockUnlock,
    isAccountSkipCompanyDocuments: accountSkipCompanyDocuments,
    isAccountProofOfIdentificationVerified: accountVisibilityDocumentManagement,
  }
}

export const isAccountCompanyDepositStandard = (
  account: AccountDetailedDto | undefined
): boolean => {
  return account?.companyConfiguration?.onboardingFlowType.id === OnboardingFlowTypeEnum.Standard
}

export const isAccountSkipCompanyDocuments = (account: AccountDetailedDto | undefined): boolean => {
  return !!account?.companyConfiguration?.skipDocuments
}

export const isAccountProofOfIdentificationVerified = (
  account: AccountDetailedDto | undefined
): boolean => {
  return !!account?.companyConfiguration?.poiVerified
}
