import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { BankAccount } from '../../model/BankAccount'
import { DocumentClientAreaDto } from '../../model/DocumentClientAreaDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { IAppendableFetchResult } from '../../utils/useFetch'
import { UploadedDocumentsWrapper } from './DocumentManagementPage'
import { UploadAdditionalDocuments } from './UploadAdditionalDocuments'
import { UploadedDocuments } from './UploadedDocuments'

interface Props extends IAppendableFetchResult<DocumentClientAreaDto[]> {
  bankAccounts: BankAccount[]
  forceRefresh(): void
  setIsPaginationEntrySelected: (isPaginationEntrySelected: boolean) => void
}

export const ActivatedClientDocumentManagementPage: FC<Props> = (props) => {
  const { data, bankAccounts, isLoading, setIsPaginationEntrySelected } = props

  const { t } = useTranslation()

  return (
    <UploadedDocumentsWrapper>
      <PageHeader
        title={t('Profile.Uploaded Documents')}
        id={ScrollToIds.UploadedDocumentsHeader}
      />
      <UploadAdditionalDocuments />

      <UploadedDocuments
        documents={data}
        setPageQuery={props.setPageQuery}
        pageQuery={props.pageQuery}
        meta={props.meta}
        forceRefresh={props.forceRefresh}
        setIsPaginationEntrySelected={setIsPaginationEntrySelected}
        bankAccounts={bankAccounts}
        isLoading={isLoading}
      />
    </UploadedDocumentsWrapper>
  )
}
