import React, { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { CopyLink } from '../../global/copyLink/CopyLink'
import IconButton from '../../global/iconButton/IconButton'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useTraderUrlByPlatform } from '../../hooks/useTraderUrlByPlatform'
import { CopyIcon } from '../../icons/CopyIcon'
import { DotsIcon } from '../../icons/DotsIcon'
import { DummyIcon } from '../../icons/DummyIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { PlatformType } from '../../model/PlatformTypeDto'
import { isTMTPlatformType } from '../../model/PlatformTypeEnum'
import { DemoTradingAccount, TradingAccount } from '../../model/TradingAccount'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { TickmillCompaniesEnum } from '../../utils/companyName.utils'
import { addSwapFreePostfix } from '../../utils/text'
import {
  TradingAccountRestrictions,
  isRestricted,
  isTradingAccountSuspended,
} from '../../utils/trading-account.utils'

import styles from './TradingAccountCard.module.scss'

interface TradingAccountCardProps {
  tradingAccount: TradingAccount
  isActive?: boolean
  onLeverageChange?({
    tradingAccount,
    restricted,
  }: {
    tradingAccount: TradingAccount
    restricted?: boolean
  }): void
  cardId: string
  setRestrictionModal(v: boolean): void
  platformTypes?: PlatformType[]
  handleSetSelectedTradingAccount(ta: TradingAccount): void
  onDisplayDynamicLeverageInfo?(visible: boolean): void
}

export const TradingAccountCard: React.FC<TradingAccountCardProps> = ({
  tradingAccount,
  setRestrictionModal,
  onLeverageChange,
  handleSetSelectedTradingAccount,
  isActive,
  onDisplayDynamicLeverageInfo,
  platformTypes,
  cardId,
}) => {
  const [expanded, setExpanded] = useState(false)

  const { t } = useTranslation()
  const { formatMoney, formatNumber } = useFormatNumber()
  const navigate = useNavigate()
  const isArabic = useArabicSessionLanguage()
  const isEUEntity = useSessionEntity() === TickmillCompaniesEnum.TICKMILL_EU
  const hasAllRestrictions = isTradingAccountSuspended(tradingAccount.restrictions)
  const tradeUrl = useTraderUrlByPlatform(tradingAccount)
  const isTransferFromRestricted = isRestricted(
    TradingAccountRestrictions.TRANSFER_FROM,
    tradingAccount.restrictions
  )
  const hasCampaign = !!tradingAccount.campaigns.length

  const handleTransfer = () => {
    if (isTransferFromRestricted) {
      setRestrictionModal(true)
    } else {
      navigate(`/dashboard/traders-room/trading-accounts/${tradingAccount.id}/transfer`)
    }
  }

  return (
    <div className={styles.card} id={cardId}>
      <div className={styles.titleWrapper}>
        {hasAllRestrictions ? <WarningIcon size={20} color='error' /> : <DummyIcon />}
        <TextSmall>
          {tradingAccount.currency.id}-{tradingAccount.name}
        </TextSmall>

        {isActive ? (
          <IconButton onClick={() => handleSetSelectedTradingAccount(tradingAccount)}>
            <DotsIcon />
          </IconButton>
        ) : (
          <DummyIcon />
        )}
      </div>
      <h2
        className={classNames(styles.balance, {
          [styles.inactive]: !isActive,
        })}
      >
        {formatMoney(tradingAccount.platformOverview.balance, tradingAccount.currency.id)}
      </h2>
      {isActive && (
        <>
          <div className='mb-4'>
            {hasCampaign ? (
              <Link
                to={`/dashboard/traders-room/trading-accounts/${tradingAccount.id}/deposit-to-campaign`}
              >
                <Button size='M' className={styles.button} appearance='primary'>
                  <TextSmallStrong>{t('Deposit')}</TextSmallStrong>
                </Button>
              </Link>
            ) : (
              <Button
                onClick={handleTransfer}
                renderLeftIcon={() =>
                  !hasAllRestrictions && isTransferFromRestricted && <WarningIcon color='error' />
                }
                size='M'
                state={isTransferFromRestricted ? 'disabled' : 'normal'}
                className={styles.button}
                appearance='secondary'
              >
                <TextSmallStrong>{t('Transfer')}</TextSmallStrong>
              </Button>
            )}
          </div>
          {!!tradeUrl && (
            <a className='is-link' target='_blank' href={tradeUrl} rel='noreferrer'>
              <Button size='M' appearance='primary' className={classNames(styles.button, 'mb-4')}>
                <TextSmallStrong>{t('Trade')}</TextSmallStrong>
              </Button>
            </a>
          )}
        </>
      )}
      {expanded && (
        <Fragment>
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>{t('Trading Account.Platform')}</span>
            <span className={styles.value}>{tradingAccount.platformType.name}</span>
          </div>
          {isTMTPlatformType(tradingAccount?.platformType.id) && (
            <TickmillTraderUsername tradingAccount={tradingAccount} />
          )}
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>{t('Trading Account.Account Type')}</span>
            <span className={styles.value}>
              {hasCampaign
                ? `${tradingAccount.accountType.name} ${t('Trading Account.Campaign')}`
                : addSwapFreePostfix(tradingAccount.isSwapFree, tradingAccount.accountType.name, t)}
            </span>
          </div>
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>
              {t('Trading Account.Leverage')}{' '}
              {tradingAccount.platformType.dynamicLeverage && (
                <span>({t('Trading Account.Dynamic')})</span>
              )}
            </span>
            <div className='is-flex'>
              <Button
                appearance='plain'
                className={classNames('p-0', { [styles.hasTextColor]: !isActive })}
                onClick={() => {
                  if (isActive) {
                    onLeverageChange?.({
                      tradingAccount,
                      restricted: hasAllRestrictions,
                    })
                  }
                }}
              >
                <TextSmall>{tradingAccount.leverageType.name}</TextSmall>
              </Button>
              {tradingAccount.platformType.dynamicLeverage && (
                <IconButton
                  className={classNames({
                    'ml-2 mr-0': isArabic,
                    'ml-2': !isArabic,
                  })}
                  onClick={() => onDisplayDynamicLeverageInfo?.(true)}
                >
                  <InfoIcon color='warning' size={14} />
                </IconButton>
              )}
            </div>
          </div>
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>{t('Trading Account.Balance')}</span>
            <span className={styles.value}>
              {formatMoney(tradingAccount.platformOverview.balance, tradingAccount.currency.id)}
            </span>
          </div>
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>{t('Trading Account.Equity')}</span>
            <span className={styles.value}>{formatNumber(tradingAccount.equity)}</span>
          </div>
          {!isEUEntity && (
            <div
              className={classNames(styles.subtitle, {
                [styles.inactive]: !isActive,
              })}
            >
              <span className={styles.label}>{t('Trading Account.IB Code')}</span>
              <span className={styles.value}>{tradingAccount.introducingBroker?.name}</span>
            </div>
          )}
          <div
            className={classNames(styles.subtitle, {
              [styles.inactive]: !isActive,
            })}
          >
            <span className={styles.label}>{t('Trading Account.Transaction History')}</span>
            <span>
              <Link
                className={classNames(styles.textSmall, 'is-link')}
                to='/dashboard/traders-room/transaction-history'
                state={{ tradingAccountId: tradingAccount.id }}
              >
                {t('Trading Account.View')}
              </Link>
            </span>
          </div>
        </Fragment>
      )}
      <div
        className={classNames(styles.textSmall, 'is-link has-text-centered')}
        onClick={() => setExpanded((prevState) => !prevState)}
      >
        {!expanded ? t('Trading Account.See more') : t('Trading Account.See less')}
      </div>
    </div>
  )
}

export const TickmillTraderUsername: FC<{
  tradingAccount: TradingAccount | DemoTradingAccount
}> = ({ tradingAccount }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.subtitle}>
      <span className={styles.label}>{t('Trading Account.Username')}:</span>
      <span className={styles.value}>
        <div className={styles.usernameWrapper}>
          <span className='pt-1'>
            <CopyLink value={tradingAccount.externalId}>
              <CopyIcon size={18} />
            </CopyLink>
          </span>
          <TextSmall>{tradingAccount.externalId}</TextSmall>
        </div>
      </span>
    </div>
  )
}
