import React, { SyntheticEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { CheckBox } from '../../global/checkBox/CheckBox'
import { DocumentFileUpload } from '../../global/fileUpload/DocumentFileUpload'
import { FileData } from '../../global/fileUpload/FileUpload'
import { createFormField } from '../../global/formField/FormField'
import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { Modal } from '../../global/modal/Modal'
import { FormTemplate } from '../../global/templates/FormTemplate'
import { TransactionFormActionButtonTemplate } from '../../global/templates/TransactionFormActionButtonTemplate'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../icons/ChevronUpIcon'
import { CircleSuccessIcon } from '../../icons/CircleSuccessIcon'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import { DocumentClientAreaDto } from '../../model/DocumentClientAreaDto'
import { isDocumentStatusApproved } from '../../model/DocumentStatus'
import { WalletCurrency } from '../../model/WalletDto'
import { TextSmall, TextSmallStrong, TextTinyStrong } from '../../ui/Typography/Typography'
import { FormSubmitValues } from '../../utils/formValidation'
import { scrollToTop, useScrollToTop } from '../../utils/useScrollToTop'
import { trimToMaxLength } from '../../utils/validations'
import { CreateBankAccountFormValues } from './CreateBankAccountPage'

import styles from './CreateBankAccountForm.module.scss'

interface OuterProps {
  availableCurrencies: WalletCurrency[]
  onSubmit(values: FormSubmitValues<CreateBankAccountFormValues>): Promise<void>
  onCancelNewBank(): void
  documents: DocumentClientAreaDto[]
}

const DOCUMENTS_LIMIT = 3

const FormField = createFormField<Omit<CreateBankAccountFormValues, 'document'>>()

const CreateBankAccountFormUI: React.FC<FormikProps<CreateBankAccountFormValues> & OuterProps> = (
  props
) => {
  useScrollToTop()

  const { isValid, isSubmitting, errors, values, touched, documents } = props
  const { handleSubmit, setSubmitting, setValues, setFieldTouched, onCancelNewBank } = props

  const { t } = useTranslation()

  const [areAllDocumentsShown, setAreAllDocumentsShown] = useState(false)
  const [cancelModal, setCancelModal] = useState(false)
  const slicedDocuments = useMemo(() => {
    return areAllDocumentsShown ? documents : documents.slice(0, DOCUMENTS_LIMIT)
  }, [areAllDocumentsShown, documents])

  const handleSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }

  const handleUploadDocumentField = (doc?: FileData) => {
    setFieldTouched('document', true)
    if (!doc) {
      setValues((values) => ({ ...values, document: undefined }))
    } else {
      setValues((values) => ({ ...values, document: doc, previouslyUploadedDocumentId: undefined }))
    }
  }

  const isUploadedDocumentsSown = !!slicedDocuments.length && !values.document
  const isDocumentFieldsTouched = touched.document || touched.previouslyUploadedDocumentId

  return (
    <React.Fragment>
      {cancelModal && (
        <Modal
          closeModal={() => setCancelModal(false)}
          render={() => (
            <CancelActionModal
              title={t('Cancel Adding New Bank Account?')}
              onConfirm={onCancelNewBank}
              onCancel={() => {
                setCancelModal(false)
                scrollToTop()
              }}
              cancelLabel={t('Back to Adding')}
              confirmLabel={t('Yes, Cancel Adding')}
            >
              <p>{t('Do you really like to cancel the Adding New Bank Account?')}</p>
            </CancelActionModal>
          )}
        />
      )}
      <Loading showLoadingIcon isLoading={false}>
        <FormTemplate title={t('New Bank Account')} goBack={() => setCancelModal(true)}>
          <Form className={styles.form} onSubmit={handleSubmitForm}>
            <div>
              <FormField
                name='bankName'
                label={t('Name of Bank')}
                placeholder={t('Name of Bank')}
                required
                maxlength={255}
              />
              <FormField
                name='bankAddress'
                label={t('Bank Address')}
                placeholder={t('Bank Address')}
                required
                maxlength={255}
              />
              <FormField
                name='accountName'
                label={t('Beneficiary Name')}
                placeholder={t('Beneficiary Name')}
                required
                maxlength={128}
              />
              <FormField
                name='bankAccount'
                label={t('IBAN/Account Number')}
                placeholder={t('IBAN/Account Number')}
                required
                maxlength={64}
                type={'text'}
              />
              <FormField
                name='bankCode'
                label={t('BIC / Swift Code')}
                placeholder={t('BIC / Swift Code')}
                required
                maxlength={32}
              />
              <FormField
                name='codeSort'
                label={t('Branch Number/Sort Code')}
                placeholder={t('Branch Number/Sort Code')}
                maxlength={32}
              />
            </div>
            <div className={styles.uploadSection}>
              <div className={styles.label}>
                <TextSmallStrong>{t('Bank Statement') + ' *'}</TextSmallStrong>
              </div>
              {!!errors.document && isDocumentFieldsTouched && (
                <span className={styles.error}>{errors.document}</span>
              )}

              {isUploadedDocumentsSown && (
                <div className={styles.docList}>
                  {slicedDocuments.map((document) => (
                    <div key={document.id} className={styles.documentItem}>
                      <CheckBox
                        value={values.previouslyUploadedDocumentId === document.id}
                        wrapperClassName={styles.checkboxWrapper}
                        contentWrapperClassName={styles.checkboxContentWrapper}
                        onChange={() => {
                          setFieldTouched('previouslyUploadedDocumentId', true, true)
                          setValues((values) => ({
                            ...values,
                            document: undefined,
                            previouslyUploadedDocumentId:
                              values.previouslyUploadedDocumentId === document.id
                                ? undefined
                                : document.id,
                          }))
                        }}
                      >
                        <div className='is-flex'>
                          <span className={styles.documentName} dir='ltr'>
                            {document.name}
                          </span>
                        </div>
                      </CheckBox>

                      {isDocumentStatusApproved(document.status.id) && (
                        <CircleSuccessIcon size={24} />
                      )}
                    </div>
                  ))}
                </div>
              )}

              {isUploadedDocumentsSown && documents.length > DOCUMENTS_LIMIT && (
                <Button
                  type='button'
                  className={styles.documentListButton}
                  appearance='plain'
                  onClick={() => setAreAllDocumentsShown((prevState) => !prevState)}
                >
                  {areAllDocumentsShown ? (
                    <TextTinyStrong className={styles.lessMore}>
                      <ChevronUpIcon />
                      {t('Show less')}
                    </TextTinyStrong>
                  ) : (
                    <TextTinyStrong className={styles.lessMore}>
                      <ChevronDownIcon />
                      {t('Show more')}
                    </TextTinyStrong>
                  )}
                </Button>
              )}

              {!values.previouslyUploadedDocumentId?.length && (
                <>
                  <TextSmall>
                    {t('Provide a clear high-quality unobstructed image of your bank statement')}
                  </TextSmall>
                  <DocumentFileUpload
                    file={values.document}
                    documentCategory={DocumentCategoryType.Personal}
                    className={styles.fileUpload}
                    onRemove={() => handleUploadDocumentField(undefined)}
                    onUpload={handleUploadDocumentField}
                  />
                </>
              )}
            </div>

            <TransactionFormActionButtonTemplate>
              <Button
                appearance='secondary'
                size='L'
                type='button'
                onClick={() => setCancelModal(true)}
              >
                {t('Cancel')}
              </Button>

              <Button
                appearance='primary'
                disabled={!isValid || isSubmitting}
                size='L'
                type='submit'
              >
                {t('Confirm')}
              </Button>
            </TransactionFormActionButtonTemplate>
          </Form>
        </FormTemplate>
      </Loading>
    </React.Fragment>
  )
}

const enforceMaxLength = (values: CreateBankAccountFormValues): CreateBankAccountFormValues => {
  return {
    ...values,
    bankName: values.bankName ? trimToMaxLength(values.bankName, 255) : values.bankName,
    bankAddress: values.bankAddress ? trimToMaxLength(values.bankAddress, 255) : values.bankAddress,
    accountName: values.accountName ? trimToMaxLength(values.accountName, 128) : values.accountName,
    bankAccount: values.bankAccount ? trimToMaxLength(values.bankAccount, 64) : values.bankAccount,
    bankCode: values.bankCode ? trimToMaxLength(values.bankCode, 32) : values.bankCode,
    codeSort: values.codeSort ? trimToMaxLength(values.codeSort, 32) : values.codeSort,
  }
}

export const CreateBankAccountForm = withFormik<OuterProps, CreateBankAccountFormValues>({
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(enforceMaxLength(values))
    } finally {
      setSubmitting(false)
    }
  },

  validate: (values) => {
    const errors: FormikErrors<CreateBankAccountFormValues> = {}
    const requiredKeys: (keyof CreateBankAccountFormValues)[] = [
      'bankName',
      'bankAddress',
      'accountName',
      'bankAccount',
      'bankCode',
    ]
    requiredKeys.forEach((key) => {
      if (values[key]) {
        if (typeof values[key] === 'string') {
          if (!(values[key] as string)?.trim()) {
            errors[key] = t('Validation.Required')
          }
        }
      } else {
        errors[key] = t('Validation.Required')
      }
    })

    if (!values.document && !values.previouslyUploadedDocumentId) {
      errors.document = t('Validation.Required')
    }

    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(CreateBankAccountFormUI)
