import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { CheckOutlinedIcon } from '../../icons/CheckOutlinedIcon'
import { TextH3, TextStrong } from '../../ui/Typography/Typography'
import { Modal } from './Modal'

import styles from './SuccessModal.module.scss'

interface SuccessBodyModalProps {
  title: string
}

const SuccessBodyModal: React.FunctionComponent<PropsWithChildren<SuccessBodyModalProps>> = ({
  title,
  children,
}) => {
  return (
    <section className={classNames('modal-card-body', styles.modalCardBody)}>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <div className={styles.icon}>
            <CheckOutlinedIcon />
          </div>
          <TextH3>{title}</TextH3>
        </div>

        <div>{children}</div>
      </div>
    </section>
  )
}

interface SuccessModalItemProps {
  title: string
  value: string
}

export const SuccessModalItem: React.FunctionComponent<
  PropsWithChildren<SuccessModalItemProps>
> = ({ title, value }) => {
  return (
    <div className={styles.row}>
      <div>{title}</div>
      <TextStrong isParagraph>{value}</TextStrong>
    </div>
  )
}

interface SuccessModalProps {
  title: string
  body?: React.ReactNode | React.ReactNode[]
  onConfirm(): void
  onCancel?(): void
  renderFooter?(): React.ReactNode
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  title,
  body,
  onConfirm,
  onCancel,
  renderFooter,
}) => {
  const { t } = useTranslation()

  const handleCancel = () => {
    onCancel?.()
  }

  return (
    <Modal
      size='xsmall'
      cardClassName={styles.card}
      closeModal={handleCancel}
      render={() => (
        <React.Fragment>
          <SuccessBodyModal title={title}>{body}</SuccessBodyModal>
          <footer className={classNames('modal-card-foot', styles.footer)}>
            {renderFooter ? (
              renderFooter()
            ) : (
              <button className='button' onClick={onConfirm}>
                {t('OK')}
              </button>
            )}
          </footer>
        </React.Fragment>
      )}
    />
  )
}
