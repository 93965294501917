import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'

import { ChevronLeftIcon } from '../../icons/ChevronLeftIcon'
import { ChevronRightIcon } from '../../icons/ChevronRightIcon'
import { TextSmall } from '../../ui/Typography/Typography'
import { PageMetaData } from '../../utils/ApiClient'
import { isOne, isZero } from '../../utils/validations'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'

import styles from './Paging.module.scss'

const PageSizesAllOptions = [10, 20, 50, 100, 250, 500, 1000]

interface Props {
  pageData?: PageMetaData
  isLoading?: boolean
  maxPageSize?: number
  minPageSize?: number
  onPageChanged(newPage: number, pageSize?: number, eventType?: PagingEventType): void
  mode?: 'simple' | 'detailed'
  scrollToHeaderId?: string
}

export enum PagingEventType {
  PAGE_CHANGED = 'pageChanged',
  ENTRIES_CHANGED = 'entriesChanged',
}

export const Paging: React.FC<Props> = (props) => {
  const { pageData, onPageChanged, mode = 'detailed' } = props

  const pageSizes = PageSizesAllOptions.filter(filterPageSizeByTotalResults(props))

  if (!pageData) {
    return null
  }

  return (
    <div className={classNames('level', styles.pagingSpace)}>
      {!!pageSizes.length && (
        <PaginationSize {...props} mode={mode} pageSizes={pageSizes} onChange={onPageChanged} />
      )}
      {pageData.totalPages > 1 && <PaginationPage {...props} mode={mode} />}
    </div>
  )
}

const filterPageSizeByTotalResults = (props: Props) => (size: number, index: number) => {
  const { pageData, maxPageSize = 100 } = props

  const previousPageSizeOption = PageSizesAllOptions[index - 1]
  const isPreviousPageSizeOptionValid =
    pageData?.totalResults && previousPageSizeOption < pageData.totalResults
  if (previousPageSizeOption) {
    return size <= maxPageSize && isPreviousPageSizeOptionValid
  }

  return pageData?.totalResults && size <= pageData.totalResults
}

interface PaginationSizeProps extends Props {
  pageSizes: number[]
  scrollToHeaderId?: string | undefined
  onChange(newPage: number, pageSize?: number, eventType?: PagingEventType): void
}

const PaginationSize: React.FC<PaginationSizeProps> = (props) => {
  const { mode, pageData, pageSizes, onChange } = props
  const isDetailedMode = mode === 'detailed'

  const { t } = useTranslation()

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    if (Number(event.target.value) < (pageData?.pageSize ?? 10)) {
      onChange(1, Number(event.target.value), PagingEventType.ENTRIES_CHANGED)
    }
    onChange(1, Number(event.target.value))
  }

  return (
    <div className={styles.innerWrapper}>
      {isDetailedMode && <TextSmall className={styles.show}>{t('Show')}</TextSmall>}
      <select
        value={pageData?.pageSize}
        className={styles.select}
        name='pageSize'
        onChange={handleChange}
      >
        {pageSizes.map((size) => (
          <option value={size} key={size}>
            {size}
          </option>
        ))}
      </select>
      {isDetailedMode && <TextSmall>{t('entries')}</TextSmall>}
    </div>
  )
}

const PaginationPage: React.FC<Props> = (props) => {
  const { mode, pageData, onPageChanged } = props

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()

  const [pageIndex, setPageIndex] = useState(pageData?.pageIndex || 1)

  const debounced = useDebouncedCallback((index, pageSize) => onPageChanged(index, pageSize), 500)

  const handlePageChange = async (pageIndex: number) => {
    debounced(pageIndex, pageData?.pageSize)
  }

  const handlePageIndexChange = (value: string) => {
    const newPageIndex = Number(value) > totalPages ? totalPages : Number(value)
    setPageIndex(newPageIndex)
  }

  if (!pageData) {
    return null
  }

  const { totalPages } = pageData

  const isSimpleMode = mode === 'simple'

  return (
    <div className={styles.innerWrapper}>
      <TextSmall className={styles.show}>{t('Page')}</TextSmall>
      <button
        className={styles.button}
        type='button'
        disabled={isOne(pageIndex)}
        onClick={() => handlePageChange(pageIndex - 1)}
      >
        {isArabic ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </button>
      <input
        className={classNames(styles.input, 'input', { ['mx-1']: isSimpleMode })}
        onBlur={() => handlePageChange(pageIndex)}
        onChange={(e) => handlePageIndexChange(e.target.value)}
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            handlePageChange(pageIndex)
          }
        }}
        type='number'
        min={1}
        title='pageIndex'
        value={totalPages > 0 ? pageIndex : 0}
      />
      <button
        disabled={pageIndex === totalPages || isZero(totalPages)}
        className={styles.button}
        type='button'
        onClick={() => handlePageChange(pageIndex + 1)}
      >
        {isArabic ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </button>
      <TextSmall>
        {t('of')} {totalPages}
      </TextSmall>
    </div>
  )
}
