import React, { FormEventHandler, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../../global/button/Button'
import { SelectModal, SelectModalOption } from '../../global/field/SelectModal'
import { createFormField } from '../../global/formField/FormField'
import { createFormNumericField } from '../../global/formField/FormNumericField'
import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { Modal } from '../../global/modal/Modal'
import { WalletRestriction } from '../../global/modal/WalletRestrictionsModal'
import { FormTemplate } from '../../global/templates/FormTemplate'
import { TransactionFormActionButtonTemplate } from '../../global/templates/TransactionFormActionButtonTemplate'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { NameDto } from '../../model/NameDto'
import { TradingAccount } from '../../model/TradingAccount'
import { TransactionLimitDto } from '../../model/TransactionLimitDto'
import { CurrencyType, WalletCurrency, WalletDto, WalletTypeEnum } from '../../model/WalletDto'
import { ExchangeRateBox } from '../../ui/ExchangeRateBox/ExchangeRateBox'
import { TextStrong } from '../../ui/Typography/Typography'
import { FormSubmitValues } from '../../utils/formValidation'
import { isZero } from '../../utils/validations'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../utils/wallet.utils'
import { ExchangeRateModal } from '../Wallets/WalletTransfer/ExchangeRateModal'

import styles from './ConversionForm.module.scss'

export enum ConversionDirection {
  From = 'from',
  To = 'to',
}

interface CreateConversionFormValues {
  from: SelectableOptionField | null
  to: SelectableOptionField | null
  amountFrom: number | null
  amountTo: number | null
  minAmount: number
  direction: ConversionDirection
}

export enum ConversionType {
  Wallet = 'wallet',
  TradingAccount = 'tradingAccount',
}

export interface SelectableOptionField {
  id: string
  name: string
  currency: CurrencyType
  type: ConversionType
  balance: number
  displayType: string
  restrictions?: NameDto[]
}

export interface CreateConversionFormSubmitValues {
  fromId: string
  toId: string
  fromCurrencyId?: CurrencyType
  toCurrencyId?: CurrencyType
  fromAmount: number
  toAmount: number
  direction: ConversionDirection
  fromType: SelectableOptionField['type']
  toType: SelectableOptionField['type']
}

const FormField = createFormField<CreateConversionFormValues>()
const FormNumericField = createFormNumericField<CreateConversionFormValues>()

const ConversionFormUI: React.FC<FormikProps<CreateConversionFormValues> & OuterProps> = (
  props
) => {
  const { handleSubmit, values, setFieldValue, isValid, setState, limits } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { formatNumber, formatMoney } = useFormatNumber()

  const [isCancelModalOpen, setCancelModalOpen] = useState(false)
  const [isToModalOpen, setToModalOpen] = useState(false)
  const [isFromModalOpen, setFromModalOpen] = useState(false)
  const [isExchangeRateModalOpen, setExchangeRateModalOpen] = useState(false)

  const { calculateAmount, fromOptions, toOptions } = useConversionOptions(props, values)

  useFetchRate(props, values)

  const handleSubmitForm: FormEventHandler = (event) => {
    event.preventDefault()
    setState('loading')
    handleSubmit()
  }

  const handleCancel = () => {
    navigate('/dashboard/traders-room/balances')
  }

  useEffect(() => {
    if (!toOptions?.length || !values.to) {
      return
    }
    if (!toOptions.some((option) => option.id === values.to?.id)) {
      setFieldValue('to', null)
    }
    if (values.from?.type !== values.to?.type) {
      setFieldValue('to', null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toOptions, values.from, values.to])

  useEffect(() => {
    if (values.direction === ConversionDirection.From) {
      setFieldValue('amountTo', calculateAmount(values.amountFrom).toFixed(2))
    } else {
      setFieldValue('amountFrom', calculateAmount(values.amountTo, true).toFixed(2))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.amountFrom, values.amountTo, props.exchangeRate, values.direction])

  useEffect(() => {
    if ((values.amountTo && values.amountTo > 0) || (values.amountFrom && values.amountFrom > 0)) {
      props.setFieldTouched('amountFrom', true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.amountFrom, values.amountTo])

  return (
    <React.Fragment>
      {isExchangeRateModalOpen && (
        <Modal
          closeModal={() => setExchangeRateModalOpen(false)}
          render={({ closeModal }) => <ExchangeRateModal onCancel={closeModal} />}
        />
      )}
      {isFromModalOpen && (
        <Modal
          closeModal={() => setFromModalOpen(false)}
          render={({ closeModal }) => (
            <SelectFromModal limits={limits} options={fromOptions} closeModal={closeModal} />
          )}
        />
      )}
      {isToModalOpen && (
        <Modal
          closeModal={() => setToModalOpen(false)}
          render={({ closeModal }) => (
            <SelectToModal toFilteredOptions={toOptions} closeModal={closeModal} />
          )}
        />
      )}
      {isCancelModalOpen && (
        <Modal
          closeModal={() => setCancelModalOpen(false)}
          render={({ closeModal }) => (
            <CancelActionModal onConfirm={handleCancel} onCancel={closeModal} />
          )}
        />
      )}
      <FormTemplate title={t('Transactions.FX Conversion')} goBack={() => setCancelModalOpen(true)}>
        <Form onSubmit={handleSubmitForm}>
          <p className={classNames(styles.info)}>
            <span className='danger'>{t('Trading Account.Important')}</span>{' '}
            {t(
              'Transactions.Currency conversions can only take place between Wallets or between Trading Accounts'
            )}
          </p>
          <FormField
            name='from.name'
            label={t('Transactions.Convert from')}
            placeholder={t('Transactions.Convert from')}
            rightIcon={<DropArrowDownIcon />}
            value={values.from ? `${values.from?.currency} ${values.from?.displayType}` : ''}
            readOnly
            hint={
              values.from &&
              `${t('Trading Account.Balance')} ${formatMoney(
                values.from.balance,
                values.from.currency
              )}`
            }
            required
            onClick={() => setFromModalOpen(true)}
          />
          <div className={styles.formWithCurrency}>
            <FormNumericField
              name={'amountFrom'}
              onChange={(v) => {
                const numberValue = Number(v.target.value)
                setFieldValue('direction', ConversionDirection.From)
                setFieldValue('amountFrom', numberValue)
              }}
              decimalPlaces={2}
              label={t('Transactions.Amount sent')}
              placeholder={t('Transactions.Amount sent')}
              required
              step={values.minAmount}
              disabled={!props.exchangeRate || props.rateLoading}
            />
            <FormField
              name={'from.currency'}
              label={t('Wallet.Currency')}
              placeholder={t('Wallet.Currency')}
              value={values.from?.currency}
              disabled
            />
          </div>
          <FormField
            label={t('Transactions.Convert to')}
            placeholder={t('Transactions.Convert to')}
            rightIcon={<DropArrowDownIcon />}
            value={values.to ? `${values.to?.currency} ${values.to?.displayType}` : null}
            hint={
              values.to &&
              `${t('Trading Account.Balance')} ${formatMoney(
                values.to.balance,
                values.to.currency
              )}`
            }
            disabled={!values?.from}
            readOnly
            required
            onClick={() => setToModalOpen(true)}
          />
          {values.to && values.from && (
            <div className={styles.formWithCurrency}>
              <FormNumericField
                name={'amountTo'}
                decimalPlaces={2}
                onChange={(v) => {
                  const numberValue = Number(v.target.value)
                  setFieldValue('direction', ConversionDirection.To)
                  setFieldValue('amountTo', numberValue)
                }}
                label={t('Transactions.Amount received')}
                placeholder={t('Transactions.Amount received')}
                required
                disabled={!props.exchangeRate || props.rateLoading}
              />
              <FormField
                name={'to.currency'}
                label={t('Wallet.Currency')}
                placeholder={t('Wallet.Currency')}
                value={values.to?.currency}
                disabled
              />
            </div>
          )}
          {values.from && values.to && (
            <ExchangeRateBox
              infoIconClick={() => setExchangeRateModalOpen(true)}
              currencyFrom={values.from.currency}
              currencyTo={values.to.currency}
              calculatedAmount={formatNumber(calculateAmount(1, false), 6)}
            />
          )}
          <TransactionFormActionButtonTemplate>
            <Button
              appearance='secondary'
              size='L'
              type='button'
              onClick={() => setCancelModalOpen(true)}
            >
              {t('Cancel')}
            </Button>

            <Button appearance='primary' size='L' type='submit' disabled={!isValid}>
              {t('Confirm')}
            </Button>
          </TransactionFormActionButtonTemplate>
        </Form>
      </FormTemplate>
    </React.Fragment>
  )
}

interface SelectFromModalProps {
  limits: TransactionLimitDto[]
  options: SelectableOptionField[]
  closeModal(): void
}

const SelectFromModal: React.FC<SelectFromModalProps> = (props) => {
  const { options, closeModal, limits } = props
  const { formatMoney } = useFormatNumber()

  const sortOptions = (a: SelectableOptionField, b: SelectableOptionField) => {
    return a.currency.localeCompare(b.currency)
  }

  const filterIfBalanceNegative = (option: SelectableOptionField) => {
    return option?.balance >= 0
  }

  const fromOptions = options.sort(sortOptions).filter(filterIfBalanceNegative)

  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<CreateConversionFormValues>()

  const isFirstOption = (index: number) => {
    return isZero(index)
  }

  const isLastOption = (option: SelectableOptionField, index: number) =>
    fromOptions?.[index + 1]?.currency !== option.currency && fromOptions?.[index + 1]?.currency

  const getNextCurrency = (index: number) => {
    return fromOptions?.[index + 1]?.currency || ''
  }

  const handleOption = (option: SelectableOptionField) => {
    setFieldValue('from', option)
    if (option.type === ConversionType.TradingAccount) {
      // default one
      setFieldValue('minAmount', 0.01)
    }
    if (option.type === ConversionType.Wallet) {
      const isMinAmount = limits.find(
        (x) => x.walletType?.id === WalletTypeEnum.ETD && x.currency.id === option.currency
      )
      if (isMinAmount) {
        setFieldValue('minAmount', isMinAmount.amount)
      } else {
        // default one
        setFieldValue('minAmount', 0.01)
      }
    }
    if (option.currency === values.to?.currency) {
      setFieldValue('to', null)
    }
    closeModal()
  }

  const isWalletTransferFromAllowed = (wallet: SelectableOptionField) =>
    !(
      isRestricted(WalletRestrictions.TRANSFER_FROM, wallet.restrictions) ||
      isWalletSuspended(wallet?.restrictions ?? [])
    )

  return (
    <SelectModal
      onCancel={closeModal}
      title={t('Trading Account.Convert From')}
      renderOptions={() => (
        <div className='control'>
          {fromOptions.map((x, index) => {
            const hasRestriction = !isWalletTransferFromAllowed(x)
            return (
              <React.Fragment>
                {isFirstOption(index) && (
                  <label className='radio column is-full-desktop'>
                    <TextStrong>{x.currency}</TextStrong>
                  </label>
                )}
                <SelectModalOption
                  label={`${x.currency} ${x.displayType}`}
                  value={x.id === values.from?.id && x.currency === values.from?.currency}
                  hint={`${t('Trading Account.Balance')} ${formatMoney(x.balance, x.currency)}`}
                  onClick={() => handleOption(x)}
                  key={x.id}
                  disabled={hasRestriction}
                >
                  {hasRestriction ? (
                    <WalletRestriction
                      restrictions={x.restrictions}
                      restrictionType={WalletRestrictions.TRANSFER_FROM}
                    />
                  ) : null}
                </SelectModalOption>
                {isLastOption(x, index) && (
                  <label className='radio column is-full-desktop'>
                    <TextStrong>{getNextCurrency(index)}</TextStrong>
                  </label>
                )}
              </React.Fragment>
            )
          })}
        </div>
      )}
    />
  )
}

interface SelectToModalProps {
  toFilteredOptions?: SelectableOptionField[]
  closeModal(): void
}

const SelectToModal: React.FC<SelectToModalProps> = (props) => {
  const { toFilteredOptions, closeModal } = props

  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<CreateConversionFormValues>()

  const filterCurrentSelected = (x: SelectableOptionField) => {
    return values.from?.currency !== x?.currency
  }

  const filterByFromType = (x: SelectableOptionField) => {
    return x.type === values.from?.type
  }

  const handleOption = (x: SelectableOptionField) => {
    setFieldValue('to', x)
    closeModal()
  }

  const isWalletTransferToAllowed = (wallet: SelectableOptionField) =>
    !(
      isRestricted(WalletRestrictions.TRANSFER_TO, wallet.restrictions) ||
      isWalletSuspended(wallet?.restrictions ?? [])
    )

  return (
    <SelectModal
      onCancel={closeModal}
      title={t('Trading Account.Convert To')}
      renderOptions={() => (
        <div className='control'>
          {toFilteredOptions
            ?.filter(filterCurrentSelected)
            ?.filter(filterByFromType)
            .map((x) => {
              const hasRestriction = !isWalletTransferToAllowed(x)
              return (
                <SelectModalOption
                  label={`${x.currency} ${x.displayType}`}
                  value={x.id === values.to?.id && x.currency === values.to?.currency}
                  onClick={() => handleOption(x)}
                  key={x.id}
                  disabled={hasRestriction}
                >
                  {hasRestriction ? (
                    <WalletRestriction
                      restrictions={x.restrictions}
                      restrictionType={WalletRestrictions.TRANSFER_TO}
                    />
                  ) : null}
                </SelectModalOption>
              )
            })}
        </div>
      )}
    />
  )
}

const useConversionOptions = (props: OuterProps, values: CreateConversionFormValues) => {
  const { t } = useTranslation()
  const getWallets = useMemo(() => {
    return props.wallets.map<SelectableOptionField>((x) => ({
      id: x.id,
      name: x.name,
      currency: x.currency.id,
      balance: x.balance,
      displayType: t('Wallet.Wallet'),
      type: ConversionType.Wallet,
      restrictions: x.restrictions,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.wallets])

  const getTradingAccounts = useMemo(() => {
    const [tradingAccount] = props.tradingAccounts
    const balances = tradingAccount?.platformOverview?.balances || []
    return balances?.map<SelectableOptionField>((x) => ({
      id: tradingAccount.id,
      name: tradingAccount.name,
      currency: x.currency,
      balance: x.balance,
      displayType: t('Trading Account.Trading Account'),
      type: ConversionType.TradingAccount,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tradingAccounts])

  const fromOptions = useMemo(() => {
    if (!values.from) {
      return [...getWallets, ...getTradingAccounts]
    }
    if (values.from.type === ConversionType.Wallet) {
      return getWallets
    } else if (values.from.type === ConversionType.TradingAccount) {
      return getTradingAccounts
    }
    return []
  }, [values.from, getWallets, getTradingAccounts])

  const calculateAmountByExchangeRate = (amount: number, exchangeRate: number) => {
    return amount * exchangeRate
  }

  const calculateAmountByReverseExchangeRate = (amount: number, exchangeRate: number) => {
    return amount / exchangeRate
  }

  const toOptions = useMemo(() => {
    if (values.from?.type === ConversionType.Wallet) {
      return fromOptions
    }
    if (values.from?.type === ConversionType.TradingAccount) {
      return props.availableTACurrencies.map(
        (x) =>
          getTradingAccounts.find((tradingAccount) => tradingAccount.currency === x.id) || {
            id: x.id,
            currency: x.id,
            displayType: t('Trading Account.Trading Account'),
            type: ConversionType.TradingAccount,
            name: '',
            balance: 0,
          }
      )
    }
  }, [values.from?.type, fromOptions, props.availableTACurrencies, getTradingAccounts, t])

  const calculateAmount = useCallback(
    (amount: number | null, reverse?: boolean) =>
      amount
        ? reverse
          ? calculateAmountByReverseExchangeRate(amount, props.exchangeRate)
          : calculateAmountByExchangeRate(amount, props.exchangeRate)
        : 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.exchangeRate]
  )

  return {
    fromOptions,
    toOptions,
    calculateAmount,
  }
}

const useFetchRate = (props: OuterProps, values: CreateConversionFormValues) => {
  const { fetchRate } = props

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout
    if (values.from?.currency && values.to?.currency) {
      delayDebounceFn = setTimeout(() => {
        fetchRate(values.from?.currency as CurrencyType, values.to?.currency as CurrencyType)
      }, 1000)
    }
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.from, values.to])
}

function dataToSubmit(
  values: CreateConversionFormValues
): FormSubmitValues<CreateConversionFormSubmitValues> {
  return {
    fromType: values.from?.type as ConversionType,
    toType: values.to?.type as ConversionType,
    fromId: values.from?.id || '',
    toId: values.to?.id || '',
    direction: values.direction || ConversionDirection.From,
    fromCurrencyId: values?.from?.currency,
    toCurrencyId: values?.to?.currency,
    fromAmount: Number.parseFloat((values.amountFrom || 0).toString()),
    toAmount: Number.parseFloat((values.amountTo || 0).toString()),
  }
}

export interface PresetAccount {
  account?: TradingAccount
  currency?: CurrencyType
  wallet?: WalletDto
  type: ConversionType
  displayType: string
}

interface OuterProps {
  wallets: WalletDto[]
  tradingAccounts: TradingAccount[]
  availableTACurrencies: WalletCurrency[]
  fromAccount?: PresetAccount
  fetchRate(currencyFrom: CurrencyType, currencyTo: CurrencyType): void
  exchangeRate: number
  rateLoading: boolean
  limits: TransactionLimitDto[]

  onSubmit(values: FormSubmitValues<CreateConversionFormSubmitValues>): Promise<void>
  setState(state: 'loading'): void
}

export const ConversionForm = withFormik<OuterProps, CreateConversionFormValues>({
  mapPropsToValues: ({ fromAccount, limits }) => {
    const minAmount = 0.01
    const baseValues = {
      from: null,
      to: null,
      amountFrom: null,
      amountTo: null,
      minAmount,
      direction: ConversionDirection.From,
    }

    if (fromAccount?.account && fromAccount?.currency) {
      const balances = fromAccount.account.platformOverview.balances
      const selectedBalance = balances.find((x) => x.currency === fromAccount.currency)
      const balance = selectedBalance?.balance || 0
      return {
        ...baseValues,
        from: {
          id: fromAccount.account.id,
          name: fromAccount.account.name,
          currency: fromAccount.currency,
          balance,
          type: fromAccount.type,
          displayType: fromAccount.displayType,
        },
      }
    }

    if (fromAccount?.wallet) {
      const minAmount = limits.find(
        (x) =>
          x.walletType?.id === WalletTypeEnum.ETD &&
          x.currency.id === fromAccount.wallet?.currency.id
      )
      return {
        ...baseValues,
        minAmount: minAmount?.amount ?? baseValues.minAmount,
        from: {
          id: fromAccount.wallet.id,
          name: fromAccount.wallet.name,
          currency: fromAccount.wallet.currency.id,
          balance: fromAccount.wallet.balance || 0,
          type: fromAccount.type,
          displayType: fromAccount.displayType,
        },
      }
    }

    return baseValues
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(dataToSubmit(values))
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<CreateConversionFormValues> = {}
    if (!values.from) {
      errors.from = t('Validation.Required')
    }
    if (!values.to) {
      errors.to = t('Validation.Required')
    }
    if (!values.amountFrom) {
      errors.amountFrom = t('Validation.Required')
    }
    if (!values.amountTo) {
      errors.amountTo = t('Validation.Required')
    }

    if (values.amountFrom && values.amountFrom < values?.minAmount) {
      errors.amountFrom = t('Validation.Minimum amount is {{amount}} {{currency}}', {
        currency: values?.from?.currency,
        amount: values?.minAmount,
      })
    }
    if (values.amountFrom && values.amountFrom > 0) {
      if (values.amountTo && values.amountTo < 0.01) {
        errors.amountTo = t('Validation.Minimum amount is {{amount}} {{currency}}', {
          currency: values?.to?.currency,
          amount: 0.01,
        })
      }
    }
    if (
      values?.amountFrom &&
      values.from?.balance != null &&
      values.amountFrom > values.from.balance
    ) {
      if (values.from.type === ConversionType.Wallet) {
        errors.amountFrom = t('Validation.There is not enough balance in your wallet')
      } else {
        errors.amountFrom = t('Validation.There is not enough balance in your trading account')
      }
    }
    return errors
  },
  enableReinitialize: true,
  validateOnMount: true,
})(ConversionFormUI)
